import * as React from "react";
import moment from "moment";
import * as MaskedInput from "react-text-mask";
import { maskArray } from "react-text-mask";
import autobind from "autobind-decorator";

export class DefaultMaskedDateFormats {
  public static MM_DD_YY: IMaskedDateFormat = {
    format: "MM/DD/YY",
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/],
  };

  public static MM_DD_YY_: IMaskedDateFormat = {
    format: "MM / DD / YYYY",
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/],
  };
}

export interface IMaskedDateFormat {
  format: string;
  mask: maskArray;
}

export interface IMaskedDateInputProps {
  value?: Date;
  format?: IMaskedDateFormat;
  minDate?: Date;
  maxDate?: Date;
  onDateChange?: (d: Date) => void;
}

export class MaskedDateInput extends React.Component<
  IMaskedDateInputProps,
  any
> {
  public render() {
    let format = this.props.format || DefaultMaskedDateFormats.MM_DD_YY;
    let placeholder = moment().format(format.format);

    let value = "";
    if (this.props.value) {
      value = moment(this.props.value).format(format.format);
    }

    return (
      <MaskedInput.default
        value={value}
        placeholder={placeholder}
        onChange={this.onDateChange}
        mask={format.mask}
      />
    );
  }

  @autobind
  public onDateChange(e) {
    let format = this.props.format || DefaultMaskedDateFormats.MM_DD_YY;
    let val = e.target.value || "";
    let date = moment(val, format.format, true);

    if (this.props.minDate && date.toDate() < this.props.minDate) {
      e.target.value = moment(this.props.minDate).format("MM/DD/YY");
    }

    if (this.props.maxDate && date.toDate() > this.props.maxDate) {
      e.target.value = moment(this.props.maxDate).format("MM/DD/YY");
    }

    if (
      this.props.minDate &&
      this.props.maxDate &&
      date.toDate() < this.props.minDate
    ) {
      e.target.value = moment(this.props.maxDate).format("MM/DD/YY");
    }

    val = e.target.value || "";
    date = moment(val, format.format, true);

    if (date.isValid()) {
      if (this.props.onDateChange) {
        this.props.onDateChange(date.toDate());
      }
    }
  }
}
