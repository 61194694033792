import * as React from "react";
import { AbstractMessageDialog } from "./AbstractMessageDialog";

class SuccessMessageDialog extends AbstractMessageDialog {
  public renderButtons() {
    return (
      <div>
        <button type="button" onClick={this._onCloseClicked}>
          OK
        </button>
      </div>
    );
  }
}

export { SuccessMessageDialog };
