import * as React from "react";
import * as PropTypes from "prop-types";
import { Emitter, waitUntil } from "helpers";
import { MapBoxDrawControl } from "./MapBoxDrawControl";
import autobind from "autobind-decorator";

interface IMapBoxDrawControlAdapterProps {
  events: Emitter;
  getInteractiveMap: () => any;
}

interface IMapBoxDrawControlAdapterState {
  showDrawControl: boolean;
}

export class MapBoxDrawControlAdapter extends React.Component<
  IMapBoxDrawControlAdapterProps,
  IMapBoxDrawControlAdapterState
> {
  public static childContextTypes = {
    map: PropTypes.object,
  };

  private mapBoxDrawControlRef: MapBoxDrawControl = null;
  private interactiveMap: any = null;

  constructor(props) {
    super(props);

    this.state = { showDrawControl: false };
  }

  public componentWillMount() {
    let condition = () => {
      return this.props.getInteractiveMap() != null;
    };

    waitUntil(condition, () => {
      this.interactiveMap = this.props.getInteractiveMap();
      this.setState({ showDrawControl: true });
    });
  }

  public render() {
    if (!this.state.showDrawControl) {
      return null;
    }

    return (
      <MapBoxDrawControl
        events={this.props.events}
        ref={this.bindMapBoxDrawControlRef}
      />
    );
  }

  public getChildContext() {
    return {
      map: this.interactiveMap === null ? null : this.interactiveMap.getMap(),
    };
  }

  public getMapBoxDrawControl(): MapBoxDrawControl {
    return this.mapBoxDrawControlRef;
  }

  @autobind
  private bindMapBoxDrawControlRef(ref) {
    this.mapBoxDrawControlRef = ref;
  }
}
