import * as geo_convert from "geo-coordinates-parser";

export const LIVE_CAMPAIGN: string = "LIVE";
export const UPCOMING_CAMPAIGN: string = "UPCOMING";
export const ENDED_CAMPAIGN: string = "FINISHED";

export const SAN_FRANCISCO: string = "San Francisco";
export const LOS_ANGELES: string = "Los Angeles";
export const NEW_YORK: string = "New York";
export const DALLAS: string = "Dallas";
export const CHICAGO: string = "Chicago";
export const MIAMI: string = "Miami";
export const AUSTIN: string = "Austin";
export const LAS_VEGAS: string = "Las Vegas";

export const SAN_FRANCISCO_SHORT: string = "SF";
export const LOS_ANGELES_SHORT: string = "LA";
export const NEW_YORK_SHORT: string = "NY";
export const DALLAS_SHORT: string = "DAL";
export const CHICAGO_SHORT: string = "CHI";
export const MIAMI_SHORT: string = "MIA";
export const AUSTIN_SHORT: string = "ATX";
export const LAS_VEGAS_SHORT: string = "LSV";

export function getLongLat(city) {
  if (city === SAN_FRANCISCO) {
    return [-122.4312971, 37.790664];
  } else if (city === LOS_ANGELES) {
    return [-118.24368, 34.052235];
  } else if (city === NEW_YORK) {
    return [-73.9879046, 40.7226917];
  } else if (city === DALLAS) {
    return [-96.797, 32.7767];
  } else if (city === CHICAGO) {
    return [-87.6298, 41.8781];
  } else if (city === MIAMI) {
    return [-80.1918, 25.7617];
  } else if (city === AUSTIN) {
    return [-97.7431, 30.2672];
  } else if (city === LAS_VEGAS) {
    return [-115.1398, 36.1699];
  } else {
    return [-122.4312971, 37.790664];
  }
}

export const DMAS: string[] = [
  SAN_FRANCISCO,
  LOS_ANGELES,
  NEW_YORK,
  DALLAS,
  CHICAGO,
  MIAMI,
  AUSTIN,
  LAS_VEGAS,
];

export const DMAS_SHORT: string[] = [
  SAN_FRANCISCO_SHORT,
  LOS_ANGELES_SHORT,
  NEW_YORK_SHORT,
  DALLAS_SHORT,
  CHICAGO_SHORT,
  MIAMI_SHORT,
  AUSTIN_SHORT,
  LAS_VEGAS_SHORT,
];

export const SAN_FRANCISCO_IMP_MULT: number = 1826;
export const LOS_ANGELES_IMP_MULT: number = 1718;
export const NEW_YORK_IMP_MULT: number = 2377;
export const DALLAS_IMP_MULT: number = 1157;
export const CHICAGO_IMP_MULT: number = 2313;
export const MIAMI_IMP_MULT: number = 2139;
export const AUSTIN_IMP_MULT: number = 961;
export const LAS_VEGAS_MULT: number = 1888;

function getCityImpressionMultiplier(city, dma_multipliers) {
  let multiplier = dma_multipliers[city];
  if (multiplier) {
    return parseInt(multiplier, 10);
  } else if (city === SAN_FRANCISCO_SHORT) {
    return SAN_FRANCISCO_IMP_MULT;
  } else if (city === LOS_ANGELES_SHORT) {
    return LOS_ANGELES_IMP_MULT;
  } else if (city === NEW_YORK_SHORT) {
    return NEW_YORK_IMP_MULT;
  } else if (city === DALLAS_SHORT) {
    return DALLAS_IMP_MULT;
  } else if (city === CHICAGO_SHORT) {
    return CHICAGO_IMP_MULT;
  } else if (city === MIAMI_SHORT) {
    return MIAMI_IMP_MULT;
  } else if (city === AUSTIN_SHORT) {
    return AUSTIN_IMP_MULT;
  } else if (city === LAS_VEGAS_SHORT) {
    return LAS_VEGAS_MULT;
  } else {
    return 1;
  }
}

export function getImpressionDelivered(loc, dma_multipliers) {
  let impression = 0;
  let lat = loc.lat;
  let lng = loc.lng;
  if (lat >= 36.1 && lat <= 42 && lng >= -127 && lng <= -116.6) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(SAN_FRANCISCO_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 31 && lat <= 36 && lng >= -127 && lng <= -116.6) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(LOS_ANGELES_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 31 && lat <= 35 && lng >= -99 && lng <= -95) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(DALLAS_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 24 && lat <= 32 && lng >= -86 && lng <= -78) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(MIAMI_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 38 && lat <= 48 && lng >= -94 && lng <= -84) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(CHICAGO_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 38 && lat <= 43 && lng >= -76 && lng <= -69) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(NEW_YORK_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 29.5 && lat <= 30.9 && lng >= -99 && lng <= -96) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(AUSTIN_SHORT, dma_multipliers)) /
      3600;
  } else if (lat >= 35 && lat <= 37 && lng >= -116.5 && lng <= -113) {
    impression =
      (loc.duration *
        getCityImpressionMultiplier(LAS_VEGAS_SHORT, dma_multipliers)) /
      3600;
  }

  return impression;
}

export const SAN_FRANCISCO_TZ: string = "America/Los_Angeles";
export const LOS_ANGELES_TZ: string = "America/Los_Angeles";
export const NEW_YORK_TZ: string = "America/New_York";
export const DALLAS_TZ: string = "America/Chicago";
export const CHICAGO_TZ: string = "America/Chicago";
export const MIAMI_TZ: string = "America/New_York";
export const AUSTIN_TZ: string = "America/Chicago";
export const LAS_VEGAS_TZ: string = "America/Los_Angeles";

export function getTimeZone(city) {
  if (city === SAN_FRANCISCO) {
    return SAN_FRANCISCO_TZ;
  } else if (city === LOS_ANGELES) {
    return LOS_ANGELES_TZ;
  } else if (city === NEW_YORK) {
    return NEW_YORK_TZ;
  } else if (city === DALLAS) {
    return DALLAS_TZ;
  } else if (city === CHICAGO) {
    return CHICAGO_TZ;
  } else if (city === MIAMI) {
    return MIAMI_TZ;
  } else if (city === AUSTIN) {
    return AUSTIN_TZ;
  } else if (city === LAS_VEGAS) {
    return LAS_VEGAS_TZ;
  } else {
    return SAN_FRANCISCO_TZ;
  }
}

export function getTimeZoneWithIndex(city) {
  if (city === 1) {
    return SAN_FRANCISCO_TZ;
  } else if (city === 2) {
    return LOS_ANGELES_TZ;
  } else if (city === 3) {
    return NEW_YORK_TZ;
  } else if (city === 4) {
    return DALLAS_TZ;
  } else if (city === 5) {
    return CHICAGO_TZ;
  } else if (city === 6) {
    return MIAMI_TZ;
  } else if (city === 7) {
    return AUSTIN_TZ;
  } else if (city === 8) {
    return LAS_VEGAS_TZ;
  } else {
    return SAN_FRANCISCO_TZ;
  }
}

export function getTimeZoneWithShort(city) {
  if (city === SAN_FRANCISCO_SHORT) {
    return SAN_FRANCISCO_TZ;
  } else if (city === LOS_ANGELES_SHORT) {
    return LOS_ANGELES_TZ;
  } else if (city === NEW_YORK_SHORT) {
    return NEW_YORK_TZ;
  } else if (city === DALLAS_SHORT) {
    return DALLAS_TZ;
  } else if (city === CHICAGO_SHORT) {
    return CHICAGO_TZ;
  } else if (city === MIAMI_SHORT) {
    return MIAMI_TZ;
  } else if (city === AUSTIN_SHORT) {
    return AUSTIN_TZ;
  } else if (city === LAS_VEGAS_SHORT) {
    return LAS_VEGAS_TZ;
  } else {
    return SAN_FRANCISCO_TZ;
  }
}

export function getDmaShort(dma) {
  if (dma === LOS_ANGELES) {
    return LOS_ANGELES_SHORT;
  } else if (dma === SAN_FRANCISCO) {
    return SAN_FRANCISCO_SHORT;
  } else if (dma === NEW_YORK) {
    return NEW_YORK_SHORT;
  } else if (dma === DALLAS) {
    return DALLAS_SHORT;
  } else if (dma === CHICAGO) {
    return CHICAGO_SHORT;
  } else if (dma === MIAMI) {
    return MIAMI_SHORT;
  } else if (dma === AUSTIN) {
    return AUSTIN_SHORT;
  } else if (dma === LAS_VEGAS) {
    return LAS_VEGAS_SHORT;
  } else {
    return dma.substring(0, 2).toUpperCase();
  }
}

export function getCityofLocation(loc) {
  let city = "Other";
  let lat = loc.lat;
  let lng = loc.lng;
  if (lat >= 36.1 && lat <= 42 && lng >= -127 && lng <= -116.6) {
    city = SAN_FRANCISCO;
  } else if (lat >= 31 && lat <= 36 && lng >= -127 && lng <= -116.6) {
    city = LOS_ANGELES;
  } else if (lat >= 31 && lat <= 35 && lng >= -99 && lng <= -95) {
    city = DALLAS;
  } else if (lat >= 24 && lat <= 32 && lng >= -86 && lng <= -78) {
    city = MIAMI;
  } else if (lat >= 38 && lat <= 48 && lng >= -94 && lng <= -84) {
    city = CHICAGO;
  } else if (lat >= 38 && lat <= 43 && lng >= -76 && lng <= -69) {
    city = NEW_YORK;
  } else if (lat >= 29.5 && lat <= 30.9 && lng >= -99 && lng <= -96) {
    city = AUSTIN;
  } else if (lat >= 35 && lat <= 37 && lng >= -116.5 && lng <= -113) {
    city = LAS_VEGAS;
  }
  return city;
}

export function check_coordinates(dms) {
  let coordinates: any = [];
  try {
    if (dms.toUpperCase().includes("°")) {
      dms = dms.replace(/\s\s+/g, " ").trim();
      let converted = geo_convert(dms);
      coordinates[0] = converted.decimalLatitude;
      coordinates[1] = converted.decimalLongitude;
    }
  } catch (e) {
    console.log(e);
    try {
      if (dms.toUpperCase().includes("''")) {
        for (let index of [dms.indexOf("''"), dms.lastIndexOf("''")]) {
          for (let i = index - 1; i > 0; i--) {
            if (dms[i] === ".") {
              let to_be_replaced = dms
                .substring(i + 1, index)
                .replace("''", "");
              dms = dms.replace(
                to_be_replaced,
                parseFloat("0." + to_be_replaced)
                  .toFixed(2)
                  .split(".")[1]
              );
              break;
            }
          }
        }
        let converted = geo_convert(dms);
        coordinates[0] = converted.decimalLatitude;
        coordinates[1] = converted.decimalLongitude;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return coordinates;
}
