import * as doubleClickZoom from "@mapbox/mapbox-gl-draw/src/lib/double_click_zoom";
import * as CommonSelectors from "@mapbox/mapbox-gl-draw/src/lib/common_selectors";
import * as isEventAtCoordinates from "@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import * as createVertex from "@mapbox/mapbox-gl-draw/src/lib/create_vertex";
import * as turf from "@turf/turf";

const DrawCorridor: any = {};

DrawCorridor.setHelperMessage = function (title, steps = []) {
  this.map.fire("SET_HELPER_MESSAGE", { message: title, steps });
};

DrawCorridor.onSetup = function (opts) {
  opts = opts || {};

  const featureId = opts.featureId;

  this.offset = opts.offset || 50;
  this.units = opts.units || "yards";
  this.helpersEnabled = true;

  let line;
  let corridor;
  let currentVertexPosition;
  let direction = "forward";
  this.mode = "CENTERLINE";

  if (featureId) {
    line = this.getFeature(featureId);
    if (!line) {
      throw new Error("Could not find a feature with the provided featureId");
    }
    let from = opts.from;
    if (
      from &&
      from.type === "Feature" &&
      from.geometry &&
      from.geometry.type === "Point"
    ) {
      from = from.geometry;
    }
    if (
      from &&
      from.type === "Point" &&
      from.coordinates &&
      from.coordinates.length === 2
    ) {
      from = from.coordinates;
    }
    if (!from || !Array.isArray(from)) {
      throw new Error(
        "Please use the `from` property to indicate which point to continue the line from"
      );
    }
    const lastCoord = line.coordinates.length - 1;
    if (
      line.coordinates[lastCoord][0] === from[0] &&
      line.coordinates[lastCoord][1] === from[1]
    ) {
      currentVertexPosition = lastCoord + 1;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
    } else if (
      line.coordinates[0][0] === from[0] &&
      line.coordinates[0][1] === from[1]
    ) {
      direction = "backwards";
      currentVertexPosition = 0;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
    } else {
      throw new Error(
        "`from` should match the point at either the start or the end of the provided LineString"
      );
    }
  } else {
    line = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    });

    corridor = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POLYGON,
        coordinates: [[]],
      },
    });

    currentVertexPosition = 0;
    this.addFeature(line);
    this.addFeature(corridor);
  }

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.LINE);
  this.setActionableState({
    trash: true,
  });

  this.state = {
    line,
    corridor,
    currentVertexPosition,
    direction,
  };

  this.setHelperMessage(
    "Position the mouse cursor where you want the corridor to begin, and click to define the first point."
  );

  return this.state;
};

DrawCorridor.clickAnywhere = function (state, e) {
  if (state.currentVertexPosition === 0) {
    this.setHelperMessage(
      "Continue clicking to set points for additional straight segments."
    );
  }
  if (state.currentVertexPosition === 1) {
    this.setHelperMessage("Complete the path by:", [
      "Positioning the cursor over the last point you set and clicking on it.",
      "Pressing “Enter/Return” key.",
    ]);
  }

  if (this.mode === "CENTERLINE") {
    if (
      (state.currentVertexPosition > 0 &&
        isEventAtCoordinates(
          e,
          state.line.coordinates[state.currentVertexPosition - 1]
        )) ||
      (state.direction === "backwards" &&
        isEventAtCoordinates(
          e,
          state.line.coordinates[state.currentVertexPosition + 1]
        ))
    ) {
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id],
      });
    }
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
    if (state.direction === "forward") {
      state.currentVertexPosition++;
      state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
      );
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    }
  }
};

DrawCorridor.updateOffset = function (state, offset) {
  this.offset = Math.max(Math.min(200, Number(offset)), 20);
  this.updateCorridor(state);
};

DrawCorridor.clickOnVertex = function (state, _e) {
  if (this.mode === "CENTERLINE") {
    this.mode = "BUFFER";
    this.setHelperMessage(
      "Adjust the buffer distance by moving the cursor and finish drawing the corridor by;",
      [
        "Clicking anywhere you think the buffer distance is enough.",
        "Pressing “Enter/Return” key.",
      ]
    );

    state.line.removeCoordinate(state.line.coordinates.length - 1);
  }
};

DrawCorridor.onMouseMove = function (state, e) {
  if (this.mode === "BUFFER") {
    const { lng, lat } = e.lngLat;
    const point = turf.point([lng, lat]);

    const line = turf.cleanCoords(state.line, { mutate: false });
    const distance = turf.pointToLineDistance(point, line, {
      units: this.units,
    });
    this.updateOffset(state, distance);
  }
  if (this.mode === "CENTERLINE") {
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
  }
  if (state.line.coordinates.length > 1) {
    this.updateCorridor(state);
  }
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }
};

DrawCorridor.onTap = DrawCorridor.onClick = function (state, e) {
  if (this.mode === "CENTERLINE") {
    if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);
    this.clickAnywhere(state, e);
  } else if (this.mode === "BUFFER") {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.corridor.id],
    });
  }
};

DrawCorridor.onKeyDown = function (state, e) {
  if (e.keyCode === 68) {
    this.offset += 2;
  }
  if (e.keyCode === 65) {
    this.offset -= 2;
  }

  this.updateCorridor(state);
};

DrawCorridor.onKeyUp = function (state, e) {
  if (CommonSelectors.isEnterKey(e)) {
    if (this.mode === "CENTERLINE" && state.line.coordinates.length > 2) {
      state.line.removeCoordinate(state.line.coordinates.length - 1);
      this.mode = "BUFFER";
      this.setHelperMessage(
        "Adjust the buffer distance by moving the cursor and finish drawing the corridor by;",
        [
          "Clicking anywhere you think the buffer distance is enough.",
          "Pressing “Enter/Return” key.",
        ]
      );
      this.this.updateCorridor(state);
    } else if (this.mode === "BUFFER") {
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.corridor.id],
      });
    }
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id, state.corridor.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

DrawCorridor.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  if (state.line.isValid()) {
    const length = turf.length(state.line.toGeoJSON(), { units: this.units });
    const polygon = state.corridor.toGeoJSON();
    polygon.properties.length = length;
    polygon.properties.lengthUnit = this.units;
    polygon.properties.shapeType = "corridor";
    polygon.properties.centerline = JSON.stringify(
      state.line.toGeoJSON().geometry.coordinates
    );

    this.deleteFeature([state.line.id], { silent: true });
    this.map.fire(Constants.events.CREATE, {
      features: [polygon],
    });
  } else {
    this.deleteFeature([state.line.id, state.corridor.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawCorridor.onTrash = function (state) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawCorridor.updateCorridor = function (state) {
  const bufferFeature = turf.buffer(state.line.toGeoJSON(), this.offset, {
    units: this.units,
  });
  if (bufferFeature)
    state.corridor.setCoordinates(bufferFeature.geometry.coordinates);
};

DrawCorridor.toDisplayFeatures = function (state, geojson, display) {
  if (geojson.geometry.type === Constants.geojsonTypes.POLYGON) {
    if (
      !geojson.geometry.coordinates ||
      geojson.geometry.coordinates.length === 0
    )
      return;

    const coordinateCount = geojson.geometry.coordinates[0].length;
    if (coordinateCount < 3) {
      return;
    }

    geojson.properties.meta = Constants.meta.FEATURE;
    return display(geojson);
  }

  const isActiveLine = geojson.properties.id === state.line.id;

  geojson.properties.active = isActiveLine
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  if (!isActiveLine) {
    return display(geojson);
  }
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return;
  geojson.properties.meta = Constants.meta.FEATURE;

  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[
        state.direction === "forward"
          ? geojson.geometry.coordinates.length -
            (this.mode === "CENTERLINE" ? 2 : 1)
          : 1
      ],
      `${
        state.direction === "forward"
          ? geojson.geometry.coordinates.length -
            (this.mode === "CENTERLINE" ? 2 : 1)
          : 1
      }`,
      false
    )
  );

  display(geojson);
};

export default DrawCorridor;
