import axios from "axios";
import { AxiosResponse, AxiosPromise } from "axios";

function normalizeData(data: any): any {
  let result = data;
  if (typeof data === "string") {
    try {
      result = JSON.parse(data);
    } catch (e) {
      result = data;
    }
  }
  return result;
}

axios.defaults.timeout = 600000;
class AjaxHelper {
  public static get<T = any>(url: string, options: any = {}): Promise<T> {
    return AjaxHelper.processAxiosResponse(axios.get(url), options);
  }

  public static post<T = any>(
    url: string,
    sendData: any,
    options: any = {}
  ): Promise<T> {
    return AjaxHelper.processAxiosResponse(axios.post(url, sendData), options);
  }

  public static postWithConfig<T = any>(
    url: string,
    sendData: any,
    options: any = {},
    config
  ): Promise<T> {
    return AjaxHelper.processAxiosResponse(
      axios.post(url, sendData, config),
      options
    );
  }

  public static put<T = any>(
    url: string,
    sendData: any,
    options: any = {}
  ): Promise<T> {
    return AjaxHelper.processAxiosResponse(axios.put(url, sendData), options);
  }

  public static delete<T = any>(url: string, options: any = {}): Promise<T> {
    return AjaxHelper.processAxiosResponse(axios.delete(url), options);
  }

  private static processAxiosResponse<T = any>(
    axiosPromise: AxiosPromise<T>,
    options: any = {}
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axiosPromise
        .then((res) => {
          const data = normalizeData(res.data);
          if (options.debug) {
            debugger;
          }
          return resolve(data);
        })
        .catch((err) => {
          if (options.debug) {
            debugger;
          }
          reject(err);
        });
    });
  }

  public static setupAxiosInterceptors(
    onUnauthenticated: (message: string) => void
  ) {
    const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
      return response;
    };

    const onResponseError = (error: any) => {
      if (error.status === 403) {
        AjaxHelper.removeAuthToken();
        onUnauthenticated("Your session expired!");
      } else if (error.response && error.response.status === 401) {
        AjaxHelper.removeAuthToken();
      }
      return Promise.reject(error);
    };

    // axios.interceptors.request.use(pathPrefix);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
    axios.defaults.withCredentials = true;
  }

  private static removeAuthToken() {
    localStorage.removeItem("auth-token");
  }
}

export const setToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setTokenGetter = (getTokenCallback: () => Promise<string>) => {
  axios.interceptors.response.use(
    (value) => value,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest.retriedOnce) {
        originalRequest.retriedOnce = true;
        setToken(await getTokenCallback());

        return axios(originalRequest);
      }
    }
  );
};

export { AjaxHelper };
