import * as React from "react";

/** Action Types */
export const SHOW_SIDEBAR: string = "ui/SHOW_SIDEBAR";
export const HIDE_SIDEBAR: string = "ui/HIDE_SIDEBAR";
export const SET_PANELS: string = "ui/SET_PANELS";
export const SET_MENU_CARD_PANEL: string = "ui/SET_MENU_CARD_PANEL";

export interface IUIState {
  fullSidebarMenu: boolean;
  headerComponent: React.ComponentClass;
  cardComponent: React.ComponentClass;
  filterComponent: React.ComponentClass;
  menuCampaignDetailExportComponent: React.ComponentClass;
  headerCampaignDetailComponent: React.ComponentClass;
  headerCampaignLandingComponent: React.ComponentClass;
}

/** Initial State */
const initialState: IUIState = {
  fullSidebarMenu: false,
  //@ts-ignore
  headerComponent: null,
  //@ts-ignore
  cardComponent: null,
  //@ts-ignore
  filterComponent: null,
  //@ts-ignore
  menuCampaignDetailExportComponent: null,
  //@ts-ignore
  headerCampaignDetailComponent: null,
  //@ts-ignore
  headerCampaignLandingComponent: null,
};

/** Reducer */
export function uiReducer(state = initialState, action: any) {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return Object.assign({}, state, {
        fullSidebarMenu: true,
      });

    case HIDE_SIDEBAR:
      return Object.assign({}, state, {
        fullSidebarMenu: false,
      });

    case SET_PANELS:
      return Object.assign({}, state, {
        headerComponent: action.headerComponent,
        cardComponent: action.cardComponent,
        filterComponent: action.filterComponent,
        menuCampaignDetailExportComponent:
          action.menuCampaignDetailExportComponent,
        headerCampaignDetailComponent: action.headerCampaignDetailComponent,
        headerCampaignLandingComponent: action.headerCampaignLandingComponent,
      });

    default:
      return state;
  }
}

/** Async Action Creator */
export function showSidebar() {
  return (dispatch) => {
    dispatch({
      type: SHOW_SIDEBAR,
    });
  };
}

export function hideSidebar() {
  return (dispatch) => {
    dispatch({
      type: HIDE_SIDEBAR,
    });
  };
}

export interface IUIPanels {
  headerComponent?: React.ComponentClass;
  cardComponent?: React.ComponentClass;
  filterComponent?: React.ComponentClass;
  menuCampaignDetailExportComponent?: React.ComponentClass;
  headerCampaignDetailComponent?: React.ComponentClass;
  headerCampaignLandingComponent?: React.ComponentClass;
}

/** Async Action Creator */
export function setPanels(uiPanels: IUIPanels) {
  return (dispatch) => {
    dispatch({
      type: SET_PANELS,
      headerComponent: uiPanels.headerComponent,
      cardComponent: uiPanels.cardComponent,
      filterComponent: uiPanels.filterComponent,
      menuCampaignDetailExportComponent:
        uiPanels.menuCampaignDetailExportComponent,
      headerCampaignDetailComponent: uiPanels.headerCampaignDetailComponent,
      headerCampaignLandingComponent: uiPanels.headerCampaignLandingComponent,
    });
  };
}
