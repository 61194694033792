import * as React from "react";

export class CarNumbers extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="white-holder has-header">
        <div className="white-holder__header planner_result">
          Estimated Car Numbers
        </div>
        <div className="white-holder__body">
          <div className="flex-col">
            <div className="f-col-12">
              <p className="h-200 margin-bottom">City</p>
              {this.props.filters.plannerResponse.car_numbers.map(
                (car, index) => (
                  <div className="fly-list-item slim" key={index}>
                    <div className="fly-list-item__wrapper">
                      <div className="flex-col">
                        <div className="f-col-4">
                          <b className="paragraph bold">{car.dma}</b>
                        </div>
                        <div className="f-col-6">
                          <b className="paragraph bold">{car.range}</b>
                        </div>
                        <div className="f-col-2">
                          <b className="paragraph bold">{car.count}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
