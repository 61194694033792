import * as React from "react";
import * as DayPicker from "react-day-picker";
import * as styled from "styled-components";
import autobind from "autobind-decorator";
import moment from "moment";
import { MaskedDateInput } from "components";
import "react-day-picker/lib/style.css";
import * as cs from "classnames";
import { AppHelper } from "helpers";

const StyledDayPicker = styled.default(DayPicker.default)`
`;

const DATE_FORMAT = "YYYY-MM-DD";

class Calendar extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  public getInitialState(props) {
    let start_date = AppHelper.getDateFromDateString(
      props.initial_start_date
        ? moment(props.initial_start_date).format(DATE_FORMAT)
        : moment().format(DATE_FORMAT)
    );
    let end_date = AppHelper.getDateFromDateString(
      props.initial_end_date
        ? moment(props.initial_end_date).format(DATE_FORMAT)
        : moment().format(DATE_FORMAT)
    );

    return {
      fromSet: start_date,
      toSet: end_date,
      from: start_date,
      to: end_date,
      enteredTo: end_date,
      initial_start_date: start_date,
      initial_end_date: end_date,
    };
  }

  public componentWillReceiveProps(nextProps) {
    if (this.state.initial_start_date == null) {
      if (nextProps.initial_start_date != null) {
        this.setState({
          initial_start_date: AppHelper.getDateFromDateString(
            nextProps.initial_start_date
          ),
          from: AppHelper.getDateFromDateString(nextProps.initial_start_date),
          fromSet: AppHelper.getDateFromDateString(
            nextProps.initial_start_date
          ),
        });
      } else {
        this.setState({
          initial_start_date: new Date(),
          from: new Date(),
          fromSet: new Date(),
        });
      }
    }

    if (this.state.initial_end_date == null) {
      if (nextProps.initial_end_date != null) {
        this.setState({
          initial_end_date: AppHelper.getDateFromDateString(
            nextProps.initial_end_date
          ),
          to: AppHelper.getDateFromDateString(nextProps.initial_end_date),
          toSet: AppHelper.getDateFromDateString(nextProps.initial_end_date),
          enteredTo: AppHelper.getDateFromDateString(
            nextProps.initial_end_date
          ),
        });
      } else {
        this.setState({
          initial_end_date: new Date(),
          to: new Date(),
          toSet: new Date(),
          enteredTo: new Date(),
        });
      }
    }
  }

  public componentDidMount() {
    if (this.props.elRef) {
      this.props.elRef(this);
    }
  }

  public render() {
    const { from, enteredTo } = this.state;

    const modifiers = {
      start: from,
      end: enteredTo,
    };

    const selectedDays = [from, { from, to: enteredTo }];

    return (
      <div
        className={cs(
          "fly-calendar",
          this.props.isOpened ? "is-open" : "is-close"
        )}
      >
        <div className="calendar-dropdown">
          <div className="calendar-dropdown__header">
            <b>Date Picker</b>
            <span className="close-btn" onClick={() => this.props.onClose()}>
              <i className="icon ozzy-calendar" />
            </span>
          </div>
          <div className={cs("calendar-dropdown__date-picker")}>
            <div className="input-box">
              <div className="start">
                <div className="fly-input">
                  <MaskedDateInput
                    minDate={
                      this.props.disabledDays
                        ? this.props.disabledDays.before
                        : null
                    }
                    value={this.state.from}
                    onDateChange={this.onStartDateChanged}
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="end">
                <div className="fly-input">
                  <MaskedDateInput
                    minDate={
                      this.props.disabledDays
                        ? this.props.disabledDays.before
                        : null
                    }
                    maxDate={
                      this.props.disabledDays
                        ? this.props.disabledDays.after
                        : null
                    }
                    value={this.state.to}
                    onDateChange={this.onEndDateChanged}
                  />
                </div>
              </div>
            </div>

            <StyledDayPicker
              numberOfMonths={1}
              month={this.props.initial_start_date}
              disabledDays={this.props.disabledDays}
              selectedDays={selectedDays}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
              renderDay={this.customRenderDay}
            />
          </div>
          <div className="calendar-dropdown__action-box">
            <button className="fly-button inline" onClick={this.resetPanel}>
              <b>RESET</b>
            </button>
            <button
              className={cs("fly-button primary", {
                disabled:
                  this.state.enteredTo === null || this.state.to === null,
              })}
              onClick={this.setDates}
            >
              <b>APPLY</b>
            </button>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private setDates() {
    let from = this.state.from;
    let to = this.state.to;

    from = moment(from).format(DATE_FORMAT);
    to = moment(to).format(DATE_FORMAT);

    this.setState({
      fromSet: from,
      toSet: to,
    });

    this.props.onApply({
      label: from + " - " + to,
      value: { start: from, end: to },
    });
  }

  @autobind
  private customRenderDay(day) {
    const date = day.getDate();
    let useIndicator = false;
    const { initial_start_date, initial_end_date } = this.state;
    if (
      initial_start_date &&
      initial_end_date &&
      initial_start_date !== initial_end_date &&
      day >= initial_start_date &&
      day <= initial_end_date
    ) {
      useIndicator = true;
    }
    return (
      <div>
        <div
          className={cs("", { indicator: useIndicator })}
          style={{
            display: "block",
          }}
        />
        <div>{date}</div>
      </div>
    );
  }

  @autobind
  private resetPanel() {
    let start_date = this.state.initial_start_date;
    let end_date = this.state.initial_end_date;

    this.setState({
      from: start_date,
      to: end_date,
      enteredTo: end_date,
      fromSet: start_date,
      toSet: end_date,
    });

    this.props.onReset(null);
  }

  @autobind
  public onStartDateChanged(val: Date) {
    const { to } = this.state;

    if (this.props.disabledDays && val < this.props.disabledDays.before) {
      this.setState({ from: this.props.disabledDays.before });
    } else if (!to || val <= to) {
      this.setState({ from: val });
    }
  }

  @autobind
  public onEndDateChanged(val: Date) {
    const { from } = this.state;
    if (!from || val >= from) {
      this.setState({ to: val, enteredTo: val });
    }
  }

  @autobind
  public isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay =
      from && (DayPicker as any).DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  @autobind
  public handleDayClick(day) {
    const { from, to } = this.state;

    if (
      this.props.disabledDays &&
      (day < this.props.disabledDays.before ||
        day > this.props.disabledDays.after)
    ) {
      return;
    }

    if (from && to && day >= from && day <= to) {
      this.setState({ from: day, to: null, enteredTo: null });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      });
    }
  }

  @autobind
  public handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }
}

export default Calendar;
