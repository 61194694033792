import * as React from "react";
import * as cs from "classnames";
import autobind from "autobind-decorator";
import { Constants } from "pages/Planner/Map/draw/Constants";
import { AppHelper } from "helpers/AppHelper";
import { ZipcodeFilter } from "./ZipcodeFilter";
import { Mapfences } from "./mapfences";
import { UploadGeojson } from "./UploadGeojson";
import { GlobalStore } from "helpers";
import { toggleModal } from "redux/modal";
export class Geofence extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const { isOpen, activeTab, zipcodes, drawing, events } = this.props.options;

    this.state = {
      isOpen: typeof isOpen !== "undefined" ? isOpen : false,
      activeTab: activeTab || "drawing",
      drawing: drawing || {
        shapes: [],
        geojsonFiles: [],
      },
      zipcodes: zipcodes || {
        selected_zipcodes: [],
        selected_zipcodes_geojson: [],
      },
      events,
    };

    events.on("geofence-on-geojson-file-removed", this.onRemoveGeojsonFile);
    events.on("geofence-on-filter-zipcode", this.onZipcodeFilter);
    events.on("mapfences-shapes-updated", this.onMapfenceUpdated);
    events.on("workspace-on-selected", this.onSelectedWorkspace);
    events.on("geofence-on-filter", this.onFilter);
    events.on("geofence-on-reset", this.onReset);
  }

  public componentWillUnmount() {
    const { events } = this.state;

    events.off("geofence-on-geojson-file-removed", this.onRemoveGeojsonFile);
    events.off("geofence-on-filter-zipcode", this.onZipcodeFilter);
    events.off("mapfences-shapes-updated", this.onMapfenceUpdated);
    events.off("workspace-on-selected", this.onSelectedWorkspace);
    events.off("geofence-on-filter", this.onFilter);
    events.off("geofence-on-reset", this.onReset);
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ ...this.props.options });
    }
  }

  public componentDidMount() {
    const { isOpen, activeTab } = this.state;

    if (isOpen) {
      if (activeTab === "drawing") {
        this.openMapfences();
      } else {
        this.openZipcodes();
      }
    }
  }

  public render() {
    const { isOpen, activeTab, zipcodes, drawing, events } = this.state;

    return (
      <div
        className="geofence-container"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div
          className={cs("item-select geofence")}
          style={{ display: "block" }}
        >
          <div className="item-select-geofence-header">
            <div
              className={cs("item-select-geofence-tab", {
                selected: activeTab === "drawing",
              })}
              onClick={this.openMapfences}
            >
              <p>DRAWING</p>
            </div>
            <div
              className={cs("item-select-geofence-tab", {
                selected: activeTab === "zipcodes",
              })}
              onClick={this.openZipcodes}
            >
              <p>ZIPCODES</p>
            </div>
          </div>
          <Mapfences
            events={events}
            drawing={drawing}
            isVisible={activeTab === "drawing"}
          />
          {activeTab === "drawing" ? (
            <UploadGeojson onUpload={this.onUploadGeojsonFile} />
          ) : (
            ""
          )}
        </div>
        <div
          className="white-holder has-header has-no-header-mobile"
          style={{ display: activeTab === "zipcodes" ? "block" : "none" }}
        >
          <div className="white-holder__header">
            <b className="header">ZIPCODES</b>
          </div>
          <div className="white-holder__content">
            <ZipcodeFilter events={events} zipcodes={zipcodes} />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  public onSelectedWorkspace(workspaceItem) {
    const allItems = this.state.drawing.shapes.concat(
      this.state.drawing.geojsonFiles
    );

    const exists = allItems.find((item) => {
      return item.workspaceId === workspaceItem.id;
    });

    if (exists) {
      return;
    }

    let newTab = "drawing";

    if (workspaceItem.type === "zipcodes") {
      newTab = "zipcodes";
    }

    const onChangeTab = (closeModal = false) => {
      this.state.events.emit("map-on-goto-city", {
        dma: workspaceItem.dma,
        noQuery: true,
      });

      if (
        workspaceItem.type === "shape" ||
        workspaceItem.type === "geojsonFile"
      ) {
        this.state.events.emit("map-features-clear-all-zipcodes", false);
        this.state.events.emit("map-features-select-from-map", false);
      } else {
        this.state.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
        this.state.events.emit(Constants.events.CLEAR_ALL_ITEMS);
        this.state.events.emit(Constants.events.DELETE_ALL);
      }

      setTimeout(() => {
        if (workspaceItem.type === "shape") {
          this.state.drawing.shapes.push(workspaceItem.data);
          this.state.drawing.shapes.forEach((shapeData) => {
            this.state.events.emit(
              Constants.events.ADD_PTC,
              shapeData.geojson.features[0]
            );
          });
        } else if (workspaceItem.type === "geojsonFile") {
          this.state.drawing.geojsonFiles.push(workspaceItem.data);
          this.drawGeoJSON(workspaceItem.data.geojson, workspaceItem.data.file);
        } else if (workspaceItem.type === "zipcodes") {
          this.state.events.emit("geofence-on-received-zipcodes", {
            zipcodes: workspaceItem.data.zipcodes,
            id: workspaceItem.id,
          });
        }

        this.setState(
          {
            activeTab: newTab,
            drawing: Object.assign({}, this.state.drawing),
          },
          () => {
            this.state.events.emit("geofence-on-receive-data", this.state);
          }
        );

        if (closeModal) {
          this.toggleChangeTabModal({});
        }
      });
    };

    if (this.state.activeTab !== newTab) {
      if (
        (this.state.activeTab === "drawing" &&
          this.state.drawing.shapes.length) ||
        (this.state.activeTab === "zipcodes" &&
          this.state.zipcodes.selected_zipcodes)
      ) {
        this.toggleChangeTabModal({
          isOpen: true,
          onConfirm: onChangeTab.bind(this, true),
        });
        return;
      }
    }

    onChangeTab();
  }

  @autobind
  public onZipcodeFilter(selected_zipcodes_geojson) {
    const selected_zipcodes = selected_zipcodes_geojson.map(
      (zip) => zip.zipcode
    );

    this.setState(
      { zipcodes: { selected_zipcodes, selected_zipcodes_geojson } },
      () => {
        this.state.events.emit("geofence-on-receive-data", this.state);
      }
    );
  }

  @autobind
  private openMapfences() {
    const { activeTab } = this.state;

    if (activeTab === "zipcodes") {
      this.state.events.emit("map-features-clear-all-zipcodes", false);
      this.state.events.emit(Constants.events.DELETE_ALL);
      this.state.events.emit(Constants.events.CLEAR_ALL_ITEMS);
    }

    this.setState(
      {
        activeTab: "drawing",
        zipcodes: {
          selected_zipcodes: [],
          selected_zipcodes_geojson: [],
        },
      },
      () => {
        this.state.events.emit("geofence-on-receive-data", this.state);
      }
    );

    this.state.events.emit("map-features-select-from-map", false);
  }

  @autobind
  private openZipcodes() {
    this.setState(
      {
        activeTab: "zipcodes",
        drawing: {
          shapes: [],
          geojsonFiles: [],
        },
      },
      () => {
        this.state.events.emit("map-features-select-from-map", false);
        this.state.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
        this.state.events.emit(Constants.events.CLEAR_ALL_ITEMS);
        this.state.events.emit(Constants.events.DELETE_ALL);

        this.state.events.emit("geofence-on-receive-data", this.state);
      }
    );
  }

  @autobind
  private onFilter() {
    this.state.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
  }

  @autobind
  private onMapfenceUpdated(shapes) {
    this.state.drawing.shapes = shapes;

    if (!shapes.length) {
      this.state.drawing.geojsonFiles = [];
    }

    this.setState({ drawing: this.state.drawing }, () => {
      this.state.events.emit("geofence-on-receive-data", { ...this.state });
    });
  }

  @autobind
  private onReset() {
    this.setDefaultOptions();
  }

  @autobind
  private setDefaultOptions() {
    this.setState(
      {
        activeTab: "drawing",
        drawing: {
          shapes: [],
          geojsonFiles: [],
        },
        zipcodes: {
          selected_zipcodes: [],
          selected_zipcodes_geojson: [],
        },
      },
      () => {
        this.state.events.emit("geofence-on-receive-data", this.state);
      }
    );

    this.state.events.emit("map-features-select-from-map", false);
    this.state.events.emit("map-features-clear-all-zipcodes", false);
    this.state.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
    this.state.events.emit(Constants.events.CLEAR_ALL_ITEMS);
    this.state.events.emit(Constants.events.DELETE_ALL);
  }

  @autobind
  public onUploadGeojsonFile(geojsonFile) {
    geojsonFile.geojson.id = AppHelper.uuidv4();

    this.drawGeoJSON(geojsonFile.geojson, geojsonFile.file);

    this.state.drawing.geojsonFiles.push(geojsonFile);

    this.setState({ drawing: this.state.drawing }, () => {
      this.state.events.emit("geofence-on-receive-data", this.state);
    });
  }

  @autobind
  public onRemoveGeojsonFile(geojsonFile) {
    this.state.drawing.geojsonFiles = this.state.drawing.geojsonFiles.filter(
      (_geojsonFile) => _geojsonFile.geojson.id !== geojsonFile.geojson.id
    );

    this.setState({ drawing: this.state.drawing }, () => {
      this.state.events.emit("geofence-on-receive-data", this.state);
    });
  }

  @autobind
  public drawGeoJSON(geojson, file = null) {
    if (geojson) {
      geojson.features.forEach((feature) => {
        feature.properties.shapeType = "Polygon";
        feature.properties.theme = "t1";
        feature.id = "f-import-" + AppHelper.uuidv4();

        if (file) {
          feature.properties.parentId = geojson.id;
          feature.properties.type = "fromFile";
        }

        this.state.events.emit(Constants.events.ADD_PTC, feature);
        this.state.events.emit(Constants.events.CREATE, {
          features: [feature],
        });
      });
    }
  }

  @autobind
  private toggleChangeTabModal(options = {}) {
    const modalChangeTab = {
      isOpen: false,
      content: "Are you sure you want to remove the current areas on the map?",
      confirmBtnClass: "primary",
      isConfirm: true,
    };

    GlobalStore.dispatch(
      toggleModal({
        ...modalChangeTab,
        ...options,
      })
    );
  }
}
