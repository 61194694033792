import { experimental } from "react-map-gl";
import { MapMouseEvent } from "./MapMouseEvent";

export class MapControlsEventAdapter extends (experimental.MapControls as any) {
  //@ts-ignore
  private getMapFn: () => any = null;
  private mouseOutAttached: boolean = false;

  public setupEvents(getMapFn) {
    this.getMapFn = getMapFn;
    this.events = [
      "mousedown",
      "mouseup",
      "mousemove",
      "touchmove",
      "touchstart",
      "touchend",
      "keydown",
      "keyup",
      "click",
      "contextmenu",
    ];
  }

  public handleEvent = (e) => {
    let map = this.getMapFn();
    if (!map || !e) {
      return;
    }

    let type = e.type;
    if (type === "keydown" || type === "keyup") {
      let {
        code,
        key,
        keyCode,
        charCode,
        location,
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
      } = e.srcEvent;
      let arg: any = {
        code,
        key,
        keyCode,
        charCode,
        location,
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
      };
      let ke = new KeyboardEvent(type, arg);
      map.getContainer().dispatchEvent(ke);
    } else if (
      type === "mousedown" ||
      type === "mouseup" ||
      type === "mousemove" ||
      type === "touchmove" ||
      type === "touchstart" ||
      type === "touchend"
    ) {
      if (!this.mouseOutAttached) {
        e.srcEvent.target.addEventListener("mouseout", (outEvent) => {
          let {
            x,
            y,
            clientX,
            clientY,
            screenX,
            screenY,
            button,
            buttons,
            movementX,
            movementY,
            offsetX,
            offsetY,
            pageX,
            pageY,
          } = outEvent;
          let arg: any = {
            x,
            y,
            clientX,
            clientY,
            screenX,
            screenY,
            button,
            buttons,
            movementX,
            movementY,
            offsetX,
            offsetY,
            pageX,
            pageY,
          };
          map.getContainer().dispatchEvent(new MouseEvent("mouseout", arg));
        });
        this.mouseOutAttached = true;
      }
      const mapEvent = new MapMouseEvent(e.type, map, e.srcEvent);
      map.fire(mapEvent);
    } else if (type === "panmove") {
      if (!map.dragPan.isEnabled()) {
        return;
      }
    } else if (type === "contextmenu" || type === "click") {
      const mapEvent = new MapMouseEvent(e.type, map, e.srcEvent);
      map.fire(mapEvent);
    }

    return super.handleEvent(e);
  };
}
