import {
  doubleClickZoomEnable,
  doubleClickZoomDisable,
} from "../lib/double_click_zoom";
import { Constants } from "../Constants";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import { distance } from "@turf/turf";

let DrawLineString = MapboxDraw.modes.draw_line_string;
const EllipseMode: any = {
  onTap: DrawLineString.onTap,
  onClick: DrawLineString.onClick,
};

EllipseMode.onSetup = function (_opts) {
  const ellipse = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      shapeType: Constants.geojsonTypes.ELLIPSE,
      center: [0, 0],
      radius: 0,
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });
  this.addFeature(ellipse);
  this.clearSelectedFeatures();
  doubleClickZoomDisable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.setActionableState({
    trash: true,
  });
  return {
    ellipse,
  };
};

EllipseMode.clickAnywhere = function (state, e) {
  if (
    state.startPoint &&
    state.startPoint[0] !== e.lngLat.lng &&
    state.startPoint[1] !== e.lngLat.lat
  ) {
    this.updateUIClasses({ mouse: "pointer" });
    state.endPoint = [e.lngLat.lng, e.lngLat.lat];
    this.changeMode("simple_select", { featuresId: state.ellipse.id });
  }
  // on first click, save clicked point coords as starting for  ellipse
  const startPoint = [e.lngLat.lng, e.lngLat.lat];
  state.startPoint = startPoint;
};

EllipseMode.onMouseMove = function (state, e) {
  if (state.startPoint) {
    const center = state.startPoint;
    const end = [e.lngLat.lng, e.lngLat.lat];
    const radiusInKm = distance(center, end, { units: "kilometers" });
    const circleFeature = createGeoJSONCircle(
      center,
      radiusInKm,
      state.ellipse.id
    );
    state.ellipse.properties.center = center;
    state.ellipse.properties.radius = radiusInKm;
    state.ellipse.setCoordinates(circleFeature.geometry.coordinates);
  }
};

EllipseMode.onKeyUp = function (_state, e) {
  if (e.keyCode === 27) {
    // escape key
    return this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

EllipseMode.onStop = function (state) {
  doubleClickZoomEnable(this);
  this.updateUIClasses({ mouse: "none" });
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.ellipse.id) === undefined) {
    return;
  }

  // remove last added coordinate
  state.ellipse.removeCoordinate("0.4");
  if (state.ellipse.isValid()) {
    this.map.fire("draw.create", {
      features: [state.ellipse.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.ellipse.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

EllipseMode.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.ellipse.id;
  geojson.properties.active = isActivePolygon ? "true" : "false";
  if (!isActivePolygon) {
    return display(geojson);
  }

  // Only render the rectangular polygon if it has the starting point
  if (!state.startPoint) {
    return;
  }
  return display(geojson);
};

EllipseMode.onTrash = function (state) {
  this.deleteFeature([state.ellipse.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

// create a circle-like polygon given a center point and radius
// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/39006388#39006388
function createGeoJSONCircle(center, radiusInKm, parentId, points = 64) {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
    properties: {
      parent: parentId,
    },
  };
}

export { EllipseMode };
