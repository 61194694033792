import * as React from "react";
import autobind from "autobind-decorator";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import { Constants } from "pages/Planner/Map/draw/Constants";

class GeofenceTypeSelectFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      top: 0,
      left: 0,
      inDrawMode: false,
      features: [],
      mile: 0,
      isCustomMile: false,
      disableToolboxContextMenu: true,
      pointInfo: null,
      body: document.querySelector("body"),
    };

    this.props.events.on("geofence-on-reset", this.onReset);
    this.props.events.on("map.left.clicked", (data) =>
      this.openSelect(data, "left")
    );
    this.props.events.on("map.right.clicked", (data) =>
      this.openSelect(data, "right")
    );
    this.props.events.on("map.geofenced.closed", this.closeSelect);
    this.props.events.on("geofence-point-on-set-info", this.onSetPointInfo);
    this.props.events.on(Constants.events.START_DRAW, this.onDrawStart);
    this.props.events.on(Constants.events.STOP_DRAW, this.onDrawEnd);
    this.props.events.on(
      "map.go.to.coordinates.clear.event",
      this.onSearchGoToCoordinatesClear
    );
  }

  public componentDidMount() {
    this.state.body.addEventListener("click", this.handleClickOutside);
  }

  public componentWillUnmount() {
    this.state.body.removeEventListener("click", this.handleClickOutside);
  }

  public render() {
    const { pointInfo } = this.state;

    let targeting = 0.0;
    if (pointInfo) {
      try {
        targeting = parseFloat(pointInfo[1].targeting.split(" "));
      } catch (err) {
        console.log(err.message);
      }
    }

    if (this.state.features.length > 0) {
      if (
        this.state.features[0].layer.source === "point-to-circle-layer-source"
      ) {
        return (
          <div
            className="geofence-ptc-miles"
            style={
              pointInfo
                ? { top: this.state.top - 400, left: this.state.left }
                : { top: this.state.top, left: this.state.left }
            }
          >
            {pointInfo ? pointInfo[0] : pointInfo}
            <div className="geofence-ptc-miles-group">
              {targeting > 0 ? (
                <div
                  className="mile__item double"
                  onClick={this.pointToCircle.bind(
                    this.pointToCircle,
                    targeting
                  )}
                >
                  <p>{targeting} Mile</p>
                </div>
              ) : (
                <>
                  <div
                    className="mile__item"
                    onClick={this.pointToCircle.bind(this.pointToCircle, "1")}
                  >
                    <p>1 Mile</p>
                  </div>
                  <div
                    className="mile__item"
                    onClick={this.pointToCircle.bind(this.pointToCircle, "2")}
                  >
                    <p>2 Mile</p>
                  </div>
                </>
              )}
              <div
                className={cs("mile__item", {
                  opened: this.state.isCustomMile,
                })}
                onClick={this.changeCustomMileState}
              >
                <p>Custom</p>
              </div>
              <div className="delete__item" onClick={this.removeShape}>
                <div className="item-trash-button">
                  <SVG
                    className="fly-svg-icon fly-svg-icon--small trash-icon"
                    src={"/public/images/icons/hm-trash.svg"}
                  />
                </div>
              </div>
            </div>
            <div
              className={cs("input__item", { opened: this.state.isCustomMile })}
            >
              <input
                type="text"
                placeholder="Mile"
                onChange={this.onMileTextChange}
                onKeyPress={this.keyPress}
              />
              <div
                className="mile__item btn-apply"
                onClick={this.onClickMileApply}
              >
                <p>APPLY</p>
              </div>
            </div>
          </div>
        );
      } else if (
        this.state.features[0].properties.user_shapeType !== "Zipcode"
      ) {
        return (
          <div
            className="geofence-type-contextmenu"
            style={{ top: this.state.top, left: this.state.left }}
          >
            {this.props.menuItems &&
              this.props.menuItems.length &&
              this.props.menuItems.map((item, i) => {
                return (
                  <div
                    className="menu-item"
                    key={i}
                    onClick={this.onClickMenuItem.bind(this, item.onClick)}
                  >
                    {item.title}
                  </div>
                );
              })}
            <div className="menu-item" onClick={this.removeShape}>
              <p>Delete</p>
              <div className="item-trash-button">
                <SVG
                  className="fly-svg-icon fly-svg-icon--small trash-icon"
                  src={"/public/images/icons/hm-trash.svg"}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    if (
      !this.state.isOpen ||
      this.state.inDrawMode ||
      this.state.disableToolboxContextMenu
    ) {
      return null;
    }

    return (
      <div
        className="geofence-type-select"
        style={{ top: this.state.top, left: this.state.left }}
      >
        <div
          className="geofence-type-select__item"
          onClick={this.startDraw.bind(
            this.startDraw,
            Constants.modes.DRAW_ELLIPSE
          )}
        >
          <SVG
            src={"/public/images/icons/hm-circle-unselected.svg"}
            className="fly-svg-icon fly-svg-icon--large icon"
          />
          <p>Circle</p>
        </div>
        <div
          className="geofence-type-select__item"
          onClick={this.startDraw.bind(
            this.startDraw,
            Constants.modes.DRAW_RECTANGLE
          )}
        >
          <SVG
            src={"/public/images/icons/hm-rectangle-unselected.svg"}
            className="fly-svg-icon fly-svg-icon--large icon"
          />
          <p>Rectangle</p>
        </div>
        <div
          className="geofence-type-select__item"
          onClick={this.startDraw.bind(
            this.startDraw,
            Constants.modes.DRAW_POLYGON
          )}
        >
          <SVG
            src={"/public/images/icons/hm-polygon-unselected.svg"}
            className="fly-svg-icon fly-svg-icon--large icon"
          />
          <p>Polygon</p>
        </div>
        <div
          className="geofence-type-select__item"
          onClick={this.startDraw.bind(
            this.startDraw,
            Constants.modes.DRAW_POINT
          )}
        >
          <SVG
            src={"/public/images/icons/hm-point-unselected.svg"}
            className="fly-svg-icon fly-svg-icon--large icon"
          />
          <p>Point to Circle</p>
        </div>
      </div>
    );
  }

  @autobind
  public set_geofence_mile(mile) {
    if (this.state.pointInfo) {
      try {
        this.state.pointInfo[1].geofence_mile = parseFloat(mile);
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  @autobind
  public keyPress(e) {
    if (e.key === "Enter") {
      let mile = e.target.value;
      this.set_geofence_mile(mile);
      this.props.events.emit("point-to-circle-layer-circle-mile-entered", mile);
      this.setState({ isOpen: false, features: [], pointInfo: null });
    }
  }

  @autobind
  public onClickMileApply() {
    let mile = this.state.mile;
    this.set_geofence_mile(mile);
    this.props.events.emit("point-to-circle-layer-circle-mile-entered", mile);
    this.setState({ isOpen: false, features: [], pointInfo: null });
  }

  @autobind
  private pointToCircle(mile) {
    this.set_geofence_mile(mile);
    this.props.events.emit("point-to-circle-layer-circle-mile-entered", mile);
    this.setState({ isOpen: false, features: [], pointInfo: null });
  }

  @autobind
  private changeCustomMileState() {
    this.setState({ isCustomMile: !this.state.isCustomMile });
  }

  @autobind
  public onMileTextChange(e) {
    let mile = e.target.value;
    this.setState({ mile });
  }

  @autobind
  private onClickMenuItem(callback) {
    callback(this.state.features);
    this.setState({ isOpen: false, features: [] });
  }

  @autobind
  private removeShape() {
    if (
      this.state.features[0].layer.source === "location-search-layer-source"
    ) {
      this.props.events.emit("location-search-layer-clear-point");
    } else if (
      this.state.features[0].layer.source === "point-to-circle-layer-source"
    ) {
      this.props.events.emit("point-to-circle-layer-clear-point");
    } else if (
      this.state.features[0].layer.source === "ptc-circle-layer-source"
    ) {
      this.props.events.emit(
        "point-to-circle-layer-clear-circle",
        this.state.features[0]
      );
    } else {
      let ids = [this.state.features[0].properties.id];
      this.props.events.emit(Constants.events.REMOVE_SHAPE, { ids });
      this.props.events.emit(Constants.events.DELETE, ids);
    }
    this.setState({ isOpen: false, features: [] });
  }

  @autobind
  private onDrawStart() {
    this.setState({ inDrawMode: true });
  }

  @autobind
  private onDrawEnd() {
    this.setState({ inDrawMode: false });
  }

  @autobind
  private startDraw(type) {
    this.props.events.emit(Constants.events.START_DRAW);
    this.props.events.emit(Constants.events.SET_DRAW_TYPE, type);
    this.closeSelect();
  }

  @autobind
  private openSelect(data, clickType) {
    let state = {
      isOpen: false,
      top: data.clientY - 60,
      left: data.clientX - 45,
      features: [],
      isCustomMile: false,
    };

    if (data.features.length) {
      if (
        clickType === "left" &&
        data.features[0].layer.source === "point-to-circle-layer-source"
      ) {
        state.isOpen = true;
        state.features = data.features;
      } else if (
        clickType === "right" &&
        data.features[0].layer.source !== "point-to-circle-layer-source"
      ) {
        state.isOpen = true;
        state.features = data.features;
      }

      this.setState(state);
    }
  }

  @autobind
  private closeSelect() {
    this.setState({ isOpen: false });
  }

  @autobind
  private onSetPointInfo(info) {
    this.setState({ pointInfo: info });
  }

  @autobind
  private onReset() {
    this.setState({ pointInfo: null, isOpen: false });
    this.props.events.emit("location-search-layer-clear-point");
    this.props.events.emit("point-to-circle-layer-clear-point");
  }

  @autobind
  private onSearchGoToCoordinatesClear() {
    this.setState({ pointInfo: null });
  }

  @autobind
  public handleClickOutside(e) {
    if (e.path) {
      let closeState = true;

      e.path.forEach((el) => {
        if (el.classList) {
          if (
            el.classList.contains("geofence-ptc-miles") ||
            el.classList.contains("geofence-type-contextmenu") ||
            el.classList.contains("geofence-type-select")
          ) {
            closeState = false;
          }
        }
      });

      if (closeState) {
        this.setState({ isOpen: false, features: [] });
      }
    }
  }
}

export default GeofenceTypeSelectFilter;
