import * as React from "react";
import autobind from "autobind-decorator";
import ReactLoading from "react-loading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as cs from "classnames";
import { AjaxHelper } from "helpers";
import { Bouncer } from "@fireflyon/auth";

class SalesforceSettings extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selected_option: 0,
      is_updating_cache: false,
      update_result: "",
      is_updating_credentials: false,
      sf_credentials: { id: "", user: "", password: "", token: "" },
      login_test_result: "",
    };
  }

  public componentWillMount() {
    this.setState({
      is_updating_credentials: true,
    });

    let url = "/api/report/inventory-salesforce-credentials";
    AjaxHelper.get(url, {}).then((res) => {
      this.setState({
        sf_credentials: res,
        is_updating_credentials: false,
      });
    });
  }

  public render() {
    return (
      <div className="salesforce-settings">
        <div
          className={cs("white-holder", {
            "is-active": this.props.activeTab === 1,
          })}
          style={{ marginBottom: "20px" }}
        >
          <div className="white-holder__header">
            <b className="header">UPDATE SALESFORCE CACHES : </b>
            <button
              className="fly-button border-white"
              onClick={this.updateCache}
            >
              <b>Update</b>
            </button>
          </div>
          {this.state.is_updating_cache || this.state.update_result ? (
            <div className="white-holder__content">
              {this.state.is_updating_cache ? (
                <ReactLoading type="cylon" color="#FC8975" />
              ) : (
                this.state.update_result
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <Bouncer requiredPermissions={["geo|r:reportsalesforce"]}>
          <div
            className={cs("white-holder", {
              "is-active": this.props.activeTab === 1,
            })}
          >
            <Bouncer requiredPermissions={["geo|u:reportsalesforce"]}>
              <div className="white-holder__header">
                <b className="header">UPDATE SALESFORCE CREDENTIALS : </b>
                <button
                  className="fly-button border-white"
                  onClick={this.submitSFCredentials}
                >
                  <b>Update</b>
                </button>
              </div>
            </Bouncer>

            {this.state.is_updating_credentials ? (
              <ReactLoading type="cylon" color="#FC8975" />
            ) : (
              <div>
                <div className="white-holder__content">
                  <div className="white-holder__content">
                    <div className="fly-list-item__title">
                      <b className="main">User</b>
                      <p className="sub">
                        <input
                          type="text"
                          name={"user"}
                          value={this.state.sf_credentials.user}
                          onChange={this.handleSFCUserChange}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="white-holder__content">
                  <div className="fly-list-item__title">
                    <b className="main">Password</b>
                    <p className="sub">
                      <input
                        type="text"
                        name={"password"}
                        value={this.state.sf_credentials.password}
                        onChange={this.handleSFCPasswordChange}
                      />
                    </p>
                  </div>
                </div>
                <div className="white-holder__content">
                  <div className="fly-list-item__title">
                    <b className="main">Token</b>
                    <p className="sub">
                      <input
                        type="text"
                        name={"token"}
                        value={this.state.sf_credentials.token}
                        onChange={this.handleSFCTokenChange}
                      />
                    </p>
                  </div>
                </div>
                <Bouncer requiredPermissions={["geo|r:reportsalesforcelogin"]}>
                  <div className="white-holder__header">
                    <button
                      className="fly-button border-white"
                      onClick={this.loginTest}
                    >
                      <b>Test Login</b>
                    </button>
                    <b className="header" style={{ paddingLeft: "10px" }}>
                      {this.state.login_test_result}
                    </b>
                  </div>
                </Bouncer>
              </div>
            )}
          </div>
        </Bouncer>
      </div>
    );
  }

  @autobind
  public submitSFCredentials() {
    confirmAlert({
      title: "Are you sure ?",
      message: "New Password : " + this.state.sf_credentials.password,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.updateSFCredentials(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  @autobind
  public loginTest() {
    this.setState({
      login_test_result: "",
    });

    let url = "/api/report/inventory-testsalesforcelogin";
    AjaxHelper.get(url, {}).then((res) => {
      this.setState({
        login_test_result: res,
      });
    });
  }

  @autobind
  public handleComboChange(val) {
    let value = val ? val.value : 0;
    this.setState({ selected_option: value });
  }

  @autobind
  public handleSFCUserChange(event) {
    let new_sf_credentials = Object.assign({}, this.state.sf_credentials);
    new_sf_credentials.user = event.target.value;
    this.setState({ sf_credentials: new_sf_credentials });
  }

  @autobind
  public handleSFCPasswordChange(event) {
    let new_sf_credentials = Object.assign({}, this.state.sf_credentials);
    new_sf_credentials.password = event.target.value;
    this.setState({ sf_credentials: new_sf_credentials });
  }

  @autobind
  public handleSFCTokenChange(event) {
    let new_sf_credentials = Object.assign({}, this.state.sf_credentials);
    new_sf_credentials.token = event.target.value;
    this.setState({ sf_credentials: new_sf_credentials });
  }

  @autobind
  public updateSFCredentials() {
    if (this.state.is_updating_credentials === false) {
      if (
        this.state.sf_credentials.password === "" ||
        this.state.sf_credentials.password.includes("*")
      ) {
        return;
      }

      this.setState({
        is_updating_credentials: true,
        login_test_result: "",
      });

      let url = "/api/report/inventory-update-salesforce-credentials";
      AjaxHelper.post(
        url,
        JSON.parse(JSON.stringify(this.state.sf_credentials))
      ).then((res) => {
        this.setState({
          sf_credentials: res,
          is_updating_credentials: false,
        });
      });
    }
  }

  @autobind
  public updateCache() {
    if (this.state.is_updating_cache === false) {
      this.setState({
        is_updating_cache: true,
        update_result: "",
      });

      let url = "/api/report/campaign-cache/update?type=1";
      AjaxHelper.get(url, {}).then((res) => {
        this.setState({
          update_result: res,
          is_updating_cache: false,
        });
      });
    }
  }
}

export { SalesforceSettings };
