import * as React from "react";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import moment from "moment";

interface IProps {
  item: any;
  isSelected?: boolean;
  isEditingName?: boolean;
  onChangeName?: (e: React.ChangeEvent<HTMLInputElement>, item: any) => any;
  onBlur?: (item: any) => any;
  onDoubleClick?: (item: any) => any;
  onDelete?: (item: any) => any;
  onClick?: (item: any) => any;
}

const getTypeOfItem = (type) => {
  switch (type) {
    case "shape":
      return "DRAWING";
    case "geojsonFile":
      return "JSON";
    case "zipcodes":
      return "ZIP";
    default:
      return "";
  }
};

const WorkspaceItem: React.FC<IProps> = ({
  item,
  isSelected,
  isEditingName,
  onChangeName,
  onBlur,
  onDoubleClick,
  onDelete,
  onClick,
}) => {
  isSelected = Boolean(isSelected);
  isEditingName = Boolean(isEditingName);

  let from = "";

  if (item.shape_id && item.user_name) {
    const parts = item.user_name.split(" ");
    from = parts[0] + " " + parts[1][0] + ".";
  }

  return (
    <div
      className="workspace-item"
      onClick={() => {
        //@ts-ignore
        onClick(item);
      }}
    >
      <div
        className={cs("workspace-item__radio-btn", {
          selected: isSelected,
        })}
      >
        <div className="dot" />
      </div>
      <div className="workspace-item__name" title="">
        {isSelected && isEditingName ? (
          <input
            type="text"
            maxLength={20}
            value={
              typeof item.newName !== "undefined" ? item.newName : item.name
            }
            onChange={(e) => onChangeName && onChangeName(e, item)}
            onBlur={() => onBlur && onBlur(item)}
          />
        ) : (
          <span
            onDoubleClick={() => {
              onDoubleClick && onDoubleClick(item);
            }}
          >
            {typeof item.newName !== "undefined" ? item.newName : item.name}
          </span>
        )}
        <div className="workspace-item__date">
          {from &&
            "From " +
              from +
              " " +
              moment.utc(item.shared_date).local().format("MM/DD/YY HH:mm")}
          {!from &&
            moment.utc(item.create_date).local().format("MM/DD/YY HH:mm")}
        </div>
      </div>
      <div className="workspace-item__type-container">
        <div className="workspace-item__type" title="">
          {getTypeOfItem(item.type)}
        </div>
      </div>
      <div className="workspace-item__delete-item" title="Delete">
        <button
          onClick={() => {
            onDelete && onDelete(item);
          }}
        >
          <SVG
            src={"/public/images/icons/trash.svg"}
            className="fly-svg-icon"
          />
        </button>
      </div>
    </div>
  );
};

export default WorkspaceItem;
