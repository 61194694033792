import * as React from "react";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import Workspace from "components/Workspace/Workspace";
import autobind from "autobind-decorator";

export class RightControls extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { zoom: 9 };
    this.props.events.on("map-zoom-changed", this.setZoom);
  }

  public render() {
    return (
      <div className={cs("right-controls")}>
        <div className="control center zoom-control" title="Zoom Level">
          <b>{this.state.zoom.toFixed(1)}</b>
        </div>
        <div
          className="control center workspace-control"
          title="Workspace"
          onClick={this.openWorkspace}
        >
          <SVG
            src={"/public/images/icons/briefcase.svg"}
            className="fly-svg-icon control-icon"
          />
          <Workspace
            events={this.props.events}
            usertoken={this.props.usertoken}
          />
        </div>
      </div>
    );
  }

  @autobind
  private openWorkspace() {
    this.props.events.emit("workspace-show-popup");
  }

  @autobind
  private setZoom(zoom) {
    this.setState({ zoom });
  }
}
