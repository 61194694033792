let store = null;

export function registerStore(s: any) {
  store = s;
}

class GlobalStore {
  public static getStore() {
    return store;
  }

  public static getState() {
    return GlobalStore.getStore().getState();
  }

  public static dispatch(action) {
    return GlobalStore.getStore().dispatch(action);
  }

  public static dispatchAsync(action, timeout = 0) {
    return setTimeout(() => {
      GlobalStore.getStore().dispatch(action);
    }, timeout);
  }
}

export { GlobalStore };
