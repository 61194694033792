import * as React from "react";
import autobind from "autobind-decorator";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as cs from "classnames";
import { InventorySettings } from "pages/Settings/InventorySettings";
import { SalesforceSettings } from "pages/Settings/SalesforceSettings";
import { Bouncer, AuthContext } from "@fireflyon/auth";

const inventorySettingsPermissions = [
  "geo|u:reportcarnumbers",
  "geo|u:reportdmamultipliers",
  "geo|r:reportcarnumbers",
  "geo|r:reportdmamultipliers",
];

const salesforceSettingsPermissions = ["geo|r:reportsalesforce"];
class Settings0 extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.isAuthorized(
        undefined,
        undefined,
        inventorySettingsPermissions
      )
        ? 0
        : 1,
    };
  }

  public render() {
    return (
      <div className="fr-page has-tab">
        <div className="fly-tab">
          <Bouncer fallbackPermissions={inventorySettingsPermissions}>
            <div
              className={cs("fly-tab__item", {
                "is-active": this.state.activeTab === 0,
              })}
              onClick={this.changeTab.bind(this, 0)}
            >
              <i className="icon ozzy-fleet" />
              <b>INVENTORY</b>
            </div>
          </Bouncer>
          <Bouncer fallbackPermissions={salesforceSettingsPermissions}>
            <div
              className={cs("fly-tab__item", {
                "is-active": this.state.activeTab === 1,
              })}
              onClick={this.changeTab.bind(this, 1)}
            >
              <i className="icon ozzy-fleet" />
              <b>SALESFORCE</b>
            </div>
          </Bouncer>
        </div>

        {this.state.activeTab === 0 && (
          <InventorySettings activeTab={this.state.activeTab} />
        )}
        {this.state.activeTab === 1 && (
          <SalesforceSettings activeTab={this.state.activeTab} />
        )}
      </div>
    );
  }

  @autobind
  private changeTab(id) {
    this.setState({ activeTab: id });
  }
}

export const Settings = () => {
  const { isAuthorized } = React.useContext(AuthContext);
  return <Settings0 isAuthorized={isAuthorized} />;
};
