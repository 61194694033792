import React from "react";

import "./style.scss";
import SVG from "react-inlinesvg";

const ErrorPage: React.FC<any> = ({}) => {
  return (
    <div className="error-page-container">
      <div className="container">
        <div className="flex-col error-page_stage">
          <div className="f-col-6 error-page_stage-logo">
            <SVG src={"/public/images/firefly-logo.svg"} className="svg" />
          </div>
          <div className="f-col-6">
            <div className="row-error-text">
              <div className="col error-msg">
                <div>
                  <h5>STATUS CODE: 403</h5>
                  <br />
                  <h1>
                    Oops! You're not <br />
                    meant to be here.
                  </h1>
                  <br />
                  <p>
                    You do not have the required permission <br />
                    to access this page.
                  </p>
                </div>
              </div>
              <div className="col has-text-right assetHolder">
                <SVG src={"/public/images/errorAsset.svg"} className="svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
