import * as React from "react";
import { MessageDialogs } from "components/dialogbox/MessageDialogs";
import { RodalModal } from "components/RodalModal/RodalModal";
import { ToastContainer, Slide } from "react-toastify";

class App extends React.Component<any, any> {
  public render() {
    return (
      <>
        <div className="AppContainer" key="main">
          {this.props.children}
        </div>
        <MessageDialogs key="msg" />
        <ToastContainer transition={Slide} />
        <RodalModal />
      </>
    );
  }
}

export { App };
