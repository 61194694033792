export interface IParameterMap {
  [key: string]: any;
}

export class CustomQueryStringParameter {
  public static toCustomParameter(
    name: string,
    parameters: IParameterMap[] = [],
    appendAmpersand: boolean = true
  ) {
    let result = "";
    for (let parameterMap of parameters) {
      let currentParameterStr = "";
      for (let key in parameterMap) {
        if (parameterMap.hasOwnProperty(key)) {
          let val = parameterMap[key] + "";

          if (currentParameterStr.length > 0) {
            currentParameterStr += "~";
          }
          currentParameterStr += key + "_" + val;
        }
      }

      if (currentParameterStr.length > 0) {
        if (result.length > 0) {
          result += "~~";
        }

        result += currentParameterStr;
      }
    }

    return (appendAmpersand ? "&" : "") + name + "=" + result;
  }

  public static coordinatesToText(coordinates) {
    let result = "";
    for (let coordinate of coordinates) {
      if (result.length > 0) {
        result += "x";
      }

      result += coordinate[0] + "x" + coordinate[1];
    }
    return result;
  }
}
