import { AjaxHelper } from "./AjaxHelper";
import { CssHelper } from "./CssHelper";
import { AppConfig } from "./AppConfig";
import { AppHistory } from "./AppHistory";
import { GlobalStore } from "./GlobalStore";
import { AppHelper } from "./AppHelper";
import { Emitter } from "./Emitter";
import { DialogHelper } from "./DialogHelper";
import {
  CustomQueryStringParameter,
  IParameterMap as _IParameterMap,
} from "./CustomQueryStringParameter";
import { SupplierFunctionWrapper } from "./SupplierFunctionWrapper";

export { AjaxHelper };
export { CssHelper };
export { AppConfig };
export { AppHistory };
export { GlobalStore };
export { AppHelper };
export { Emitter };
export { CustomQueryStringParameter };
export type IParameterMap = _IParameterMap;
export { DialogHelper };
export { SupplierFunctionWrapper };

export function waitUntil(condition, action, delay = 10) {
  setTimeout(() => {
    if (condition()) {
      action();
    } else {
      waitUntil(condition, action, delay + 10);
    }
  }, delay);
}
