import React from "react";
import spinner from "./spinner.svg";
import "./spinner.scss";

export const Spinner = () => {
  return (
    <>
      <div className="spinner">
        <img src={spinner} alt="Spinner" />
      </div>
    </>
  );
};
