import * as React from "react";
import { connect } from "react-redux";
import {
  hideError,
  hideSuccess,
  hideConfirm,
  hideWarning,
} from "../../redux/dialogbox";
import { ConfirmMessageDialog } from "./ConfirmMessageDialog";
import { ErrorMessageDialog } from "./ErrorMessageDialog";
import { SuccessMessageDialog } from "./SuccessMessageDialog";
import { WarningMessageDialog } from "./WarningMessageDialog";
import { GlobalStore } from "../../helpers/GlobalStore";

class MessageDialogStateConnector extends React.Component<any, any> {
  constructor(props, context) {
    super(props, context);

    this.onDialogClose = this.onDialogClose.bind(this);
  }
  public onDialogClose(_e: any) {
    GlobalStore.dispatch(this.props.closeEvent());
  }

  public render() {
    let messageList = this.props.messageList || [];
    let showModal = messageList.length > 0;
    let item = showModal ? messageList[0] : null;

    let MessageDialog = this.props.messageDialog;

    return (
      <MessageDialog
        showModal={showModal}
        item={item}
        onClose={this.onDialogClose}
      />
    );
  }
}

function mapState2Props(closeEvent, queueName, messageDialog, state) {
  return {
    closeEvent,
    queueName,
    messageList: state.dialogbox[queueName],
    messageDialog,
  };
}

function createDialogStateConnector(closeEvent, queueName, messageDialog) {
  return connect(
    //@ts-ignore
    mapState2Props.bind(this, closeEvent, queueName, messageDialog),
    null
  )(MessageDialogStateConnector);
}

const ErrorMessageDialogWrapper = createDialogStateConnector(
  hideError,
  "errorList",
  ErrorMessageDialog
);
const SuccessMessageDialogWrapper = createDialogStateConnector(
  hideSuccess,
  "successList",
  SuccessMessageDialog
);
const ConfirmMessageDialogWrapper = createDialogStateConnector(
  hideConfirm,
  "confirmList",
  ConfirmMessageDialog
);
const WarningMessageDialogWrapper = createDialogStateConnector(
  hideWarning,
  "warningList",
  WarningMessageDialog
);

class MessageDialogs extends React.Component {
  public render() {
    return (
      <div>
        <ErrorMessageDialogWrapper />
        <SuccessMessageDialogWrapper />
        <ConfirmMessageDialogWrapper />
        <WarningMessageDialogWrapper />
      </div>
    );
  }
}

export { MessageDialogs };
