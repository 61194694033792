import * as Point from "@mapbox/point-geometry";
import { LngLat } from "mapbox-gl";

function mousePos(el: HTMLElement, e: any) {
  const rect = el.getBoundingClientRect();
  e = e.touches ? e.touches[0] : e;
  return new Point(
    e.clientX - rect.left - el.clientLeft,
    e.clientY - rect.top - el.clientTop
  );
}

export class Event {
  public type: string;
  public originalEvent: MouseEvent;
  public point: Point;
  public lngLat: LngLat;

  constructor(type: string, data: any = {}) {
    Object.assign(this, data);
    this.type = type;
  }
}

export class MapMouseEvent extends Event {
  public target: any;

  constructor(
    type: string,
    map: any,
    originalEvent: MouseEvent,
    data: any = {}
  ) {
    const point = mousePos(map.getCanvasContainer(), originalEvent);
    const lngLat = map.unproject(point);
    super(type, Object.assign({ point, lngLat, originalEvent }, data));
    this.target = map;
  }
}
