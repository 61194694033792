import * as React from "react";
import autobind from "autobind-decorator";

// env
const inBrowser = typeof window !== "undefined";
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isIE9 = UA && UA.indexOf("msie 9.0") > 0;

const Dialog = (props) => {
  const animation =
    (props.animationType === "enter"
      ? props.enterAnimation
      : props.leaveAnimation) || props.animation;
  const className = `rodal-dialog rodal-${animation}-${props.animationType}`;
  const CloseButton = props.showCloseButton ? (
    <span className="rodal-close" onClick={props.onClose} />
  ) : null;
  const { width, height, measure, duration, customStyles } = props;
  const style = {
    width: width + measure,
    height: height + measure,
    animationDuration: duration + "ms",
    WebkitAnimationDuration: duration + "ms",
  };
  const mergedStyles = { ...style, ...customStyles };

  return (
    <div style={mergedStyles} className={className}>
      {props.children}
      {CloseButton}
    </div>
  );
};

class RodalCustom extends React.Component<any, any> {
  private el: HTMLElement = null;
  public static defaultProps: {
    visible: boolean;
    showMask: boolean;
    customMaskStyles: {};
    closeOnEsc: boolean;
    className: string;
    customStyles: {};
    animation: string;
    duration: number;
    measure: string;
    enterAnimation: string;
    closeMaskOnClick: boolean;
    width: number;
    showCloseButton: boolean;
    leaveAnimation: string;
    height: number;
  };

  constructor(props) {
    super(props);
  }

  public state = {
    isShow: false,
    animationType: "leave",
  };

  public componentDidMount() {
    if (this.props.visible) {
      this.enter();
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      this.enter();
    } else if (this.props.visible && !nextProps.visible) {
      this.leave();
    }
  }

  @autobind
  public enter() {
    this.setState({
      isShow: true,
      animationType: "enter",
    });
  }

  @autobind
  public leave() {
    isIE9
      ? this.setState({ isShow: false })
      : this.setState({ animationType: "leave" });
  }

  public onKeyUp = (event) => {
    if (this.props.closeOnEsc && event.keyCode === 27) {
      this.props.onClose();
    }
  };

  @autobind
  public animationEnd() {
    if (this.state.animationType === "leave") {
      this.setState({ isShow: false });
    } else if (this.props.closeOnEsc) {
      this.el.focus();
    }
  }

  public render() {
    const { props, state } = this;
    const onClick = props.closeMaskOnClick ? props.onClose : null;
    const mask = props.showMask ? (
      <div
        className="rodal-mask"
        style={props.customMaskStyles}
        onClick={onClick}
      />
    ) : null;
    const style = {
      display: state.isShow ? "" : "none",
      animationDuration: props.duration + "ms",
      WebkitAnimationDuration: props.duration + "ms",
    };

    return (
      <div
        style={style}
        className={
          "fly-modal rodal-fade-" + state.animationType + " " + props.className
        }
        onAnimationEnd={this.animationEnd}
        tabIndex={-1}
        ref={this.bindElement}
        onKeyUp={this.onKeyUp}
      >
        {mask}
        <Dialog {...props} animationType={state.animationType}>
          {props.children}
        </Dialog>
      </div>
    );
  }

  @autobind
  public bindElement(el) {
    this.el = el;
  }
}

RodalCustom.defaultProps = {
  width: 680,
  height: 600,
  measure: "px",
  visible: false,
  showMask: true,
  closeOnEsc: false,
  closeMaskOnClick: true,
  showCloseButton: true,
  animation: "zoom",
  enterAnimation: "",
  leaveAnimation: "",
  duration: 300,
  className: "",
  customStyles: {},
  customMaskStyles: {},
};

export default RodalCustom;
