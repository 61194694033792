import * as React from "react";
import * as PropTypes from "prop-types";
import { InteractiveMap, Viewport, FlyToInterpolator } from "react-map-gl";
import { withMonitorSize } from "components";
import autobind from "autobind-decorator";
import * as _ from "underscore";
import { Emitter, SupplierFunctionWrapper, waitUntil } from "helpers";
import { MapBoxDrawControlAdapter } from "pages/Planner/Map/draw/MapBoxDrawControlAdapter";
import { MapControlsEventAdapter } from "pages/Planner/Map/draw/MapControlsEventAdapter";
import { IMapBounds } from "./MapboxWrapper";
import { MapBoxDrawControl } from "pages/Planner/Map/draw/MapBoxDrawControl";
import { Constants } from "pages/Planner/Map/draw/Constants";

interface IInteractiveMapboxWrapperState {
  viewport: Viewport;
  width: number;
  height: number;
}

interface IInteractiveMapboxWrapperProps {
  drawControlEnabled?: boolean;
  events?: Emitter;
  onBoundsChange?: (b: IMapBounds) => void;
  onComponentSizeChange?: (width: number, height: number) => void;
  onViewportChange?: (v: Viewport) => void;
  children?: any;
  onClick?: (e) => void;
  mapStyle?: string;
  onLoad?: (e) => void;
  initialLat: number;
  initialLon: number;
  minZoom?: number;
  maxZoom?: number;
}

class LocationSupplierFunctionWrapper {
  public setValue: (long, lat, zoom) => any = () => null;
}

interface IInteractiveMapboxWrapperInternalProps
  extends IInteractiveMapboxWrapperProps {
  size: any;
  bindMapBoxDrawControlRef: (ref) => void;
  boundsWrapperFunction: SupplierFunctionWrapper;
  mapWrapperFunction: SupplierFunctionWrapper;
  mapFloatterFunction: LocationSupplierFunctionWrapper;
}

class InteractiveMapboxWrapperSizeAware extends React.Component<
  IInteractiveMapboxWrapperInternalProps,
  IInteractiveMapboxWrapperState
> {
  public static childContextTypes = {
    viewport: PropTypes.object,
  };

  private interactiveMapRef: InteractiveMap = null;
  // @ts-ignore
  public mapBoxDrawControlRef: MapBoxDrawControlAdapter = null;
  // @ts-ignore
  private mapControls: MapControlsEventAdapter = null;

  constructor(props) {
    super(props);

    let size = this.props.size || {};
    let width = size.width || 400;
    let height = size.height || 400;
    this.state = {
      viewport: {
        latitude: this.props.initialLat,
        longitude: this.props.initialLon,
        zoom: 9,
        pitch: 0,
      },
      width,
      height,
    };

    this.onComponentSizeChanged = _.debounce(
      this.onComponentSizeChanged.bind(this),
      500
    );
    if (width > 0) {
      this.onComponentSizeChanged(width, height);
    }

    this.props.boundsWrapperFunction.getValue = () => {
      let map = this._getMap();
      if (map) {
        return toBoundsObject(map);
      } else {
        return null;
      }
    };

    this.props.mapWrapperFunction.getValue = () => {
      return this._getMap();
    };

    this.props.mapFloatterFunction.setValue = (long, lat, zoom) => {
      return this._goToLocation(long, lat, zoom);
    };

    if (this.props.events) {
      this.props.events.on(
        Constants.events.SET_LABELS_VISIBLE,
        this.setLabelsVisible
      );
      this.props.events.on(Constants.events.SET_PITCH, this.setPitch);
    }
  }

  public componentWillMount() {
    let { drawControlEnabled } = this.props;
    if (drawControlEnabled) {
      this.mapControls = new MapControlsEventAdapter();
      this.mapControls.setupEvents(this._getMap);
    }
  }

  public render() {
    let { drawControlEnabled, onLoad } = this.props;
    let mapStyle = this.props.mapStyle || "mapbox://styles/mapbox/light-v9";
    return (
      <>
        <InteractiveMap
          ref={this.bindInteractiveMapRef}
          mapControls={this.mapControls as any}
          width={this.state.width}
          height={this.state.height}
          {...this.state.viewport}
          mapStyle={mapStyle}
          mapboxApiAccessToken="pk.eyJ1IjoiYWFraWxsaSIsImEiOiJjamh2MWF5c2MwdWphM3Z0Y2RqYmlqMGZsIn0.zkQGnysSVKd246gysUUOUQ"
          onViewportChange={this.onViewportChange}
          onLoad={onLoad}
          preserveDrawingBuffer
        >
          {this.props.children}
        </InteractiveMap>
        {drawControlEnabled && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              pointerEvents: "none",
            }}
          >
            <MapBoxDrawControlAdapter
              getInteractiveMap={this.getInteractiveMap}
              // @ts-ignore
              events={this.props.events}
              ref={this.bindMapBoxDrawControlRef}
            />
          </div>
        )}
      </>
    );
  }

  @autobind
  public _goToLocation(long, lat, zm) {
    const viewport = {
      ...this.state.viewport,
      longitude: long,
      latitude: lat,
      zoom: zm,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    };
    this.setState({ viewport });
  }

  @autobind
  public getInteractiveMap() {
    // console.log('this.interactiveMapRef', this.interactiveMapRef);
    return this.interactiveMapRef;
  }

  @autobind
  private _getMap() {
    if (this.interactiveMapRef) {
      return this.interactiveMapRef.getMap();
    } else {
      return null;
    }
  }

  @autobind
  public onViewportChange(viewport: Viewport) {
    // console.log(this.getZoom());
    // zoom change determine here
    this.setState({ viewport }, () => {
      this.fireBoundsEvent();
    });

    if (this.props.onViewportChange) {
      this.props.onViewportChange(viewport);
    }
  }

  public onComponentSizeChanged(width, height) {
    this.setState({ width, height }, () => {
      if (this.props.onComponentSizeChange) {
        let condition = () => {
          return this.interactiveMapRef && this.interactiveMapRef.getMap();
        };

        waitUntil(condition, () => {
          // @ts-ignore
          this.props.onComponentSizeChange(width, height);
        });
      }
    });
  }

  @autobind
  private bindInteractiveMapRef(ref) {
    this.interactiveMapRef = ref;
  }

  @autobind
  private bindMapBoxDrawControlRef(ref) {
    this.mapBoxDrawControlRef = ref;

    if (this.props.bindMapBoxDrawControlRef) {
      this.props.bindMapBoxDrawControlRef(ref);
    }
  }

  private fireBoundsEvent() {
    let bounds = toBoundsObject(this._getMap());
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange(bounds);
    }
  }

  @autobind
  public getZoom() {
    return this.state.viewport.zoom;
  }

  public getChildContext() {
    return {
      viewport: this.state.viewport,
    };
  }

  @autobind
  public setPitch(pitch) {
    let viewport = this.state.viewport;
    viewport.pitch = pitch;
    this.setState({ viewport });
  }

  @autobind
  public setLabelsVisible(visible: boolean) {
    let map = this._getMap();
    if (!map) {
      return;
    }

    let layers = map.getStyle().layers;
    for (let layer of layers) {
      let isLabelLayer = /label|place|poi/.test(layer.id);

      if (isLabelLayer) {
        map.setLayoutProperty(
          layer.id,
          "visibility",
          visible ? "visible" : "none"
        );
      }
    }
  }
}

const InteractiveMapboxWrapperMonitorSize = withMonitorSize(
  InteractiveMapboxWrapperSizeAware
);

export class InteractiveMapboxWrapper extends React.Component<
  IInteractiveMapboxWrapperProps,
  any
> {
  private size = { width: 0, height: 0 };
  // @ts-ignore
  public mapBoxDrawControlRef: MapBoxDrawControlAdapter = null;
  private boundsWrapperFunction: SupplierFunctionWrapper = new SupplierFunctionWrapper();
  private mapWrapperFunction: SupplierFunctionWrapper = new SupplierFunctionWrapper();
  private mapFloatterFunction: LocationSupplierFunctionWrapper = new LocationSupplierFunctionWrapper();
  private viewport: Viewport = null;

  public render() {
    let {
      drawControlEnabled,
      events,
      onBoundsChange,
      onClick,
      mapStyle,
      onLoad,
      initialLat,
      initialLon,
      minZoom,
      maxZoom,
    } = this.props;
    let passProps = {
      drawControlEnabled,
      events,
      onClick,
      mapStyle,
      onLoad,
      initialLat,
      initialLon,
      minZoom,
      maxZoom,
      onBoundsChange,
      boundsWrapperFunction: this.boundsWrapperFunction,
      mapWrapperFunction: this.mapWrapperFunction,
      onViewportChange: this.onViewportChange,
      onComponentSizeChange: this.onComponentSizeChange,
      bindMapBoxDrawControlRef: this.bindMapBoxDrawControlRef,
      mapFloatterFunction: this.mapFloatterFunction,
    };

    return (
      // @ts-ignore
      <InteractiveMapboxWrapperMonitorSize {...passProps}>
        {this.props.children}
      </InteractiveMapboxWrapperMonitorSize>
    );
  }

  @autobind
  private onComponentSizeChange(width, height) {
    this.size = { width, height };
    if (this.props.onComponentSizeChange) {
      this.props.onComponentSizeChange(width, height);
    }
  }

  @autobind
  private bindMapBoxDrawControlRef(ref) {
    this.mapBoxDrawControlRef = ref;
  }

  @autobind
  public getSize() {
    return this.size;
  }

  @autobind
  public getBounds() {
    return this.boundsWrapperFunction.getValue();
  }

  @autobind
  public getMapBoxDrawControl(): MapBoxDrawControl {
    return this.mapBoxDrawControlRef.getMapBoxDrawControl();
  }

  @autobind
  public onViewportChange(viewport: Viewport) {
    this.viewport = viewport;
  }

  @autobind
  public getMap() {
    return this.mapWrapperFunction.getValue();
  }

  @autobind
  public getZoom() {
    if (this.viewport) {
      return this.viewport.zoom;
    } else {
      return 9;
    }
  }

  @autobind
  public _goToLocation(long, lat, zoom) {
    this.mapFloatterFunction.setValue(long, lat, zoom);
  }
}

function toBoundsObject(map): IMapBounds {
  let bounds = map.getBounds();
  bounds.getNorthEast();
  let { lng: lng_ne, lat: lat_ne } = bounds.getSouthWest();
  let { lng: lng_sw, lat: lat_sw } = bounds.getNorthEast();

  let pixelRatio = window.devicePixelRatio || 1;
  const canvas = map.getCanvas();
  let { width, height } = canvas;
  width = width / pixelRatio;
  height = height / pixelRatio;

  const cUL = map.unproject([0, 0]).toArray();
  const cUR = map.unproject([width, 0]).toArray();
  const cLR = map.unproject([width, height]).toArray();
  const cLL = map.unproject([0, height]).toArray();
  // let abc = toLatLng(cUL) + toLatLng(cUR) + toLatLng(cLR) + toLatLng(cLL);
  // console.log(abc);
  // console.log(lng_ne, lat_ne, lng_sw, lat_sw);
  // console.log("mapboxNative.project({lng: marker.lng, lat: marker.lat})", map.project({lng: -118.458, lat: 33.988}));

  lng_ne = Math.max(cUR[0], cLR[0]);
  lat_ne = Math.max(cUR[1], cLR[1]);

  lng_sw = Math.min(cUL[0], cLL[0]);
  lat_sw = Math.min(cUL[1], cLL[1]);

  return {
    lng_ne,
    lat_ne,
    lng_sw,
    lat_sw,
  };
}

// function toLatLng(arr) {
//   return "{ lat: " + arr[1] + ", lng: " + arr[0] + "},\n";
// }
