import * as React from "react";
import { CityFilter } from "pages/Planner/Filter/CityFilter";
import { CalendarFilter } from "pages/Planner/Filter/CalendarFilter";
import { HourFilter } from "pages/Planner/Filter/HourFilter";
import { GeofenceFilter } from "pages/Planner/Filter/GeofenceFilter";
import autobind from "autobind-decorator";
import { Constants } from "pages/Planner/Map/draw/Constants";

export class Filter extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isFilterPage: true,
    };
  }

  public render() {
    return (
      <div className="fly-planner__filter">
        <div className="fly-planner__filter__content">
          <CityFilter events={this.props.events} filters={this.props.filters} />
          <CalendarFilter
            events={this.props.events}
            filters={this.props.filters}
          />
          <HourFilter events={this.props.events} filters={this.props.filters} />
          <GeofenceFilter
            events={this.props.events}
            filters={this.props.filters}
          />
        </div>
        <div className="fly-planner__filter__action-box">
          <button className="fly-button secondary" onClick={this.onReset}>
            <b>Reset</b>
          </button>
          <button className="fly-button primary" onClick={this.onFilter}>
            <b>Filter</b>
          </button>
        </div>
      </div>
    );
  }

  @autobind
  public onFilter() {
    this.props.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
    this.props.events.emit("planner-on-filter", {});
  }

  @autobind
  public onReset() {
    this.props.events.emit("planner-on-reset");
    this.props.events.emit("workspace-hide-popup");
  }
}
