import * as React from "react";
import autobind from "autobind-decorator";
import * as cs from "classnames";
import SVG from "react-inlinesvg";
import { AjaxHelper } from "helpers";
import poBoxZipcodes from "pages/Map/HeatMap/filter/poBoxZipcodes";
import { Constants } from "pages/Map/HeatMap/draw/Constants";
import { getNextThemeName } from "pages/Planner/Map/draw/modes/themeSupport";
import { getCityofLocation } from "helpers/CONSTANTS";

export class ZipcodeFilter extends React.Component<any, any> {
  private allZipCodeOptions: any[] = [];

  constructor(props) {
    super(props);

    let selectedZipcodes: any[] = [];

    if (this.props.zipcodes.selected_zipcodes.length > 0) {
      selectedZipcodes = this.props.zipcodes.selected_zipcodes_geojson;

      setTimeout(() => {
        selectedZipcodes.forEach((selectedZipcode) => {
          this.props.events.emit(Constants.events.ADD_ZIPCODE, selectedZipcode);
        });
      }, 50);
    }

    this.state = {
      zipCodeSelectPlaceholder: "Loading zip codes ...",
      selectedZipcodes,
      zipcode_textarea: this.props.zipcodes.selected_zipcodes.join(","),
      isZipcodeTextareaChanged: false,
      isSelectMapOn: false,
      workspaceId: null,
    };

    this.props.events.on(
      "geofence-on-received-zipcodes",
      this.onReceivedZipcodes
    );
    this.props.events.on(
      "map-features-on-click-zipcode-add",
      this.onZipcodeSelected
    );
    this.props.events.on(
      "map-features-on-click-zipcode-remove",
      this.onZipcodeRemoved
    );
    this.props.events.on("map-features-clear-all-zipcodes", this.reset);
  }

  public componentWillUnmount() {
    this.props.events.off(
      "map-features-on-click-zipcode-add",
      this.onZipcodeSelected
    );
    this.props.events.off(
      "map-features-on-click-zipcode-remove",
      this.onZipcodeRemoved
    );
    this.props.events.off("map-features-clear-all-zipcodes", this.reset);
  }

  public render() {
    const tooltipOfZipcodes = this.state.selectedZipcodes
      .map((zipcode) => zipcode.zipcode)
      .join(",");

    return (
      <div className="fly-zipcode">
        <div className={cs("fly-zipcode__wrapper is-active")}>
          <div className="fly-zipcode__switch">
            <p className="fly-label vertical small">Select from map</p>
            <div
              className={cs("fly-switch fly-switch--small", {
                "is-on": this.state.isSelectMapOn,
              })}
              onClick={this.toogleSelectMapOn}
            >
              <div className="toogle" />
            </div>
          </div>

          <div className="fly-zipcode__textarea">
            <textarea
              className="fly-textarea"
              placeholder={"Paste zipcode(s) here..."}
              onChange={this.onZipcodeTextChange}
              value={this.state.zipcode_textarea}
            />
          </div>

          <div className="fly-zipcode__action-box">
            <button
              className={cs("fly-button dark", {
                disabled:
                  !this.state.isZipcodeTextareaChanged ||
                  !this.state.zipcode_textarea,
              })}
              disabled={
                !this.state.isZipcodeTextareaChanged ||
                !this.state.zipcode_textarea
              }
              onClick={this.checkAndAddZipcodes}
            >
              <b>Apply</b>
            </button>
          </div>

          <div className="fly-zipcode__zipcode-list">
            <div style={{ clear: "both", display: "block", width: "100%" }}>
              {this.state.selectedZipcodes.length === 0 ? (
                ""
              ) : (
                <div
                  className="zipcode-item select-item"
                  title={tooltipOfZipcodes}
                >
                  <b>{this.state.selectedZipcodes.length} Zipcodes</b>
                  <div
                    className={cs("favorite-btn", {
                      exists: this.state.workspaceId,
                    })}
                    onClick={this.toggleWorkspace}
                  >
                    <SVG
                      className="fly-svg-icon fly-svg-icon--small favorite-icon"
                      src={"/public/images/icons/favorite.svg"}
                    />
                  </div>
                  <div className="delete">
                    <i className="icon ozzy-cross" onClick={this.reset} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  public toggleWorkspace() {
    if (this.state.workspaceId) {
      this.props.events.emit(
        "workspace-on-delete-request",
        this.state.workspaceId
      );

      const onDeleteSuccess = () => {
        this.setState({ workspaceId: null });
        this.props.events.off("workspace-on-delete-success", onDeleteSuccess);
      };

      this.props.events.on("workspace-on-delete-success", onDeleteSuccess);
    } else {
      let item = {
        type: "zipcodes",
        name: this.state.selectedZipcodes.length + " zipcodes",
        data: {
          zipcodes: this.state.selectedZipcodes.map(
            (selectedZipcode) => selectedZipcode.zipcode
          ),
          geojson: {
            features: this.state.selectedZipcodes.map(
              (selectedZipcode) => selectedZipcode.geojson.features[0]
            ),
          },
        },
        dma: getCityofLocation({
          lat: this.state.selectedZipcodes[0].geojson.features[0].geometry
            .coordinates[0][0][1],
          lng: this.state.selectedZipcodes[0].geojson.features[0].geometry
            .coordinates[0][0][0],
        }),
      };

      const onAddSuccess = (item) => {
        this.setState({ workspaceId: item.id });
        this.props.events.off("workspace-on-add-success", onAddSuccess);
      };

      this.props.events.emit("workspace-on-add-request", item);
      this.props.events.on("workspace-on-add-success", onAddSuccess);
    }
  }

  @autobind
  private updateTextAreaWithZipcodes(newZipcodes = [], callback) {
    let zipcode_textarea;
    let zipcodes = this.state.zipcode_textarea.split(",");

    zipcodes = zipcodes.concat(newZipcodes);

    zipcode_textarea = zipcodes.join(",");

    this.setState({ zipcode_textarea }, callback);
  }

  @autobind
  private onZipcodeSelected(zipcode) {
    this.setState({ workspaceId: null, isZipcodeTextareaChanged: false });
    this.updateTextAreaWithZipcodes([zipcode], () =>
      this.checkAndAddZipcodes()
    );
  }

  @autobind
  private onZipcodeRemoved(zipcode) {
    const zipcodeData = this.getSelectedZipcodeData(zipcode);

    let zipcode_textarea = this.state.zipcode_textarea;
    let zipcodes = zipcode_textarea.split(",");

    zipcodes = zipcodes.filter((z) => z !== zipcode);

    this.setState({
      selectedZipcodes: this.state.selectedZipcodes.filter(
        (zipcodeData) => zipcodeData.zipcode !== zipcode
      ),
      zipcode_textarea: zipcodes.map((z) => z).join(","),
      workspaceId: null,
    });

    this.props.events.emit(Constants.events.REMOVE_ZIPCODE, zipcodeData);
  }

  @autobind
  public toogleSelectMapOn() {
    this.setState({ isSelectMapOn: !this.state.isSelectMapOn }, () =>
      this.props.events.emit(
        "map-features-select-from-map",
        this.state.isSelectMapOn
      )
    );
  }

  @autobind
  public onZipcodeTextChange(e) {
    this.setState({
      zipcode_textarea: e.target.value
        .replace(/[ ,]+/g, ",")
        .replace(/(\r\n|\n|\r)/gm, ",")
        .replace(/[^0-9,]/g, ""),
      isZipcodeTextareaChanged: true,
      workspaceId: null,
    });
  }

  @autobind
  public async checkAndAddZipcodes() {
    let zipcodes = this.state.zipcode_textarea.split(",");
    let all_zipcodes = this.allZipCodeOptions.map((z) => z.label.trim());
    let valid_zipcodes_a = zipcodes.filter(
      (z) => all_zipcodes.indexOf(z.trim()) > -1
    );
    let invalid_zipcodes_a = zipcodes.filter(
      (z) => all_zipcodes.indexOf(z.trim()) === -1 && z.trim() !== ""
    );
    invalid_zipcodes_a = invalid_zipcodes_a.filter(
      (z) => poBoxZipcodes.indexOf(z.trim()) === -1 && z.trim() !== ""
    );

    if (valid_zipcodes_a.length > 0 || invalid_zipcodes_a.length > 0) {
      if (
        valid_zipcodes_a.length === 1 &&
        this.getSelectedZipcodeData(valid_zipcodes_a[0]) != null
      ) {
        this.setState({
          zipcode_textarea: this.state.selectedZipcodes
            .map((z) => z.zipcode)
            .join(","),
        });
        return;
      }

      let new_theme = "";
      if (valid_zipcodes_a.length > 0) {
        new_theme = getNextThemeName();
        let selectedZipcodes = this.state.selectedZipcodes;
        for await (let _zipcode of valid_zipcodes_a) {
          let selectedZipcode = this.getSelectedZipcodeData2(
            _zipcode,
            selectedZipcodes
          );
          if (selectedZipcode == null) {
            let zipcodeData = {
              zipcode: _zipcode,
              ids: [],
              theme: new_theme,
              geojson: null,
            };
            await this.fetchZipcodeGeoJson(new_theme, zipcodeData);

            selectedZipcodes.push(zipcodeData);
          } else {
            valid_zipcodes_a = valid_zipcodes_a.filter((z) => z !== _zipcode);
          }
        }

        this.setState(
          {
            selectedZipcodes,
            zipcode_textarea: selectedZipcodes.map((z) => z.zipcode).join(","),
            isZipcodeTextareaChanged: false,
          },
          () => {
            this.props.events.emit(
              "geofence-on-filter-zipcode",
              this.state.selectedZipcodes
            );
          }
        );
      } else {
        this.setState({ zipcode_textarea: "" });
      }
    }
  }

  public componentWillMount() {
    AjaxHelper.get("/api/geozip/codes", {}).then((res) => {
      this.allZipCodeOptions = res.map((zipcode) => {
        return { value: zipcode, label: zipcode };
      });
      this.setState({ zipCodeSelectPlaceholder: "Enter Zipcode" });
    });
  }

  @autobind
  public handleZipChange(val) {
    let value = val ? val.value : null;
    let themeName = getNextThemeName();

    let selectedZipcodes = this.state.selectedZipcodes;
    if (value) {
      let selectedZipcode = this.getSelectedZipcodeData(value);
      if (selectedZipcode == null) {
        let zipcodeData = {
          zipcode: value,
          ids: [],
          theme: themeName,
          geojson: null,
        };

        this.fetchZipcodeGeoJson(themeName, zipcodeData);
        selectedZipcodes.push(zipcodeData);
      }
    }

    this.setState({ selectedZipcodes });
  }

  private getSelectedZipcodeData(zipcode: string) {
    for (let zip of this.state.selectedZipcodes) {
      if (zip.zipcode === zipcode) {
        return zip;
      }
    }

    return null;
  }

  private getSelectedZipcodeData2(zipcode: string, selectedZipcodes) {
    for (let zip of selectedZipcodes) {
      if (zip.zipcode === zipcode) {
        return zip;
      }
    }

    return null;
  }

  private fetchZipcodeGeoJson(themeName: string, zipcodeData: any) {
    return AjaxHelper.get(
      "/api/geozip/geojson/" + zipcodeData.zipcode,
      {}
    ).then((res) => {
      zipcodeData.geojson = res;
      zipcodeData.geojson.features = zipcodeData.geojson.features.filter(
        (g) => g.geometry.type !== "Point"
      );
      if (zipcodeData.geojson.type === "FeatureCollection") {
        for (let feature of zipcodeData.geojson.features) {
          feature.properties = feature.properties || {};
          feature.properties.shapeType = Constants.geojsonTypes.ZIPCODE;
          feature.properties.zipcode = zipcodeData.zipcode;
          feature.properties.theme = themeName;
        }
      }

      this.props.events.emit(Constants.events.ADD_ZIPCODE, zipcodeData);
      this.setState({});
    });
  }

  @autobind
  public reset() {
    this.props.events.emit(
      Constants.events.CLEAR_ALL_ZIPCODES,
      this.state.selectedZipcodes
    );
    this.setState({ selectedZipcodes: [], zipcode_textarea: "" });
    this.props.events.emit("geofence-on-filter-zipcode", []);
  }

  @autobind
  public onReceivedZipcodes({ zipcodes, id }) {
    this.setState({ workspaceId: id });
    this.updateTextAreaWithZipcodes(zipcodes, () => this.checkAndAddZipcodes());
  }

  @autobind
  public getZipCodeCount() {
    return this.state.selectedZipcodes.length;
  }
}
