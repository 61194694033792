import * as React from "react";
import moment from "moment";
import SVG from "react-inlinesvg";

export class FilterSummary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    let isSameDate = false;
    let item: any = null;
    if (this.props.filters.selectedEvent) {
      item = this.props.filters.selectedEvent;
      isSameDate = item.date.start === item.date.end;
    }

    return (
      <div className="white-holder has-header">
        <div className="white-holder__header planner_result">
          Filter summary
        </div>
        <div className="white-holder__body">
          <div className="flex-col">
            {this.props.filters.selectedEvent ? (
              <div className="f-col-12">
                <div className="f-row">
                  <div className="event-item filter-result">
                    <div
                      className="event-item__image"
                      style={{ backgroundImage: `url(${item.photo})` }}
                    >
                      <div className="event-item__logo">
                        <SVG src={"/public/images/icons/firefly-logo.svg"} />
                      </div>
                      <div className="event-item__category">
                        {item.category} - {item.type}
                      </div>
                    </div>
                    <div className="event-item__info">
                      <div className="event-item__date">
                        {moment(item.event_date.start)
                          .format("ddd, DD MMM YYYY")
                          .toUpperCase()}
                        &nbsp;
                        {!isSameDate && (
                          <>
                            -{" "}
                            {moment(item.event_date.end)
                              .format("ddd, DD MMM YYYY")
                              .toUpperCase()}
                          </>
                        )}
                      </div>
                      <div className="event-item__name">{item.name}</div>
                      <div className="event-item__address">
                        <div className="fly-svg-icon-16-div">
                          <SVG
                            src={"/public/images/icons/location-point.svg"}
                            className="fly-svg-icon-16"
                          />
                        </div>
                        <span>{item.location}</span>
                      </div>
                      <div className="event-item__address">
                        <div className="fly-svg-icon-16-div">
                          <SVG
                            src={"/public/images/icons/location-point.svg"}
                            className="fly-svg-icon-16"
                          />
                        </div>
                        <span>{item.address}</span>
                      </div>
                      <div className="event-item__flight-info">
                        <b>Flight:</b>
                        <span>
                          {moment(item.date.start)
                            .format("ddd, DD MMM YYYY")
                            .toUpperCase()}
                          &nbsp;
                          {!isSameDate && (
                            <>
                              -{" "}
                              {moment(item.date.end)
                                .format("ddd, DD MMM YYYY")
                                .toUpperCase()}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {item ? (
              <>
                <div className="f-col-6 margin-top-20">
                  <div className="f-row">
                    <p className="h-200 planner-summary">Multiple</p>
                  </div>
                  <div className="f-row planner-summary">
                    <b>{item.multiplier}</b>
                  </div>
                </div>
                {item.geofence_mile > 0 ? (
                  <div className="f-col-6 margin-top-20">
                    <div className="f-row">
                      <p className="h-200 planner-summary">Geofence</p>
                    </div>
                    <div className="f-row planner-summary">
                      <b>{item.geofence_mile} Mile</b>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {this.props.filters.selected_dmas ? (
              <div className="f-col-12">
                <div className="f-row">
                  <p className="h-200 planner-summary">Cities</p>
                </div>
                <div className="f-row planner-summary">
                  <b>{this.props.filters.selected_dmas.join(", ")}</b>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.props.filters.selected_dates
              ? this.props.filters.selected_dates.map((date, index) => (
                  <div className="f-col-6 margin-top-20" key={index}>
                    <div className="f-row">
                      <p className="h-200 planner-summary">Dates</p>
                    </div>
                    <div className="f-row planner-summary">
                      <b>{moment(date.start).format("ddd, DD MMM YYYY")}</b>
                      <b>&nbsp;-&nbsp;</b>
                      <b>{moment(date.end).format("ddd, DD MMM YYYY")}</b>
                    </div>
                  </div>
                ))
              : ""}
            {this.props.filters.selected_hours
              ? this.props.filters.selected_hours.map((hour, index) => (
                  <div className="f-col-6 margin-top-20" key={index}>
                    <div className="f-row">
                      <p className="h-200 planner-summary">Hours</p>
                    </div>
                    <div className="f-row planner-summary">
                      <b>
                        {hour.from}&nbsp;-&nbsp;{hour.to}
                      </b>
                    </div>
                  </div>
                ))
              : ""}
            {this.props.filters.geofence.zipcodes.selected_zipcodes.length >
            0 ? (
              <div className="f-col-12">
                <div className="f-row">
                  <p className="h-200 planner-summary">Zip codes</p>
                </div>
                <div className="f-row planner-summary">
                  <b>
                    {this.props.filters.geofence.zipcodes.selected_zipcodes.join(
                      ", "
                    )}
                  </b>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
