export class Constants {
  public static classes = {
    CONTROL_BASE: "mapboxgl-ctrl",
    CONTROL_PREFIX: "mapboxgl-ctrl-",
    CONTROL_BUTTON: "mapbox-gl-draw_ctrl-draw-btn",
    CONTROL_BUTTON_LINE: "mapbox-gl-draw_line",
    CONTROL_BUTTON_POLYGON: "mapbox-gl-draw_polygon",
    CONTROL_BUTTON_RECTANGLE: "mapbox-gl-draw_rectangle",
    CONTROL_BUTTON_ELLIPSE: "mapbox-gl-draw_ellipse",
    CONTROL_BUTTON_POINT: "mapbox-gl-draw_point",
    CONTROL_BUTTON_TRASH: "mapbox-gl-draw_trash",
    CONTROL_BUTTON_COMBINE_FEATURES: "mapbox-gl-draw_combine",
    CONTROL_BUTTON_UNCOMBINE_FEATURES: "mapbox-gl-draw_uncombine",
    CONTROL_GROUP: "mapboxgl-ctrl-group",
    ATTRIBUTION: "mapboxgl-ctrl-attrib",
    ACTIVE_BUTTON: "active",
    BOX_SELECT: "mapbox-gl-draw_boxselect",
  };

  public static types = {
    POLYGON: "polygon",
    RECTANGLE: "rectangle",
    ELLIPSE: "ellipse",
    LINE: "line",
    TRASH: "trash",
  };

  public static modes = {
    DRAW_LINE_STRING: "draw_line_string",
    DRAW_POLYGON: "draw_polygon",
    DRAW_POINT: "draw_point",
    DRAW_RECTANGLE: "draw_rectangle",
    DRAW_ELLIPSE: "draw_ellipse",
    SIMPLE_SELECT: "simple_select",
    DIRECT_SELECT: "direct_select",
    STATIC: "static",
  };

  public static cursors = {
    ADD: "add",
    MOVE: "move",
    DRAG: "drag",
    POINTER: "pointer",
    NONE: "none",
  };

  public static sources = {
    HOT: "mapbox-gl-draw-hot",
    COLD: "mapbox-gl-draw-cold",
  };

  public static geojsonTypes = {
    FEATURE: "Feature",
    POLYGON: "Polygon",
    ELLIPSE: "Ellipse",
    ZIPCODE: "Zipcode",
    RECTANGLE: "Rectangle",
    LINE_STRING: "LineString",
    POINT: "Point",
    FEATURE_COLLECTION: "FeatureCollection",
    MULTI_PREFIX: "Multi",
    MULTI_POINT: "MultiPoint",
    MULTI_LINE_STRING: "MultiLineString",
    MULTI_POLYGON: "MultiPolygon",
  };

  public static polygonTypes = {
    POLYGON: "Polygon",
    ELLIPSE: "Ellipse",
    RECTANGLE: "Rectangle",
  };

  public static events = {
    CLEAR_ALL: "web.clear.all",
    CLEAR_ALL_ZIPCODES: "web.clear.all_zipcodes",
    CLEAR_ALL_GEOFENCES: "web.clear.all_geofences",
    START_DRAW: "web.draw.start",
    STOP_DRAW: "web.draw.stop",
    TRIGGER_FILTER: "web.filter.trigger",
    REQUEST_FILTER: "web.filter.request",
    RESPONSE_FILTER: "web.filter.response",
    REMOVE_SHAPE: "web.remove.shape",
    ADD_ZIPCODE: "web.add.zipcode",
    REMOVE_ZIPCODE: "web.remove.zipcode",
    CREATE: "draw.create",
    DELETE: "draw.delete",
    UPDATE: "draw.update",
    SELECTION_CHANGE: "draw.selectionchange",
    MODE_CHANGE: "draw.modechange",
    ACTIONABLE: "draw.actionable",
    RENDER: "draw.render",
    COMBINE_FEATURES: "draw.combine",
    UNCOMBINE_FEATURES: "draw.uncombine",

    SET_LABELS_VISIBLE: "web.mapbox.ui.set_labels_visible",
    SET_PITCH: "web.mapbox.ui.set_pitch",
    SET_CIRCLE_RADIUS: "web.mapbox.ui.set_circle_radius",
  };

  public static updateActions = {
    MOVE: "move",
    CHANGE_COORDINATES: "change_coordinates",
  };

  public static meta = {
    FEATURE: "feature",
    MIDPOINT: "midpoint",
    VERTEX: "vertex",
  };

  public static activeStates = {
    ACTIVE: "true",
    INACTIVE: "false",
  };
}
