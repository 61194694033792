import * as React from "react";
import autobind from "autobind-decorator";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import { AjaxHelper } from "helpers";
import * as cs from "classnames";
// import { CLIENT, USER } from "helpers/RBAC";
import { DMAS, DMAS_SHORT } from "helpers/CONSTANTS";
import { Bouncer } from "@fireflyon/auth";

class InventorySettings extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      is_updating_cars: false,
      cars: [],
      carsontheroad: {},
      dma_multipliers: {},
      is_updating_multipliers: false,
    };
  }

  public componentWillMount() {
    this.setState({
      is_updating_cars: true,
      is_updating_multipliers: true,
    });

    let url = "/api/report/inventory-car-numbers";
    AjaxHelper.get(url, {}).then((res) => {
      this.setState({
        cars: res.cars,
        is_updating_cars: false,
        carsontheroad: res.carsontheroad,
      });
    });

    url = "/api/report/get-dma-multipliers";
    AjaxHelper.get(url, {}).then((res) => {
      this.setState({
        dma_multipliers: res.dma_multipliers,
        is_updating_multipliers: false,
      });
    });
  }

  public render() {
    return (
      <div className="inventory-settings">
        <Bouncer
          fallbackPermissions={[
            "geo|r:reportcarnumbers",
            "geo|u:reportcarnumbers",
          ]}
        >
          <div
            className={cs("white-holder", {
              "is-active": this.props.activeTab === 0,
            })}
            style={{ marginBottom: "20px" }}
          >
            <Bouncer requiredPermissions={["geo|u:reportcarnumbers"]}>
              <div className="white-holder__header">
                <b className="header">UPDATE CAR NUMBERS : </b>
                <button
                  className="fly-button border-white"
                  onClick={this.updateCarNumbers}
                >
                  <b>Update</b>
                </button>
              </div>
            </Bouncer>

            <div style={{ overflowX: "scroll" }}>
              <div className="white-holder__content">
                <div className="white-holder__content">
                  <div className="fly-list-item__title">
                    {DMAS.map((dma, index) => {
                      return (
                        <div key={index}>
                          {index === 0 ? (
                            <b className="main">Today</b>
                          ) : (
                            <b className="main"></b>
                          )}
                          <p className="sub">{dma}</p>
                        </div>
                      );
                    })}
                    <div>
                      <b className="main"></b>
                      <p className="sub">
                        <b>Total Cars On The Road</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="white-holder__content">
                  {this.state.is_updating_cars ? (
                    <div className="header-loading">
                      <Skeleton count={1} />
                    </div>
                  ) : (
                    <div className="fly-list-item__title">
                      {DMAS_SHORT.map((dma_short, index) => {
                        return (
                          <div key={index}>
                            {index === 0 ? (
                              <b className="main">
                                {this.state.carsontheroad
                                  ? this.state.carsontheroad.date
                                  : ""}
                              </b>
                            ) : (
                              <b className="main"></b>
                            )}
                            <p className="sub">
                              {this.state.carsontheroad
                                ? this.state.carsontheroad[dma_short]
                                : ""}
                            </p>
                          </div>
                        );
                      })}
                      <div>
                        <b className="main"></b>
                        <p className="sub">
                          <b>
                            {this.state.carsontheroad
                              ? this.state.carsontheroad.total
                              : ""}
                          </b>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <br></br>
                <br></br>
              </div>
              <div className="white-holder__content">
                <div className="fly-list-item__title">
                  {DMAS.map((dma, index) => {
                    return (
                      <div key={index}>
                        {index === 0 ? (
                          <b className="main">Date</b>
                        ) : (
                          <b className="main"></b>
                        )}
                        <p className="sub">{dma}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="white-holder__content">
                {this.state.is_updating_cars ? (
                  <div className="header-loading">
                    <Skeleton count={2} />
                  </div>
                ) : (
                  this.state.cars.map((cars, index) => (
                    <div className="fly-list-item__title" key={index}>
                      {DMAS_SHORT.map((dma_short, index) => {
                        return (
                          <div key={index}>
                            {index === 0 ? (
                              <b className="main">{cars.date}</b>
                            ) : (
                              <b className="main"></b>
                            )}
                            <p className="sub">
                              <input
                                type="number"
                                name={cars.date}
                                value={cars[dma_short]}
                                onChange={this.handleCarNumberChange.bind(
                                  this,
                                  dma_short
                                )}
                              />
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </Bouncer>
        <Bouncer requiredPermissions={["geo|r:reportdmamultipliers"]}>
          <div
            className={cs("white-holder", {
              "is-active": this.props.activeTab === 0,
            })}
          >
            <Bouncer requiredPermissions={["geo|u:reportdmamultipliers"]}>
              <div className="white-holder__header">
                <b className="header">UPDATE IMPRESSION MULTIPLIERS : </b>
                <button
                  className="fly-button border-white"
                  onClick={this.updateDMAMultipliers}
                >
                  <b>Update</b>
                </button>
              </div>
            </Bouncer>

            <div style={{ overflowX: "scroll" }}>
              <div className="white-holder__content">
                <div className="fly-list-item__title">
                  {DMAS.map((dma, index) => {
                    return (
                      <div key={index}>
                        {index === 0 ? (
                          <b className="main">DMA</b>
                        ) : (
                          <b className="main"></b>
                        )}
                        <p className="sub">{dma}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="white-holder__content">
                {this.state.is_updating_multipliers ? (
                  <div className="header-loading">
                    <Skeleton count={1} />
                  </div>
                ) : (
                  <div className="fly-list-item__title" key={99}>
                    {DMAS_SHORT.map((dma_short, index) => {
                      return (
                        <div key={index}>
                          {index === 0 ? (
                            <b className="main">Multiplier</b>
                          ) : (
                            <b className="main"></b>
                          )}
                          <p className="sub">
                            <input
                              type="number"
                              value={this.state.dma_multipliers[dma_short]}
                              onChange={this.handleDMAMultiplierChange.bind(
                                this,
                                dma_short
                              )}
                            />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Bouncer>
      </div>
    );
  }

  @autobind
  public handleCarNumberChange(dma, event) {
    let new_cars = Object.assign([], this.state.cars);
    const index = this.state.cars.findIndex(
      (car) => car.date === event.target.name
    );
    new_cars[index][dma] = event.target.value;
    this.setState({ cars: new_cars });
  }

  @autobind
  public handleDMAMultiplierChange(dma, event) {
    let new_dma_multipliers = Object.assign({}, this.state.dma_multipliers);
    new_dma_multipliers[dma] = event.target.value;
    this.setState({ dma_multipliers: new_dma_multipliers });
  }

  @autobind
  public updateCarNumbers() {
    if (this.state.is_updating_cars === false) {
      this.setState({
        is_updating_cars: true,
      });

      let url = "/api/report/inventory-update-car-numbers";
      AjaxHelper.post(url, JSON.parse(JSON.stringify(this.state.cars))).then(
        (res) => {
          this.setState({
            is_updating_cars: false,
            cars: res.cars,
          });
        }
      );
    }
  }

  @autobind
  public updateDMAMultipliers() {
    if (this.state.is_updating_multipliers === false) {
      this.setState({
        is_updating_multipliers: true,
      });

      let url = "/api/report/update-dma-multipliers";
      AjaxHelper.post(
        url,
        JSON.parse(JSON.stringify(this.state.dma_multipliers))
      ).then((res) => {
        this.setState({
          is_updating_multipliers: false,
          dma_multipliers: res.dma_multipliers,
        });
      });
    }
  }
}

export { InventorySettings };
