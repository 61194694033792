import * as React from "react";
import ReactSelect from "react-select";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import autobind from "autobind-decorator";
import { AjaxHelper, AppHelper } from "helpers";
import moment from "moment";
import { DMAS } from "helpers/CONSTANTS";
import DatePicker from "components/DatePicker";

function compareOptions(a, b) {
  let valueA = a.label ? a.label.toUpperCase() : "";
  let valueB = b.label ? b.label.toUpperCase() : "";
  if (valueA === "ANY TYPE") {
    valueA = "AAAAAAAAAA";
  }
  if (valueB === "ANY TYPE") {
    valueB = "AAAAAAAAAA";
  }
  if (valueA === "ANY CATEGORY") {
    valueA = "AAAAAAAAAA";
  }
  if (valueB === "ANY CATEGORY") {
    valueB = "AAAAAAAAAA";
  }
  if (valueA === "OTHER") {
    valueA = "ZZZZZZZZZZ";
  }
  if (valueB === "OTHER") {
    valueB = "ZZZZZZZZZZ";
  }
  if (valueA === "OTHER") {
    valueA = "ZZZZZZZZZZ";
  }
  if (valueB === "OTHER") {
    valueB = "ZZZZZZZZZZ";
  }
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return comparison;
}

function toTitleCase(title) {
  try {
    if (title.length < 4) {
      return title;
    }
    return title.replace(/\w\S*/g, function (txt) {
      if (txt.length < 3) {
        return txt.toUpperCase();
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } catch (e) {
    console.log(e);
    return title;
  }
}

export default class EventList extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const ignoredCities = ["Las Vegas"];

    let dmaOptions = [{ value: 0, label: "All Cities" }];

    DMAS.forEach((dma, index) =>
      dmaOptions.push({ value: index + 1, label: dma })
    );

    dmaOptions = dmaOptions.filter(
      (city) => !ignoredCities.includes(city.label)
    );

    let categoryOptions = [{ value: null, label: "Any Category" }];

    let typeOptions = [{ value: null, label: "Any Type" }];

    const impressionOptions = [
      { value: null, label: "Sort by Impression" },
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    this.state = {
      loadingItems: false,
      selectedItem: null,
      clickedItem: null,
      selectedDMA: dmaOptions[0],
      selectedDate: null,
      selectedCategory: { value: null, label: "Any Category" },
      selectedType: { value: null, label: "Any Type" },
      selectedDateRange: null,
      selectedImpression: impressionOptions[0],
      showDatePicker: false,
      items: [],
      isOpen: false,
      events: this.props.events,
      dmaOptions,
      impressionOptions,
      categoryOptions,
      typeOptions,
      filter_text: "",
    };

    this.state.events.on("planner-on-reset", this.onReset);
    this.state.events.on("planner-on-filter", this.onHidePopup);
    this.state.events.on("events-show-popup", this.onShowPopup);
    this.state.events.on("events-hide-popup", this.onHidePopup);
  }

  public componentWillUnmount() {
    this.state.events.off("planner-on-reset", this.onReset);
    this.state.events.off("planner-on-filter", this.onHidePopup);
    this.state.events.off("events-show-popup", this.onShowPopup);
    this.state.events.off("events-hide-popup", this.onHidePopup);
  }

  public componentDidMount() {
    this.getItems();
  }

  public render() {
    const {
      isOpen,
      items,
      loadingItems,
      dmaOptions,
      impressionOptions,
      categoryOptions,
      selectedDMA,
      selectedCategory,
      selectedType,
      selectedImpression,
      typeOptions,
    } = this.state;

    let filteredItems = items.filter((item) => {
      return selectedDMA.value ? item.dma === selectedDMA.label : true;
    });

    let expr = new RegExp(this.state.filter_text.toLowerCase());

    filteredItems = filteredItems.filter((item) => {
      return expr.test(item.name.toLowerCase());
    });

    // Selected Date
    filteredItems = filteredItems.filter(this.filterDueDate);

    // Selected Category
    filteredItems = filteredItems.filter(this.filterDueCategory);

    // Selected Type
    filteredItems = filteredItems.filter(this.filterDueType);

    // Selected Impression
    if (selectedImpression.value) {
      filteredItems.sort(this.orderDueImpression);
    }

    return (
      <>
        <div
          className={cs("events-btn", {
            "display-none": !this.props.isVisible,
          })}
          onClick={this.togglePopup}
        >
          <div>EVENTS</div>
          <div>
            <SVG
              src={"/public/images/icons/beta.svg"}
              className="fly-svg-icon-beta"
            />
          </div>
          <div>
            <SVG
              src={
                "/public/images/icons/arrow-" +
                (isOpen ? "up2" : "down2") +
                ".svg"
              }
              className="fly-svg-icon"
            />
          </div>
        </div>
        {!isOpen && this.state.clickedItem && this.props.isVisible ? (
          <div className="events-btn name" onClick={this.onApply}>
            <div>{this.state.clickedItem.name}</div>
          </div>
        ) : (
          ""
        )}
        <div className="events-container" title="" hidden={!isOpen}>
          <div className="events-inner">
            <div className="title-container">
              <div className="fly-combo-box fly-combo-box--large-events fly-combo-box--has-icon has-select-value-margin-left">
                <SVG
                  src={"/public/images/icons/city-event.svg"}
                  className="svg-events"
                />
                <ReactSelect
                  className="select"
                  classNamePrefix="select"
                  defaultValue={dmaOptions[0]}
                  onChange={this.handleChangeDMA}
                  options={dmaOptions}
                />
              </div>
              <div onClick={this.onHidePopup}>
                <SVG
                  src={"/public/images/icons/hide-button.svg"}
                  className="fly-svg-icon control-icon hide-button"
                />
              </div>
            </div>

            <div className="filters">
              <div className="fly-search events">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={this.onTextChange}
                />
                <SVG
                  src={"/public/images/icons/search-icon-events.svg"}
                  className="svg16"
                />
              </div>
              <DatePicker
                disabledDays={{
                  before: AppHelper.getDateFromDateString(
                    moment().format("YYYY-MM-DD")
                  ),
                }}
                onDateRangeSelected={this.handleChangeDatePicker}
              />
              <div
                className={cs(
                  "fly-combo-box fly-combo-box--has-icon category-dropdown",
                  { selected: selectedCategory.value }
                )}
              >
                <ReactSelect
                  className="select"
                  classNamePrefix="select"
                  onChange={this.handleChangeCategory}
                  value={selectedCategory}
                  options={categoryOptions}
                />
              </div>
              <div
                className={cs(
                  "fly-combo-box fly-combo-box--has-icon type-dropdown",
                  { selected: selectedType.value }
                )}
              >
                <ReactSelect
                  className="select"
                  classNamePrefix="select"
                  onChange={this.handleChangeType}
                  value={selectedType}
                  options={typeOptions}
                />
              </div>
              <div
                className={cs(
                  "fly-combo-box fly-combo-box--has-icon impression-dropdown",
                  { selected: selectedImpression.value }
                )}
              >
                <ReactSelect
                  className="select"
                  classNamePrefix="select"
                  onChange={this.handleChangeImpression}
                  value={selectedImpression}
                  options={impressionOptions}
                />
              </div>
            </div>
            {loadingItems ? <div className="no-items">Loading...</div> : ""}
            {!filteredItems.length && (
              <div className="no-items">No event(s)</div>
            )}
            <div className="items">{filteredItems.map(this.getEventItem)}</div>
            {filteredItems.length && (
              <div className="action-box">
                <button className="fly-button secondary" onClick={this.onReset}>
                  <b>Cancel</b>
                </button>
                <button
                  className={cs("fly-button primary", {
                    disabled: !this.state.clickedItem,
                  })}
                  onClick={this.onApply}
                >
                  <b>Apply</b>
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  @autobind
  private getItems() {
    this.setState({ loadingItems: true });
    let categories: any[] = [];
    let types: any[] = [];
    AjaxHelper.get("/api/geolocation/get-map-events").then((res) => {
      res = this.removePastEvents(res);
      this.orderItems(res);
      for (let item of res) {
        item.isSelected = false;
        if (!categories.includes(item.category)) {
          categories.push(item.category);
        }
        if (!types.includes(item.type)) {
          types.push(item.type);
        }
      }

      for (let item of categories) {
        this.state.categoryOptions.push({
          value: item,
          label: toTitleCase(item),
        });
      }
      this.state.categoryOptions.sort(compareOptions);

      for (let item of types) {
        this.state.typeOptions.push({ value: item, label: toTitleCase(item) });
      }
      this.state.typeOptions.sort(compareOptions);

      this.setState({
        items: res,
        loadingItems: false,
      });
    });
  }

  @autobind
  private getEventItem(item, i) {
    const isSameDate = item.date.start === item.date.end;
    let isSvgSelected = item.isSelected ? "" : "un";

    return (
      <div
        className={cs("event-item", { selected: item.isSelected })}
        key={i}
        onClick={this.onClick.bind(this, item)}
      >
        <div className="event-item__selection">
          <div className="selection-svg">
            <SVG
              className="svg"
              src={
                "/public/images/icons/radio-" + isSvgSelected + "selection.svg"
              }
            />
          </div>
        </div>
        <div
          className="event-item__image"
          style={{ backgroundImage: `url(${item.photo})` }}
        >
          <div className="event-item__logo">
            <SVG src={"/public/images/icons/firefly-logo.svg"} />
          </div>
          <div className="event-item__category">
            {toTitleCase(item.category)} - {toTitleCase(item.type)}
          </div>
        </div>
        <div className="event-item__info">
          <div className="event-item__date">
            {moment(item.event_date.start)
              .format("ddd, DD MMM YYYY")
              .toUpperCase()}
            &nbsp;
            {!isSameDate && (
              <>
                -{" "}
                {moment(item.event_date.end)
                  .format("ddd, DD MMM YYYY")
                  .toUpperCase()}
              </>
            )}
            {/*&nbsp;&nbsp;{item.hour.start} - {item.hour.end}*/}
          </div>
          <div className="event-item__name">{item.name}</div>
          <div className="event-item__address">
            <div className="fly-svg-icon-16-div">
              <SVG
                src={"/public/images/icons/location-point.svg"}
                className="fly-svg-icon-16"
              />
            </div>
            <span>{item.location}</span>
          </div>
          <div className="event-item__address">
            <div className="fly-svg-icon-16-div">
              <SVG
                src={"/public/images/icons/location-point.svg"}
                className="fly-svg-icon-16"
              />
            </div>
            <span>{item.address}</span>
          </div>
          <div className="event-item__flight-info has-top-margin">
            <b>Flight:</b>
            <span>
              {moment(item.date.start).format("ddd, DD MMM YYYY").toUpperCase()}
              &nbsp;
              {!isSameDate && (
                <>
                  -{" "}
                  {moment(item.date.end)
                    .format("ddd, DD MMM YYYY")
                    .toUpperCase()}
                </>
              )}
            </span>
            {/*&nbsp;&nbsp;{item.hour.start} - {item.hour.end}*/}
          </div>
          <div className="event-item__flight-info">
            <b>Multiple:</b>
            <span>{item.multiplier}</span>
          </div>
          <div className="event-item__flight-info">
            <b>Targeting:</b>
            <span>{item.targeting}</span>
          </div>
        </div>
        <div
          className="event-item__select"
          title="Show on map"
          onClick={this.onSelect.bind(this, item)}
        >
          <SVG
            src={"/public/images/icons/map2.svg"}
            className="fly-svg-icon fly-svg-icon--small"
          />
        </div>
      </div>
    );
  }

  @autobind
  private handleChangeDMA(val) {
    this.setState({ selectedDMA: val });
  }

  @autobind
  private handleChangeDatePicker(val) {
    val = val.value !== null ? val : null;

    this.setState({ selectedDate: val });
  }

  @autobind
  private handleChangeCategory(val) {
    val = val.value !== null ? val : this.state.categoryOptions[0];

    this.setState({ selectedCategory: val });
  }

  @autobind
  private handleChangeType(val) {
    val = val.value !== null ? val : this.state.typeOptions[0];

    this.setState({ selectedType: val });
  }

  @autobind
  private handleChangeImpression(val) {
    val = val.value !== null ? val : this.state.impressionOptions[0];

    this.setState({ selectedImpression: val });
  }

  @autobind
  private filterDueDate(item) {
    const { selectedDate } = this.state;

    if (selectedDate) {
      return (
        moment(item.date.start).isSameOrAfter(selectedDate.value.start) &&
        moment(item.date.start).isSameOrBefore(selectedDate.value.end)
      );
    }

    return true;
  }

  @autobind
  private filterDueCategory(item) {
    const { selectedCategory } = this.state;
    return selectedCategory
      ? selectedCategory.value
        ? item.category === selectedCategory.value
        : true
      : true;
  }

  @autobind
  private filterDueType(item) {
    const { selectedType } = this.state;
    return selectedType
      ? selectedType.value
        ? item.type === selectedType.value
        : true
      : true;
  }

  @autobind
  private orderDueImpression(a, b) {
    const {
      selectedImpression: { value },
    } = this.state;
    const field = "estimatedImpression";
    const imp1 = a[field];
    const imp2 = b[field];
    return value === "asc" ? imp1 - imp2 : imp2 - imp1;
  }

  @autobind
  private onClick(item) {
    if (this.state.clickedItem) {
      this.state.clickedItem.isSelected = false;
    }
    item.isSelected = true;
    this.setState({ clickedItem: item });
  }

  @autobind
  private onApply() {
    this.onSelect(this.state.clickedItem);
    this.onClick(this.state.clickedItem);
  }

  @autobind
  private onSelect(item) {
    if (this.state.clickedItem) {
      this.state.clickedItem.isSelected = true;
    }
    this.setState({ selectedItem: item, isOpen: false });
    const tmplItem = (
      <div className="point-info-for-event">
        {this.getEventItem(item, Math.random())}
      </div>
    );
    this.state.events.emit("events-on-select-item", { item, tmplItem });
  }

  @autobind
  private togglePopup() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  @autobind
  private onShowPopup() {
    this.setState({ isOpen: true });
  }

  @autobind
  private onHidePopup() {
    this.setState({ isOpen: false });
  }

  @autobind
  private onReset() {
    if (this.state.clickedItem) {
      this.state.clickedItem.isSelected = false;
    }
    this.onHidePopup();
    this.setState({ selectedItem: null, clickedItem: null });
    this.state.events.emit("events-on-select-item", {});
  }

  private removePastEvents(items) {
    return items.filter((item) =>
      moment(item.date.start).isSameOrAfter(moment().format("YYYY-MM-DD"))
    );
  }

  private orderItems(items) {
    items.sort((a, b) => {
      const dateSA = new Date(a.date.start);
      const dateSB = new Date(b.date.start);

      const dateEA = new Date(a.date.end);
      const dateEB = new Date(b.date.end);

      let comparison = 0;

      if (dateSA > dateSB) {
        comparison = 1;
      } else if (dateSA < dateSB) {
        comparison = -1;
      }

      if (dateEA > dateEB) {
        comparison = 1;
      } else if (dateEA < dateEB) {
        comparison = -1;
      }

      return comparison;
    });
  }

  @autobind
  public onTextChange(e) {
    this.setState({ filter_text: e.target.value });
  }
}
