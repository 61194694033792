import * as React from "react";
import ReactSelect from "react-select";
import autobind from "autobind-decorator";
import * as styled from "styled-components";
import * as cs from "classnames";

const StyledReactSelect = styled.default(ReactSelect)`
`;

export interface ITimeRange {
  from: string;
  to: string;
}

interface ITimeRangeSelectFilterState {
  from: number;
  to: number;
  timeRanges: ITimeRange[];
  isEditOpen: boolean;
}

const timeOptions = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
  "12:00 AM",
].map((val, index) => {
  return { value: index, label: val };
});

export class HourFilter extends React.Component<
  any,
  ITimeRangeSelectFilterState
> {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
      timeRanges: this.props.filters.selected_hours,
      isEditOpen: this.props.filters.selected_hours.length === 0,
    };
    this.props.events.on("planner-add-hour-range", ({ start, end }) =>
      this.addTimeRange(start, end)
    );
    this.props.events.on("planner-on-reset", this.onReset);
  }

  public render() {
    let timeRangeItems = this.state.timeRanges.map((range, index) => {
      let removeTimeRange = this.removeTimeRange.bind(this, index);
      return (
        <div key={`r_${index}`} className="fly-hour-filter__item">
          <b className="fly-hour-filter__item__text">
            {range.from} - {range.to}
          </b>
          <div className="fly-hour-filter__item__delete">
            <i className="icon ozzy-cross-thick" onClick={removeTimeRange} />
          </div>
        </div>
      );
    });

    return (
      <div className="fly-section fly-section--no-padding">
        <div className="fly-section__header">
          <b>Hours</b>
        </div>
        <div className="fly-section__content">
          <div className="fly-hour-filter">
            {timeRangeItems}
            {!this.state.isEditOpen && this.state.timeRanges.length < 2 ? (
              <button
                className="fly-button inline blue"
                onClick={this.openEdit}
              >
                <b>+ Add hours</b>
              </button>
            ) : (
              ""
            )}
            {!this.state.isEditOpen || this.state.timeRanges.length > 1 ? (
              ""
            ) : (
              <div className="fly-hour-filter__edit">
                <div className="fly-hour-filter__edit__box">
                  <div className="fly-combo-box fly-combo-box--medium">
                    <StyledReactSelect
                      className="select"
                      classNamePrefix="select"
                      placeholder="Start hour"
                      value={timeOptions.filter(
                        (o) => o.value === this.state.from
                      )}
                      onChange={this.handleFromChange}
                      options={timeOptions}
                    />
                  </div>
                </div>
                <span className="fly-hour-filter__edit__divider" />
                <div className="fly-hour-filter__edit__box">
                  <div className="fly-combo-box fly-combo-box--medium">
                    <StyledReactSelect
                      className="select"
                      classNamePrefix="select"
                      placeholder="End hour"
                      value={timeOptions.filter(
                        (o) => o.value === this.state.to
                      )}
                      onChange={this.handleToChange}
                      options={timeOptions}
                    />
                  </div>
                </div>
                <div className="fly-hour-filter__edit__action-box">
                  {this.state.timeRanges.length === 0 ? (
                    ""
                  ) : (
                    <button
                      className="fly-button secondary"
                      onClick={this.closeEdit}
                    >
                      <b>Cancel</b>
                    </button>
                  )}
                  <button
                    className={cs("fly-button dark", {
                      disabled:
                        this.state.from === null || this.state.to === null,
                    })}
                    disabled={
                      this.state.from === null || this.state.to === null
                    }
                    onClick={this.addTimeRange.bind(this, null, null)}
                  >
                    <b>Add</b>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  public addTimeRange(from = null, to = null) {
    from =
      from || timeOptions.filter((o) => o.value === this.state.from)[0].label;
    to = to || timeOptions.filter((o) => o.value === this.state.to)[0].label;
    let timeRange: ITimeRange = { from, to };
    let timeRanges = this.state.timeRanges;
    timeRanges.push(timeRange);
    this.setState(
      { timeRanges, from: null, to: null, isEditOpen: false },
      () => {
        this.props.events.emit("planner-on-filter-hour", this.state.timeRanges);
      }
    );
  }

  public removeTimeRange(index) {
    let timeRanges = this.state.timeRanges;
    timeRanges.splice(index, 1);
    this.setState({ timeRanges });
  }

  @autobind
  public handleFromChange(val) {
    let value = val ? val.value : null;
    if (value !== null && value === 24) {
      value = 0;
    }
    if (value !== null && this.state.to !== null && value === this.state.to) {
      if (value === 0) {
        value = 23;
      } else {
        value -= 1;
      }
    }
    this.setState({ from: value });
  }

  @autobind
  public handleToChange(val) {
    let value = val ? val.value : null;
    if (value !== null && value === 24) {
      value = 0;
    }
    if (
      value !== null &&
      this.state.from !== null &&
      value === this.state.from
    ) {
      if (value === 23) {
        value = 0;
      } else {
        value += 1;
      }
    }
    this.setState({ to: value });
  }

  @autobind
  private openEdit() {
    this.setState({ isEditOpen: true });
  }

  @autobind
  private closeEdit() {
    this.setState({ isEditOpen: false, from: null, to: null });
  }

  @autobind
  private onReset() {
    this.setState({ timeRanges: [], isEditOpen: true }, () => {
      this.props.events.emit("planner-on-filter-hour", this.state.timeRanges);
    });
  }
}
