import * as React from "react";
import { Doughnut } from "react-chartjs-2";
import * as numeral from "numeral";
// import { PLANNER_EXTERNAL } from "helpers/RBAC";
import InlineTooltip from "./InlineTooltip";

const options = {
  tooltips: {
    enabled: false,
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
};

const sovSteps = [0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875];
export class ImpressionSummary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    let sold_impression = this.props.filters.plannerResponse.sold_impression;
    let available_impression = this.props.filters.plannerResponse
      .available_impression;
    let available_impression_increased = this.props.filters.plannerResponse
      .available_impression_increased;
    let available_total_hours = this.props.filters.plannerResponse
      .available_total_hour;
    let total_impression = sold_impression + available_impression;
    let total_impression_increased =
      sold_impression + available_impression_increased;

    let formattedAvailableTotalHours = numeral(available_total_hours).format(
      "0,0.0"
    );

    formattedAvailableTotalHours =
      formattedAvailableTotalHours.substr(-1) === "0"
        ? numeral(available_total_hours).format("0,0")
        : formattedAvailableTotalHours;

    let percentage = 0;
    if (this.props.filters.selectedEvents) {
      percentage = parseInt(
        ((available_impression / total_impression) * 100).toString(),
        10
      );
    } else {
      percentage = parseInt(
        (
          (available_impression_increased / total_impression_increased) *
          100
        ).toString(),
        10
      );
    }

    if (available_impression < 0) {
      percentage = 0;
    } else if (sold_impression === 0) {
      percentage = 100;
    }
    let data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(100, 0, 0, 100);
      const gradientRed = ctx.createLinearGradient(100, 0, 0, 100);
      gradientRed.addColorStop(1, "#FFBEAE");
      gradientRed.addColorStop(0, "#FD8A76");
      gradient.addColorStop(0, "#47D6F6");
      gradient.addColorStop(1, "#22ACEA");
      return {
        labels: ["", ""],
        datasets: [
          {
            data: [percentage, 100 - percentage],
            backgroundColor: [gradient, gradientRed],
            hoverBackgroundColor: [gradient, gradientRed],
            borderWidth: [1, 0],
          },
        ],
      };
    };

    return (
      <div className="white-holder has-header">
        <div className="white-holder__header planner_result">
          Impression summary
        </div>
        <div className="white-holder__body">
          <div className="flex-col">
            {/* {this.props.userRole !== PLANNER_EXTERNAL ? ( */}
            <div className="f-col-6">
              <div className="f-row">
                <div className="fly-graph-label two">
                  <div className="fly-graph-label__left">
                    <b className="h-600 pink">{100 - percentage}%</b>
                    <p className="small">Sold</p>
                  </div>
                  <span className="fly-graph-label__divider" />
                  <div className="fly-graph-label__right">
                    <b className="h-600 teal">{percentage}%</b>
                    <p className="small">Available</p>
                  </div>
                </div>
              </div>
              <div className="f-row">
                <Doughnut
                  data={data}
                  width={100}
                  height={100}
                  options={options}
                />
              </div>
            </div>
            {/* ) : (
              ""
            )} */}
            <div className="f-col-6">
              {/* {this.props.userRole !== PLANNER_EXTERNAL ? ( */}
              <>
                {this.props.filters.selectedEvent ? (
                  <>
                    <div className="f-row">
                      <p className="h-200">
                        Total Estimated <b>Increased</b> Impressions
                      </p>
                    </div>
                    <div className="f-row">
                      <b className="h-1000">
                        {this.props.hasSovTooltip ? (
                          <InlineTooltip
                            text={numeral(total_impression_increased)
                              .format("0,0")
                              .toUpperCase()}
                          >
                            <div className="sov-helper">
                              <ul>
                                <span className="sov-helper--header">
                                  SOV Table
                                </span>
                                {sovSteps.map((step, index) => (
                                  <li key={index}>
                                    <div className="sov-helper--row">
                                      <span className="sov-helper__percentage">
                                        {step * 100} %
                                      </span>
                                      <span className="sov-helper__impressions">
                                        {numeral(
                                          total_impression_increased * step
                                        ).format("0,0")}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </InlineTooltip>
                        ) : (
                          numeral(total_impression_increased)
                            .format("0,0")
                            .toUpperCase()
                        )}
                      </b>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="f-row">
                  <p className="h-200">Total Estimated Impressions</p>
                </div>
                <div className="f-row">
                  <b className="h-1000">
                    {this.props.hasSovTooltip ? (
                      <InlineTooltip
                        text={numeral(total_impression)
                          .format("0,0")
                          .toUpperCase()}
                      >
                        <div className="sov-helper">
                          <ul>
                            <span className="sov-helper--header">
                              SOV Table
                            </span>
                            {sovSteps.map((step, index) => (
                              <li key={index}>
                                <div className="sov-helper--row">
                                  <span className="sov-helper__percentage">
                                    {step * 100} %
                                  </span>
                                  <span className="sov-helper__impressions">
                                    {numeral(total_impression * step).format(
                                      "0,0"
                                    )}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </InlineTooltip>
                    ) : (
                      numeral(total_impression).format("0,0").toUpperCase()
                    )}
                  </b>
                </div>
                <div className="f-row">
                  <p className="h-200">Sold Impressions</p>
                </div>
                <div className="f-row">
                  <b className="h-1000 pink">
                    {numeral(sold_impression).format("0,0").toUpperCase()}
                  </b>
                </div>{" "}
              </>
              {/* ) : (
                ""
              )} */}

              {this.props.filters.selectedEvent ? (
                <>
                  <div className="f-row">
                    <p className="h-200">
                      Available <b>Increased</b> Impressions
                    </p>
                  </div>
                  <div className="f-row">
                    <b className="h-1000 teal">
                      {numeral(available_impression_increased)
                        .format("0,0")
                        .toUpperCase()}
                    </b>
                  </div>{" "}
                </>
              ) : (
                ""
              )}

              <div className="f-row">
                <p className="h-200">Available Impressions</p>
              </div>
              <div className="f-row">
                <b className="h-1000 teal">
                  {numeral(available_impression).format("0,0").toUpperCase()}
                </b>
              </div>
              {this.props.withHours ? (
                <>
                  <div className="f-row">
                    <p className="h-200">Available Time</p>
                  </div>
                  <div className="f-row">
                    <b className="h-1000 orange">
                      {formattedAvailableTotalHours} h
                    </b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
