import * as React from "react";
import autobind from "autobind-decorator";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#56e481";
  }
  if (props.isDragReject) {
    return "#F56262";
  }
  if (props.isDragActive) {
    return "#4C84FF";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 12px;
  padding: 15px;
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #2e2e2e;
  outline: none;
  transition: border .24s ease-in-out;
  margin-bottom: 20px;
  cursor: pointer;
  height: 76px;
  border-radius: 6px;
  background-color: #f9fbfe;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  margin-top: 12px;
  opacity: 0.7;
}
`;

function StyledDropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: ".geojson", onDrop: onFileDrop });

  function onFileDrop(files) {
    props.onFileDrop(files);
  }

  return (
    <div className="container file-drop">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p className="title">
          <i className="icon ozzy-upload" /> Upload a GeoJSON file
        </p>
        <p className="desc">*Formats: GeoJSON</p>
      </Container>
    </div>
  );
}

export class UploadGeojson extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {}

  public render() {
    return (
      <div>
        <StyledDropzone onFileDrop={this.onFileDrop} />
      </div>
    );
  }

  @autobind
  public onFileDrop(files) {
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const json = JSON.parse(reader.result.toString());
        this.props.onUpload({
          file,
          geojson: json,
        });
      };

      reader.readAsText(file);
    }
  }
}
