import * as React from "react";
import ReactSelect, { components } from "react-select";
import makeAnimated from "react-select/animated";
import autobind from "autobind-decorator";
import { DMAS } from "helpers/CONSTANTS";

const ignoredCities = ["Las Vegas"];

let dmaOptions: any[] = [];
DMAS.forEach((dma, index) => dmaOptions.push({ value: index + 1, label: dma }));

dmaOptions = dmaOptions.filter((city) => !ignoredCities.includes(city.label));

const Placeholder = (props) => {
  return (
    <div>
      <i className="icon ozzy-city" />
      <components.Placeholder {...props} />
    </div>
  );
};

export class CityFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selected_dmas: [],
      selected_values: dmaOptions.filter(
        (o) =>
          this.props.filters.selected_dmas.filter((d) => d === o.label).length >
          0
      ),
    };
    this.props.events.on("planner-on-reset", this.onReset);
    this.props.events.on("mapfences-shapes-updated", this.mapfencesUpdated);
    this.props.events.on("events-on-select-item", this.onSelectEvent);
    this.props.events.on("geofence-on-filter-zipcode", this.onZipcodeFilter);
  }

  public render() {
    return (
      <div className="fly-section fly-section--no-padding">
        <div className="fly-section__header">
          <b>Cities</b>
        </div>
        <div className="fly-section__content">
          <div className="fly-combo-box width-flex planner-city-filter">
            <ReactSelect
              className="select"
              classNamePrefix="select"
              isMulti
              value={this.state.selected_values}
              options={dmaOptions}
              closeMenuOnSelect={false}
              components={makeAnimated({ Placeholder })}
              onChange={this.handleChange}
              placeholder="Select city.."
            />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private handleChange(values) {
    let selected_dmas = [];
    if (values) {
      selected_dmas = values.map((v) => v.label);
    }
    this.setState({ selected_dmas, selected_values: values });
    this.props.events.emit("planner-on-filter-city", selected_dmas);
  }

  @autobind
  private onReset() {
    this.setState({ selected_values: [dmaOptions[0]] });
    this.props.events.emit("planner-on-filter-city", [dmaOptions[0].label]);
  }

  @autobind
  private onSelectEvent({ item }) {
    if (item) {
      let values = [item.dma];

      let selected_values = dmaOptions.filter(
        (o) => values.filter((d) => d === o.label).length > 0
      );

      this.setState({ selected_values });
      this.handleChange(selected_values);
    }
  }

  @autobind
  private onZipcodeFilter(selectedZipcodes) {
    if (!selectedZipcodes.length) {
      return;
    }

    let citiesOfZipcodes: any = [];

    selectedZipcodes.forEach((zipcode) => {
      let city = zipcode.geojson.features[0].properties.city;

      if (city === "New York City") {
        city = "New York";
      }

      citiesOfZipcodes.push(city);
    });

    //@ts-ignore
    citiesOfZipcodes = [...new Set(citiesOfZipcodes)].map((city) =>
      dmaOptions.find((dma) => dma.label === city)
    );

    let mergedCities = [
      //@ts-ignore
      ...new Set([...this.state.selected_values, ...citiesOfZipcodes]),
    ];

    mergedCities = mergedCities.filter((city) => city);

    this.setState({
      selected_values: mergedCities,
    });
    this.handleChange(mergedCities);
  }

  @autobind
  private mapfencesUpdated(shapes) {
    if (shapes.length > 0) {
      let values: any = [];
      for (let shape of shapes) {
        if (values.indexOf(shape.city) === -1) {
          values.push(shape.city);
        }
      }
      let selected_values = dmaOptions.filter(
        (o) => values.filter((d) => d === o.label).length > 0
      );
      this.setState({ selected_values });
      this.handleChange(selected_values);
    }
  }
}
