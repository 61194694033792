import * as React from "react";
import { Filter } from "pages/Planner/Filter";
import { Result } from "pages/Planner/Result";
import { AjaxHelper, Emitter } from "helpers";
import autobind from "autobind-decorator";
import { Constants } from "pages/Planner/Map/draw/Constants";
import { InteractiveMapboxWrapper } from "pages/Planner/Map/InteractiveMapboxWrapper";
import { ZipcodeResult } from "pages/Planner/Result/ZipcodeResult";
import * as _ from "underscore";
import { CityNavigation } from "pages/Planner/Map/CityNavigation";
import { check_coordinates, getLongLat } from "helpers/CONSTANTS";
import moment from "moment";
import { ZipcodeSelectResultLayer } from "pages/Planner/Map/ZipcodeSelectResultLayer";
import { BuildingsLayer } from "pages/Planner/Map/BuildingsLayer";
import { PointToCircleLayer } from "pages/Planner/Map/PointToCircleLayer";
import { Controllers } from "components/map/controllers/controllers";
import EventList from "components/EventList/EventList";
import GeofenceTypeSelect from "components/Geofence/geofencetypeselect";
import { RightControls } from "pages/Planner/RightControls/rightcontrols";

class Planner0 extends React.Component<any, any> {
  private events: Emitter;
  //@ts-ignore
  private interactiveMapboxWrapperRef: InteractiveMapboxWrapper = null;

  constructor(props) {
    super(props);
    this.state = {
      isFilterPage: true,
      inDrawMode: false,
      isFiltering: false,
      InteractiveMapBoxref: null,
      selected_dmas: ["San Francisco"],
      selected_dates: [],
      selected_hours: [],
      plannerResponse: "",
      selectedEvent: null,
      geofence: Planner0.getInitialStateOfGeofence(),
      // userRole: this.props.usertoken.role,
      pointOffset: { x: 660, y: 40 },
    };

    this.events = new Emitter();
    this.events.on("planner-on-filter", this.onFilter);
    this.events.on("planner-on-back", this.onBack);
    this.events.on(Constants.events.START_DRAW, this.onDrawStart);
    this.events.on(Constants.events.STOP_DRAW, this.onDrawEnd);
    this.events.on("planner-on-filter-city", this.onCityFilter);
    this.events.on("planner-on-filter-calendar", this.onCalendarFilter);
    this.events.on("planner-on-filter-hour", this.onHourFilter);
    this.events.on("geofence-on-receive-data", this.onReceiveDataGeofence);
    this.events.on("geofence-on-reset", this.onResetGeofence);
    this.events.on("map-on-goto-city", this.gotoCity);
    this.events.on("events-on-select-item", this.onSelectedEvent);
    this.events.on(
      "map.go.to.coordinates.clear.event",
      this.onSearchGoToCoordinatesClear
    );

    this.updateZipcodesThrottled = _.debounce(
      this.updateZipcodesThrottled.bind(this),
      400
    );
  }

  public render() {
    let initialLongLat = getLongLat("San Francisco");

    return (
      <div className="fly-planner">
        {this.state.isFiltering ? (
          <div className="map-loading">
            <div className="map-loading__animation">
              <div className="fly-folding-cube">
                <div className="fly-cube1 fly-cube" />
                <div className="fly-cube2 fly-cube" />
                <div className="fly-cube4 fly-cube" />
                <div className="fly-cube3 fly-cube" />
              </div>
            </div>
            <div className="map-loading__text">
              <b>Filtering!</b>
            </div>
          </div>
        ) : (
          ""
        )}
        <EventList
          events={this.events}
          isVisible={
            this.state.isFilterPage //&& this.state.userRole !== PLANNER_EXTERNAL
          }
        />
        {this.state.isFilterPage ? (
          <Filter events={this.events} filters={this.state} />
        ) : (
          <Result
            events={this.events}
            filters={this.state}
            mapBoxRef={this.interactiveMapboxWrapperRef}
            // usertoken={this.props.usertoken}
          />
        )}
        <div className="fly-planner__map">
          <InteractiveMapboxWrapper
            ref={this.bindInteractiveMapboxWrapperRef}
            drawControlEnabled={true}
            events={this.events}
            onClick={this.onMapClick}
            onLoad={this.onLoad}
            initialLat={initialLongLat[1]}
            initialLon={initialLongLat[0]}
            minZoom={0}
            maxZoom={22}
            onBoundsChange={this.onBoundsChanged}
          />
          <Controllers
            events={this.events}
            usertoken={this.props.usertoken}
            pointOffset={this.state.pointOffset}
          />
          <CityNavigation events={this.events} />
        </div>
        <BuildingsLayer map={this.state.InteractiveMapBoxref} />
        <ZipcodeResult
          events={this.events}
          filters={this.state}
          isFilterPage={this.state.isFilterPage}
          usertoken={this.props.usertoken}
        />
        <ZipcodeSelectResultLayer
          events={this.events}
          map={this.state.InteractiveMapBoxref}
        />
        <PointToCircleLayer
          events={this.events}
          map={this.state.InteractiveMapBoxref}
        />
        <GeofenceTypeSelect
          events={this.events}
          menuItems={[
            {
              title: <p>Calculate Area</p>,
              onClick: this.onClickCalculateShapeArea,
            },
          ]}
        />
        <RightControls events={this.events} usertoken={this.props.usertoken} />
      </div>
    );
  }

  private static getInitialStateOfGeofence() {
    return {
      activeTab: "drawing",
      drawing: {
        shapes: [],
        geojsonFiles: [],
      },
      zipcodes: {
        selected_zipcodes: [],
        selected_zipcodes_geojson: [],
      },
    };
  }

  @autobind
  public gotoCity({ dma }) {
    let longlat = getLongLat(dma);
    this.gotoLocation(longlat[0], longlat[1]);
  }

  @autobind
  public gotoLocation(long, lat) {
    this.interactiveMapboxWrapperRef._goToLocation(long, lat, 9);
  }

  @autobind
  public onClickCalculateShapeArea(features) {
    this.state.geofence.activeTab = "drawing";
    this.events.emit(Constants.events.STOP_DRAW_OUTSIDE);
    this.events.emit("geofence-on-receive-data", this.state.geofence);
    this.events.emit("planner-on-filter", { selectedFeature: features[0] });
  }

  @autobind
  public onCityFilter(selected_dmas) {
    if (selected_dmas.length === 1) {
      if (
        !(
          this.state.selected_dmas.length === 1 &&
          this.state.selected_dmas[0] === selected_dmas[0]
        )
      ) {
        let lonlat = getLongLat(selected_dmas[0]);
        this.gotoLocation(lonlat[0], lonlat[1]);
      }
    }
    this.setState({ selected_dmas });
  }

  @autobind
  public onCalendarFilter(selected_dates) {
    this.setState({ selected_dates });
  }

  @autobind
  public onHourFilter(selected_hours) {
    this.setState({ selected_hours });
  }

  @autobind
  public onReceiveDataGeofence(geofenceData = {}) {
    this.setState({ geofence: { ...geofenceData } });
  }

  @autobind
  private onResetGeofence() {
    this.onReceiveDataGeofence(Planner0.getInitialStateOfGeofence());
  }

  @autobind
  public onLoad() {
    this.setState({
      InteractiveMapBoxref: this.interactiveMapboxWrapperRef,
    });

    this.interactiveMapboxWrapperRef.getMap().resize();
  }

  @autobind
  public onMapClick() {
    if (this.state.selectedFeature) {
      this.setState({ selectedFeature: null });
    }
  }

  @autobind
  public onBoundsChanged() {
    this.updateZipcodesThrottled();
  }

  @autobind
  public updateZipcodesThrottled() {
    this.events.emit("map-features-select-from-map-bounds-changed");
    this.events.emit(
      "map-zoom-changed",
      this.interactiveMapboxWrapperRef.getMap().getZoom()
    );
  }

  @autobind
  private bindInteractiveMapboxWrapperRef(ref) {
    this.interactiveMapboxWrapperRef = ref;
  }

  @autobind
  public onBack() {
    this.setState({
      isFilterPage: true,
    });
  }

  @autobind
  public onFilter({ selectedFeature }) {
    const geojson = this.interactiveMapboxWrapperRef
      .getMapBoxDrawControl()
      .draw.getAll();

    const geofence = this.state.geofence;

    if (geofence.drawing.shapes.length) {
      geofence.drawing.shapes.forEach((shape) => {
        geojson.features.forEach((feature) => {
          if (feature.id === shape.ids[0]) {
            shape.geojson = { features: [feature] };
          }
        });
      });

      this.setState({ geofence });
    }

    this.setState({ isFiltering: true });

    let shapes = this.interactiveMapboxWrapperRef
      .getMapBoxDrawControl()
      .getGeometries();

    let events: any[] = [];
    const selectedEvent = this.state.selectedEvent;
    if (selectedEvent) {
      try {
        events.push({
          start: moment(selectedEvent.date.start).format("YYYY-MM-DD"),
          end: moment(selectedEvent.date.end).format("YYYY-MM-DD"),
          multiplier: selectedEvent.multiplier,
        });
      } catch (e) {
        console.log(e);
      }
    }

    let url = "/api/planner/query";

    let requestObj = {
      filter: {
        dmas: this.state.selected_dmas,
        dates: this.mapRanges(this.state.selected_dates),
        hours: this.state.selected_hours,
        zipcodes: this.state.geofence.zipcodes.selected_zipcodes,
        event: events,
        shapes,
      },
    };

    const { activeTab } = this.state.geofence;

    if (activeTab === "drawing") {
      if (selectedFeature) {
        requestObj.filter.shapes = this.interactiveMapboxWrapperRef
          .getMapBoxDrawControl()
          .getGeometries([selectedFeature.properties.id]);
        this.events.emit(Constants.events.DELETE_ALL, [
          selectedFeature.properties.id,
        ]);
      }
    } else if (activeTab === "zipcodes") {
      this.state.geofence.zipcodes.selected_zipcodes_geojson.forEach(
        (zipcode) => {
          this.events.emit(Constants.events.CREATE, zipcode.geojson);
        }
      );
    }

    this.setState({ geofence });

    this.events.emit("workspace-hide-popup");

    AjaxHelper.post(url, JSON.parse(JSON.stringify(requestObj))).then((res) => {
      this.setState(
        {
          plannerResponse: res,
          isFilterPage: false,
          isFiltering: false,
        },
        () => this.events.emit("planner-on-filter-result", res)
      );
    });
  }

  @autobind
  public onDrawStart() {
    this.setState({ inDrawMode: true });
  }

  @autobind
  public onDrawEnd() {
    this.setState({ inDrawMode: false });
  }

  @autobind
  private mapRanges(selectedRanges) {
    let mappedRanges = selectedRanges.map((r) => ({
      start: moment(r.start).format("YYYY-MM-DD"),
      end: moment(r.end).format("YYYY-MM-DD"),
    }));
    return mappedRanges;
  }

  @autobind
  public onSelectedEvent({ item, tmplItem }) {
    if (!item) {
      this.setState({
        selectedEvent: null,
      });
      return;
    }

    this.events.emit("planner-on-reset");

    setTimeout(() => {
      this.events.emit("planner-add-date-range", {
        start: new Date(item.date.start),
        end: new Date(item.date.end),
      });

      // this.events.emit('planner-add-hour-range', {
      //   start: item.hour.start,
      //   end: item.hour.end
      // });

      let coordinates = check_coordinates(item.address);

      if (coordinates.length > 0) {
        this.events.emit("map.go.to.coordinates", {
          long: coordinates[1],
          lat: coordinates[0],
          openContextMenu: {
            offsetX: this.state.pointOffset.x,
            offsetY: this.state.pointOffset.y,
          },
        });
      } else {
        let url =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          item.address +
          " " +
          item.dma;
        url +=
          ".json?access_token=pk.eyJ1IjoibWF0dGZpY2tlIiwiYSI6ImNqNnM2YmFoNzAwcTMzM214NTB1NHdwbnoifQ.Or19S7KmYPHW8YjRz82v6g&cachebuster=1567441428464&autocomplete=true&country=US";
        fetch(url, {})
          .then((res) => res.json())
          .then((res) => {
            if (res.features.length > 0) {
              this.events.emit("map.go.to.coordinates", {
                long: res.features[0].center[0],
                lat: res.features[0].center[1],
                openContextMenu: {
                  offsetX: this.state.pointOffset.x,
                  offsetY: this.state.pointOffset.y,
                },
              });
            }
          });
      }

      this.events.emit("geofence-point-on-set-info", [tmplItem, item]);

      item.geofence_mile = 0.0;
      this.setState({
        selectedEvent: item,
      });
    });
  }

  @autobind
  private onSearchGoToCoordinatesClear() {
    this.setState({ selectedEvent: null });
  }
}

export const Planner = Planner0;
