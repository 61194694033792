import { GlobalStore } from "helpers/GlobalStore";
import { setPanels } from "redux/modules/ui";

let browserHistory: any = null;

class AppHistory {
  public static replace(options: any) {
    browserHistory.replace(options);
  }

  public static replaceAsync(options: any, timeout = 0, fn: any = null) {
    setTimeout(() => {
      AppHistory.replace(options);
      if (fn) {
        fn();
      }
    }, timeout);
  }

  public static push(path) {
    document.getElementById("app")?.classList.remove("has-filter");
    GlobalStore.dispatch(
      setPanels({
        headerComponent: null as any,
        filterComponent: null as any,
        cardComponent: null as any,
      })
    );
    browserHistory.push(path);
  }

  public static pushAsync(path, timeout = 0) {
    setTimeout(() => {
      AppHistory.push(path);
    }, timeout);
  }

  public static changeParameters(search, fn = null) {
    const currentPath = browserHistory.location.pathname || "";
    AppHistory.replaceAsync({ pathname: currentPath, search }, 0, fn);
  }

  public static __setBrowserHistory(b) {
    browserHistory = b;
  }

  public static __getBrowserHistory() {
    return browserHistory;
  }
}

export { AppHistory };
