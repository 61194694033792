import * as React from "react";
import * as Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class AbstractMessageDialog extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this._onCloseClicked = this._onCloseClicked.bind(this);
  }

  public _onCloseClicked() {
    if (this.props.onClose) {
      this.props.onClose();
    }

    this.onClose();
  }

  public onClose() {}

  public getTitle() {
    return this.props.item && this.props.item.title
      ? this.props.item.title
      : this.props.title;
  }

  public render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        style={customStyles}
        contentLabel="Warning"
      >
        <div className="modal">
          <div className="modal__header">
            <i className="modal-icon icon ozzy-warning" />
            <h2>{this.getTitle()}</h2>
          </div>
        </div>

        <div className="modal__content">{this.renderBody()}</div>

        <div className="modal__action-box">{this.renderButtons()}</div>
      </Modal>
    );
  }

  public renderBody() {
    return <div>{this.renderContent()}</div>;
  }

  public renderContent() {
    if (this.props.item) {
      if (typeof this.props.item.content === "function") {
        return this.props.item.content();
      } else {
        return this.props.item.content;
      }
    }
    return null;
  }

  public renderButtons() {
    return null;
  }
}

export { AbstractMessageDialog };
