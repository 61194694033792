import * as React from "react";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import { AjaxHelper, AppHelper, GlobalStore } from "helpers";
import { toggleModal } from "redux/modal";
import WorkspaceItem from "./WorkspaceItem";
import ShareDialog from "./ShareDialog";
import { DMAS } from "helpers/CONSTANTS";
import ReactSelect from "react-select";
import { AuthContext } from "@fireflyon/auth";

const dmaOptions = [{ value: "all", label: "All Cities" }];
DMAS.forEach((dma) => dmaOptions.push({ value: dma, label: dma }));

export default class Workspace extends React.Component<any, any> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props) {
    super(props);

    this.state = {
      loadingItems: true,
      selectedItem: null,
      items: [],
      sharedItems: [],
      isOpen: false,
      events: this.props.events,
      isShareDialogOpen: false,
      activeTab: "items",
      selected_dma: "all",

      modalDelete: {
        isOpen: false,
        loadingConfirmBtn: false,
        isConfirm: true,
        onConfirm: this.deleteSelectedItem,
      },
    };

    this.state.events.on("workspace-show-popup", this.onShowPopup);
    this.state.events.on("workspace-hide-popup", this.onHidePopup);
    this.state.events.on("workspace-on-add-request", this.onAddRequest);
    this.state.events.on("workspace-on-delete-request", this.onDeleteRequest);
  }

  public componentDidMount() {
    this.getItems();
  }

  private onClickCloseShareButton = () => {
    this.setState({ isShareDialogOpen: false });
  };

  private onClickShareBtn = () => {
    this.setState({ isShareDialogOpen: true });
  };

  public handleComboChange = (val) => {
    let value = val ? val.value : "all";

    let selectedItem = this.state.selectedItem;

    if (value !== "all" && selectedItem) {
      const filteredItems = this.state[this.state.activeTab].filter(
        (x) => x.dma === value && x.id === selectedItem.id
      );

      if (filteredItems.length === 0) {
        selectedItem = null;
      }
    }
    this.setState({ selected_dma: value, selectedItem });
  };

  public renderItems() {
    const key = this.state.activeTab;
    const items = this.state[key];
    const selectedDma = this.state.selected_dma;
    const filteredItems = items.filter((item) =>
      selectedDma === "all" ? true : item.dma === selectedDma
    );

    return (
      <div className="items">
        {!this.state.loadingItems && !items.length
          ? "Your workspace is empty"
          : ""}
        {filteredItems.map((item, i) => {
          return (
            <WorkspaceItem
              key={i}
              isSelected={
                this.state.selectedItem &&
                this.state.selectedItem.id === item.id
              }
              isEditingName={key === "items" ? item.editName : false}
              item={item}
              onBlur={(item) => this.onBlurName(item, key === "sharedItems")}
              onChangeName={this.onChangeName}
              onDelete={(item) =>
                this.onClickDeleteItemBtn(item, key === "sharedItems")
              }
              onDoubleClick={this.onDoubleClickName}
              onClick={this.selectItem}
            />
          );
        })}
      </div>
    );
  }

  public render() {
    const { isOpen, selectedItem, loadingItems } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <div className={cs("workspace-container")} title="">
        <div className="workspace-inner">
          {this.state.isShareDialogOpen && (
            <ShareDialog
              onClickClose={this.onClickCloseShareButton}
              onClickShare={(emails) => {
                this.onShareItemRequest(
                  this.state.selectedItem.shape_id ||
                    this.state.selectedItem.id,
                  emails
                );
              }}
            />
          )}
          <div className="title-container">
            <div className="title">Workspace</div>
            <div onClick={this.onHidePopup}>
              <SVG
                src={"/public/images/icons/hide-button.svg"}
                className="fly-svg-icon control-icon hide-button"
              />
            </div>
          </div>

          <div className="workspace-filter fly-combo-box fly-combo-box--large fly-combo-box--has-icon">
            <SVG src={"/public/images/icons/city.svg"} className="svg" />
            <ReactSelect
              className="select"
              classNamePrefix="select"
              defaultValue={dmaOptions[0]}
              onChange={this.handleComboChange}
              options={dmaOptions}
            />
          </div>

          <div className="tab-controls-container">
            <button
              className={`tab-control ${
                this.state.activeTab === "items" ? "active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab === "items") return;
                this.setState({
                  activeTab: "items",
                  selectedItem: undefined,
                });
              }}
            >
              My Favourite Areas
            </button>
            <button
              className={`tab-control ${
                this.state.activeTab === "sharedItems" ? "active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab === "sharedItems") return;
                this.setState({
                  activeTab: "sharedItems",
                  selectedItem: undefined,
                });
              }}
            >
              Shared Areas
            </button>
          </div>
          {loadingItems ? "Loading..." : ""}
          {this.renderItems()}
          <div className="workspace-footer" title="">
            <a
              href="javascript:void(0)"
              className={cs("export-btn", {
                disabled: !selectedItem,
              })}
              onClick={(e) => this.onClickExportBtn(e)}
            >
              <SVG
                src={"/public/images/icons/export.svg"}
                className="fly-svg-icon fly-svg-icon--small"
              />
            </a>
            <a
              href="javascript:void(0)"
              className={cs("sh_are-btn", {
                disabled: !selectedItem,
              })}
              onClick={this.onClickShareBtn}
            >
              <SVG
                src={"/public/images/icons/sh_are.svg"}
                className="fly-svg-icon fly-svg-icon--small"
              />
            </a>
            <a
              href="javascript:void(0)"
              className={cs("show-map-btn", {
                disabled: !selectedItem,
              })}
              onClick={this.onSelect}
            >
              <SVG
                src={"/public/images/icons/map.svg"}
                className="fly-svg-icon fly-svg-icon--small"
              />
              <span className="title">SHOW ON MAP</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  private getItems = () => {
    this.setState({ loadingItems: true });

    const request = {
      user_email: this.context.user.email,
    };

    AjaxHelper.post("/api/geolocation/get-user-map-shapes", request).then(
      (res) => {
        this.setState({
          items: res.shapes,
          sharedItems: res.shared_shapes,
          loadingItems: false,
        });
      }
    );
  };

  private onShareItemRequest = async (id, emails) => {
    const body = {
      shape_id: id,
      emails,
    };
    await AjaxHelper.post("/api/geolocation/share-map-shape", body);

    this.setState({ isShareDialogOpen: false });

    AppHelper.showMessage(
      "The geofence has been succesfully shared with the recipient(s)",
      "success"
    );
  };

  private onAddRequest = (item) => {
    const request = {
      name: item.name,
      dma: item.dma || "N/A",
      user_email: this.context.user.email,
      type: item.type,
      shape: item.data,
    };

    AjaxHelper.post("/api/geolocation/add-map-shape", request).then((res) => {
      item.id = res;
      this.onAddSuccess(item);
      AppHelper.showMessage("Geofenced area is added to workspace!", "success");
    });
  };

  private onAddSuccess = (item) => {
    this.getItems();

    this.setState({ isOpen: true });

    setTimeout(() => {
      this.state.events.emit("workspace-on-add-success", item);
    }, 0);
  };

  private onDeleteRequest = (
    workspaceId,
    forceDelete = false,
    isSharedItem = false
  ) => {
    if (!forceDelete) {
      // tslint:disable-next-line:triple-equals
      const item = this.state.items.find((item) => item.id == workspaceId);

      this.setState({
        selectedItem: item,
      });

      this.onClickDeleteItemBtn(item);

      return;
    }

    this.toggleDeleteModal({
      loadingConfirmBtn: true,
    });

    AjaxHelper.post(
      `/api/geolocation/delete-undo-${isSharedItem ? "shared-" : ""}map-shape`,
      {
        id: workspaceId,
        is_deleted: true,
      }
    ).then(() => {
      let items = this.state[isSharedItem ? "sharedItems" : "items"];

      const index = items.findIndex((item) => {
        return item.id === workspaceId;
      });

      const type = items[index].type;
      items.splice(index, 1);

      if (!isSharedItem) {
        this.state.events.emit("workspace-on-delete-success", {
          id: workspaceId,
          type,
        });
      }

      AppHelper.showMessage("Geofence area is deleted!", "error");

      this.toggleDeleteModal({
        loadingConfirmBtn: false,
      });

      const newState = {};

      newState[isSharedItem ? "sharedItems" : "items"] = items;

      this.setState({ ...newState, selectedItem: null });
    });
  };

  private onUpdateName = (item, newName, isSharedItem) => {
    const request = {
      id: isSharedItem ? item.shape_id : item.id,
      name: newName,
    };

    AjaxHelper.post(`/api/geolocation/rename-map-shape`, request).then(() => {
      delete item.newName;
      item.editName = false;
      item.name = newName;
      this.setState({ items: this.state.items });
      AppHelper.showMessage(
        "The name of the geofenced area has been updated.",
        "success"
      );
    });
  };

  private onDoubleClickName = (item) => {
    item.editName = true;
    this.setState({ selectedItem: item });
  };

  private onChangeName = (e, item) => {
    item.newName = e.currentTarget.value;
    this.setState({ items: this.state.items });
  };

  private onBlurName = (item, isSharedItem = false) => {
    if (item.newName && item.newName !== item.name) {
      this.onUpdateName(item, item.newName, isSharedItem);
    } else {
      item.editName = false;
      delete item.newName;
      this.setState({
        items: this.state.items,
        sharedItems: this.state.sharedItems,
      });
    }
  };

  private getMapShapeJson(id) {
    return AjaxHelper.post("/api/geolocation/get-map-shape-json", { id });
  }

  private onSelect = () => {
    if (!this.state.selectedItem) {
      return;
    }

    const item = this.state.selectedItem;

    this.getMapShapeJson(item.shape_id || item.id).then((res) => {
      item.data = res;
      item.data.workspaceId = item.id;

      if (item.type === "geojsonFile") {
        item.data.file.name = item.name;
      }

      this.state.events.emit("workspace-on-selected", item);
      this.onHidePopup();
    });
  };

  private onClickDeleteItemBtn = (item, isSharedItem = false) => {
    const options = {
      isOpen: true,
      content: `Are you sure you want to delete ${item && item.name}?`,
    } as any;

    if (isSharedItem) options.onConfirm = () => this.deleteSelectedItem(true);
    this.toggleDeleteModal(options);
  };

  private deleteSelectedItem = (isSharedItem = false) => {
    this.onDeleteRequest(this.state.selectedItem.id, true, isSharedItem);
    this.setState({ selectedItem: null });
  };

  private onClickExportBtn = (e) => {
    if (!this.state.selectedItem) {
      return;
    }

    const target = e.currentTarget;

    this.getMapShapeJson(this.state.selectedItem.id).then((res) => {
      res.geojson.type = "FeatureCollection";
      res.geojson.properties = res.geojson.properties || {};
      res.geojson.properties.dma = this.state.selectedItem.dma;
      res.geojson.properties.name = this.state.selectedItem.name;
      res.geojson.properties.date = this.state.selectedItem.create_date;

      // Stringify the GeoJson
      const convertedData =
        "text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(res.geojson));

      // Create export
      target.setAttribute("href", "data:" + convertedData);
      target.setAttribute("download", "my-workspace.geojson");

      this.setState({ selectedItem: null }, () => {
        target.click();
        target.setAttribute("href", "javascript:void(0)");
        target.removeAttribute("download");
      });

      this.onHidePopup();
    });
  };

  private selectItem = (item) => {
    this.setState({ selectedItem: item });
  };

  private onShowPopup = () => {
    this.setState({ isOpen: true });
  };

  private onHidePopup = (e = null) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({ isOpen: false, selectedItem: null });
  };

  private toggleDeleteModal = (options = {}) => {
    GlobalStore.dispatch(
      toggleModal({
        ...this.state.modalDelete,
        ...options,
      })
    );
  };
}
