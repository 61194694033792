import {
  showError,
  showSuccess,
  showConfirm,
  showWarning,
} from "../redux/dialogbox";
import { GlobalStore } from "./GlobalStore";

export interface IDialogOptions {
  title?: string;
  content: string;
  onOk?: () => void;
}

export interface IConfirmDialogOptions {
  title: string;
  content: string;
  onYes: () => void;
  onNo: () => void;
}

export class DialogHelper {
  public static showError(options: IDialogOptions) {
    GlobalStore.dispatch(showError(options));
  }

  public static showSuccess(options: IDialogOptions) {
    GlobalStore.dispatch(showSuccess(options));
  }

  public static showConfirm(options: IConfirmDialogOptions) {
    GlobalStore.dispatch(showConfirm(options));
  }

  public static showWarning(options: IDialogOptions) {
    GlobalStore.dispatch(showWarning(options));
  }
}
