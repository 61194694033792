import React from "react";

const suiteLink = window.REACT_APP_DOMAIN;

export const navigationItems = [
  {
    title: "Plan",
    children: [
      {
        title: "Opportunities",
        path: suiteLink + "/plan/opportunities",
        permissions: ["access-plan"],
      },
      {
        title: "Custom Plan",
        path: "/plan/custom",
        permissions: ["access-plan"],
      },
    ],
  },
  {
    title: "Manage",
    children: [
      {
        title: "Campaigns",
        path: suiteLink + "/manage/campaigns",
        permissions: ["access-campaigns"],
      },
      {
        title: "Takeovers",
        path: suiteLink + "/manage/takeovers",
        permissions: ["access-takeovers"],
      },
      {
        title: "Content Moderation",
        path: suiteLink + "/manage/content-moderation",
        permissions: ["manage_creative_moderation"],
      },
    ],
  },
  {
    title: "Report",
    children: [
      {
        title: "Campaigns",
        path: suiteLink + "/report/campaigns",
        permissions: ["access-report"],
      },
      {
        title: "Self-Service Dashboards",
        path: suiteLink + "/report/dashboards",
        permissions: ["can-see-self-service-dashboard"],
      },
    ],
  },
  {
    title: "Others",
    children: [
      {
        title: "Inventory Outlook",
        path: suiteLink + "/inventory-outlook",
        permissions: ["access-inventory-outlook"],
      },
      {
        title: "Location Intelligence",
        path: suiteLink + "/location-intelligence",
        permissions: ["access-location-intelligence"],
      },
      {
        title: "Simulation Tool",
        path: "https://simulationtool.fireflyon.com",
        permissions: ["access-simulation"],
      },
      {
        title: "Recent Coverage",
        path:
          "https://driverops.fireflyon.com/monitoring-and-diagnostics/recent-coverage",
        permissions: ["access-recent-coverage"],
      },
      {
        title: "Settings (old)",
        path: "/old-settings",
        permissions: ["access-settings"],
      },
    ],
  },
];
