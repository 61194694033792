import * as React from "react";
import * as cs from "classnames";
import autobind from "autobind-decorator";
import RodalCustom from "pages/Settings/ClientSettings/RodalCustom";
import { connect } from "react-redux";

class RodalModal0 extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.modal,
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.modal !== this.props.modal) {
      this.setState({ ...this.props.modal });
    }
  }

  public render() {
    const {
      isOpen,
      title,
      content,
      cancelText,
      confirmText,
      loadingConfirmBtn,
      confirmBtnClass,
      isConfirm,
    } = this.state;

    return (
      <RodalCustom
        visible={isOpen}
        onClose={this.onClose}
        animation={"slideDown"}
        showCloseButton={false}
      >
        {title && (
          <div className="fly-modal__header">
            <b>{title}</b>
          </div>
        )}
        <div className="fly-modal__content">{content}</div>
        <div className="fly-modal__action-box">
          <span className="space" />
          <button className="fly-button secondary modal" onClick={this.onClose}>
            <b>{cancelText}</b>
          </button>
          {isConfirm ? (
            <button
              className={cs(
                "fly-button modal",
                { disabled: loadingConfirmBtn },
                confirmBtnClass
              )}
              disabled={loadingConfirmBtn}
              onClick={this.onConfirm}
            >
              <b>{confirmText}</b>
            </button>
          ) : (
            ""
          )}
        </div>
      </RodalCustom>
    );
  }

  @autobind
  private onConfirm() {
    if (this.state.onConfirm) {
      this.state.onConfirm();
    }
  }

  @autobind
  private onClose() {
    if (this.state.onClose) {
      this.state.onClose();
    }

    this.setState({ isOpen: false });
  }
}

function mapState2Props(state: any) {
  return {
    modal: state.modal,
  };
}

export const RodalModal = connect(mapState2Props)(RodalModal0);
