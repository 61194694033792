let currentTheme = 1;

function crateOnSetup(onSetup, name) {
  return function () {
    //@ts-ignore
    let result = onSetup.apply(this, arguments);
    result[name].properties = result[name].properties || {};
    result[name].properties.theme = getNextThemeName();
    return result;
  };
}

export function getNextThemeName() {
  // currentTheme++;
  // if ( currentTheme > 8 ) {
  //   currentTheme = 1;
  // }
  return "t" + currentTheme;
}

export function resetCurrentTheme() {
  currentTheme = 0;
}

export function themeSupport(mode, name) {
  mode.onSetup = crateOnSetup(mode.onSetup, name);
  return mode;
}
