import {
  createStore,
  applyMiddleware,
  compose,
  Store,
  Middleware,
} from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { IStore } from "./IStore";
import { createLogger } from "redux-logger";

export function configureStore(history, initialState?: IStore): Store<IStore> {
  const middlewares: Middleware[] = [routerMiddleware(history), thunk];

  /** Add Only Dev. Middlewares */
  if (process.env.NODE_ENV !== "production") {
    createLogger();
    // const logger = createLogger();
    // middlewares.push(logger);
  }

  const composeEnhancers =
    (process.env.NODE_ENV !== "production" &&
      typeof window === "object" &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store: Store<IStore> = createStore<IStore>(
    rootReducer,
    // @ts-ignore
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (process.env.NODE_ENV !== "production" && (module as any).hot) {
    (module as any).hot.accept("./reducers", () => {
      store.replaceReducer(require("./reducers"));
    });
  }

  return store;
}
