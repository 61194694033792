import { combineReducers, Reducer } from "redux";
import { routerReducer } from "react-router-redux";
import { IStore } from "./IStore";
import { uiReducer } from "redux/modules/ui";
import { dialogReducer } from "./dialogbox";
import { modalReducer } from "./modal";

const rootReducer: Reducer<IStore> = combineReducers<IStore>({
  routing: routerReducer,
  ui: uiReducer,
  dialogbox: dialogReducer,
  modal: modalReducer,
});

export default rootReducer;
