import * as React from "react";
import { DateFilter } from "pages/Planner/Filter/DateFilter";
import autobind from "autobind-decorator";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export class CalendarFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedRanges: this.props.filters.selected_dates,
      dateFilterRef: React.createRef(),
    };

    this.props.events.on("planner-add-date-range", ({ start, end }) =>
      this.addRange(start, end)
    );
    this.props.events.on("planner-on-reset", this.onReset);
  }

  public render() {
    const { selectedRanges, dateFilterRef } = this.state;

    return (
      <div className="fly-section fly-section--no-padding">
        <div className="fly-section__header">
          <b>Dates</b>
        </div>
        <div className="fly-section__content">
          <div className="fly-date-filter">
            {selectedRanges.map((range, index) => (
              <DateFilter
                key={range.key}
                isNewRange={false}
                range={range}
                deleteRange={this.deleteRange}
                updateRange={this.updateRange}
                rangeCount={selectedRanges.length}
                rangeIndex={index + 1}
              />
            ))}
            <DateFilter
              key={-1}
              ref={dateFilterRef}
              isNewRange={true}
              addRange={this.addRange}
              rangeCount={selectedRanges.length}
              rangeIndex={0}
            />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private addRange(start, end) {
    if (this.state.dateFilterRef.current) {
      this.state.dateFilterRef.current.hidePanel();
    }

    const range_selected = moment.range(start, end);
    for (let r of this.state.selectedRanges) {
      if (
        range_selected.overlaps(
          moment.range(
            new Date(r.start.getTime() - 10000),
            new Date(r.end.getTime() + 10000)
          )
        )
      ) {
        return false;
      }
    }
    let selectedRanges = Object.assign([], this.state.selectedRanges);
    selectedRanges.push({ start, end, key: new Date().valueOf() });
    this.setState({ selectedRanges }, () => {
      this.props.events.emit(
        "planner-on-filter-calendar",
        this.state.selectedRanges
      );
    });
    return true;
  }

  @autobind
  private deleteRange(range) {
    let selectedRanges = Object.assign([], this.state.selectedRanges);
    selectedRanges = selectedRanges.filter((r) => r.key !== range.key);
    this.setState({ selectedRanges }, () => {
      this.props.events.emit(
        "planner-on-filter-calendar",
        this.state.selectedRanges
      );
    });
  }

  @autobind
  private updateRange(range, start, end) {
    const range_selected = moment.range(start, end);
    for (let r of this.state.selectedRanges) {
      if (
        r.key !== range.key &&
        range_selected.overlaps(
          moment.range(
            new Date(r.start.getTime() - 10000),
            new Date(r.end.getTime() + 10000)
          )
        )
      ) {
        return false;
      }
    }

    let selectedRanges = Object.assign([], this.state.selectedRanges);
    let updatedRange = selectedRanges.filter((r) => r.key === range.key)[0];
    updatedRange.start = start;
    updatedRange.end = end;
    this.setState({ selectedRanges }, () => {
      this.props.events.emit(
        "planner-on-filter-calendar",
        this.state.selectedRanges
      );
    });
    return true;
  }

  @autobind
  private onReset() {
    this.setState({ selectedRanges: [] }, () => {
      this.props.events.emit(
        "planner-on-filter-calendar",
        this.state.selectedRanges
      );
    });
  }
}
