import * as React from "react";
import { Manager, Reference, Popper } from "react-popper";

interface IProps {
  text: string;
}

const InlineTooltip: React.FC<React.PropsWithChildren<IProps>> = ({
  text,
  children,
}) => (
  <Manager>
    <Reference>
      {({ ref }) => (
        <span ref={ref} className="tooltip--inline">
          {text}
        </span>
      )}
    </Reference>
    <Popper
      placement="right"
      positionFixed={true}
      modifiers={{
        preventOverflow: { enabled: false },
        arrow: { enabled: true },
        offset: { enabled: true, offset: "0 50.6" },
      }}
    >
      {({ ref, style, placement, arrowProps }) => (
        <div
          ref={ref}
          style={{ ...style, zIndex: 999999999 }}
          data-placement={placement}
          className="tooltip-content"
        >
          {children}
          <div
            ref={arrowProps.ref}
            style={arrowProps.style}
            className="tooltip-arrow"
          />
        </div>
      )}
    </Popper>
  </Manager>
);

export default InlineTooltip;
