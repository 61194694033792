import * as React from "react";
import { Constants } from "pages/Planner/Map/draw/Constants";
import autobind from "autobind-decorator";
import SVG from "react-inlinesvg";
import cs from "classnames";
import * as turf from "@turf/turf";
import * as numeral from "numeral";
import { getCityofLocation } from "helpers/CONSTANTS";

export class Mapfences extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      shapes: props.drawing.shapes || [],
      geojsonFiles: [],
      inDrawMode: false,
      events: {
        [Constants.events.CREATE]: this.onShapeCreate,
        [Constants.events.DELETE]: this.onShapeDelete,
        [Constants.events.DELETE_ALL]: this.removeAllShapes,
        [Constants.events.START_DRAW]: this.onDrawStart,
        [Constants.events.STOP_DRAW]: this.onDrawEnd,
        "geofence-on-receive-data": this.onReceiveDataGeofence,
        "workspace-on-delete-success": this.onDeleteWorkspaceItem,
      },
    };

    if (this.state.shapes) {
      this.state.shapes.forEach((shapeData) => {
        this.props.events.emit(
          Constants.events.ADD_PTC,
          shapeData.geojson.features[0]
        );
      });
    }

    Object.keys(this.state.events).forEach((key) => {
      this.props.events.on(key, this.state.events[key]);
    });
  }

  public componentWillUnmount() {
    Object.keys(this.state.events).forEach((key) => {
      this.props.events.off(key, this.state.events[key]);
    });
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.drawing !== this.props.drawing) {
      this.props.drawing.shapes.forEach((shapeData) => {
        this.props.events.emit(
          Constants.events.ADD_PTC,
          shapeData.geojson.features[0]
        );
      });
      this.setState({ shapes: this.props.drawing.shapes });
    }
  }

  public render() {
    if (!this.props.isVisible) {
      return null;
    }

    let shapeItems = this.state.shapes.map((data, index) => {
      if (data.type === "fromFile") {
        return "";
      }

      let toggleWorkspace = this.toggleWorkspace.bind(this, data);
      let removeShape = this.removeShape.bind(this, index);

      return (
        <div key={`r_${index}`} className="shape__item">
          <div>
            <SVG
              src={
                data.name === "Corridor"
                  ? "/public/images/icons/corridor.svg"
                  : data.name === "Circle"
                  ? "/public/images/icons/hm-circle-unselected.svg"
                  : data.name === "Rectangle"
                  ? "/public/images/icons/hm-rectangle-unselected.svg"
                  : data.name === "Polygon"
                  ? "/public/images/icons/hm-polygon-unselected.svg"
                  : "/public/images/icons/hm-point-unselected.svg"
              }
              className="fly-svg-icon fly-svg-icon--xlarge"
            />
          </div>
          <div className="shape__item__info">
            <p>{data.name}</p>
            <p className="small">{data.area}</p>{" "}
            {data.length && <p className="small">{data.length}</p>}
          </div>
          <div className="actions">
            <div
              className={cs("item-favorite-button", {
                exists: data.workspaceId,
              })}
              onClick={toggleWorkspace}
            >
              <SVG
                className="fly-svg-icon fly-svg-icon--small favorite-icon"
                src={"/public/images/icons/favorite.svg"}
              />
            </div>
            <div className="item-trash-button" onClick={removeShape}>
              <SVG
                className="fly-svg-icon fly-svg-icon--small trash-icon"
                src={"/public/images/icons/hm-trash.svg"}
              />
            </div>
          </div>
        </div>
      );
    });

    let geojsonFileItems = this.state.geojsonFiles.map((data, index) => {
      let toggleWorkspace = this.toggleWorkspace.bind(this, data);
      let removeGeojsonFile = this.removeGeojsonFile.bind(this, index);

      return (
        <div key={`r_${index}`} className="shape__item">
          <div>
            <SVG
              src="/public/images/icons/json-icon.svg"
              className="fly-svg-icon fly-svg-icon--xlarge"
            />
          </div>
          <div className="shape__item__info">
            <p>{data.file.name}</p>
            <p className="small">{data.file.size} bytes</p>
          </div>
          <div className="actions">
            <div
              className={cs("item-favorite-button", {
                exists: data.workspaceId,
              })}
              onClick={toggleWorkspace}
            >
              <SVG
                className="fly-svg-icon fly-svg-icon--small favorite-icon"
                src={"/public/images/icons/favorite.svg"}
              />
            </div>
            <div className="item-trash-button" onClick={removeGeojsonFile}>
              <SVG
                className="fly-svg-icon fly-svg-icon--small trash-icon"
                src={"/public/images/icons/hm-trash.svg"}
              />
            </div>
          </div>
        </div>
      );
    });

    if (shapeItems.length === 0) {
      return (
        <div className="mapfences no-fences">
          <div className="tool-info">
            <div className="drawing-text-container">
              <SVG
                className="fly-svg-icon fly-svg-icon--small"
                src={"/public/images/icons/hm-edit.svg"}
              />
              <p className="small">
                You can click the shapes at the top of the map
              </p>
            </div>
            {!this.state.inDrawMode && (
              <div className="drawing-button-container">
                <button className="fly-button primary" onClick={this.startDraw}>
                  START DRAWING
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="mapfences">
        {shapeItems}
        {geojsonFileItems}
        {!this.state.inDrawMode && (
          <div className="drawing-button-container">
            <button className="fly-button primary" onClick={this.startDraw}>
              START DRAWING
            </button>
          </div>
        )}
      </div>
    );
  }

  @autobind
  public openGeofenceMode() {
    this.props.events.emit(Constants.events.START_DRAW);
  }

  public removeShape(index) {
    let shapes = this.state.shapes;

    let shapeData = shapes[index];

    this.props.events.emit(Constants.events.REMOVE_SHAPE, shapeData);

    shapes.splice(index, 1);

    this.setState({ shapes });
    this.props.events.emit("mapfences-shapes-updated", shapes);
  }

  public removeGeojsonFile(index) {
    let shapes = this.state.shapes;
    let geojsonFiles = this.state.geojsonFiles;
    let geojsonFile = geojsonFiles[index];

    shapes.forEach((shape) => {
      if (shape.parentId === geojsonFile.geojson.id) {
        this.props.events.emit(Constants.events.REMOVE_SHAPE, shape);
      }
    });

    shapes = shapes.filter((shape) => {
      return shape.parentId !== geojsonFile.geojson.id;
    });

    geojsonFiles.splice(index, 1);

    this.props.events.emit("geofence-on-geojson-file-removed", geojsonFile);
    this.setState({ geojsonFiles, shapes }, () => {
      this.props.events.emit("mapfences-shapes-updated", shapes);
    });
  }

  public toggleWorkspace(data) {
    if (data.workspaceId) {
      this.props.events.emit("workspace-on-delete-request", data.workspaceId);
    } else {
      let item: any = null;

      if (data.type === "shape") {
        item = { type: "shape", name: data.name, dma: data.city, data };
      } else {
        item = {
          type: "geojsonFile",
          name: data.file.name,
          data: {
            geojson: data.geojson,
            file: { size: data.file.size },
          },
          dma: this.state.shapes.find(
            (shape) => shape.parentId === data.geojson.id
          ).city,
        };
      }

      const onAddSuccess = (item) => {
        data.workspaceId = "" + item.id;

        if (item.type === "shape") {
          this.setState({ shapes: this.state.shapes });
        } else if (item.type === "geojsonFile") {
          this.setState({ geojsonFiles: this.state.geojsonFiles });
        }

        this.props.events.off("workspace-on-add-success", onAddSuccess);
      };

      this.props.events.emit("workspace-on-add-request", item);
      this.props.events.on("workspace-on-add-success", onAddSuccess);
    }
  }

  @autobind
  public removeAllShapes(excludedIds) {
    let shapes = this.state.shapes;

    shapes.forEach((shape) => {
      if (excludedIds && excludedIds.includes(shape.ids[0])) {
        return;
      }

      this.props.events.emit(Constants.events.REMOVE_SHAPE, shape);
    });

    this.setState({ shapes: [], geojsonFiles: [] }, () => {
      this.props.events.emit("mapfences-shapes-updated", []);
    });
  }

  @autobind
  public onDrawStart() {
    this.setState({ inDrawMode: true });
  }

  @autobind
  public onDrawEnd() {
    this.setState({ inDrawMode: false });
  }

  @autobind
  private onReceiveDataGeofence(geofence) {
    if (
      geofence.drawing.geojsonFiles.length !== this.state.geojsonFiles.length
    ) {
      this.setState({ geojsonFiles: geofence.drawing.geojsonFiles });
    }
  }

  @autobind
  private onShapeCreate(geojson) {
    let ids: any = [];
    for (let feature of geojson.features) {
      ids.push(feature.id);
    }

    let feature = geojson.features[0];
    let properties = feature.properties || {};
    let theme = properties.theme || "t1";
    let type = properties.type || "shape";
    let parentId = properties.parentId;

    let name;
    if (properties.shapeType === Constants.geojsonTypes.ELLIPSE) {
      name = "Circle";
    } else if (properties.shapeType === Constants.geojsonTypes.RECTANGLE) {
      name = "Rectangle";
    } else if (feature.geometry.type === Constants.geojsonTypes.POLYGON) {
      if (properties.shapeType === "corridor") {
        name = "Corridor";
      } else if (properties.mile || properties.radius) {
        name = "Circle";
      } else {
        name = "Polygon";
      }
    } else {
      name = "Point";
    }

    let area = numeral(turf.area(feature)).format("0,0") + " Sq m";
    let center = turf.center(feature);
    let city = getCityofLocation({
      lat: center.geometry!.coordinates[1],
      lng: center.geometry!.coordinates[0],
    });
    let shapes = this.state.shapes;
    let length =
      name === "Corridor"
        ? numeral(properties.length).format("0,0") + " m"
        : undefined;

    delete geojson.target;

    shapes.push({
      name,
      geojson,
      ids,
      theme,
      area,
      city,
      type,
      parentId,
      length,
    });

    this.setState({ shapes });
    this.props.events.emit("mapfences-shapes-updated", shapes);
  }

  @autobind
  private onShapeDelete(ids) {
    let shapeIndex = this.getSelectedShapeDataIndex(ids);
    if (shapeIndex !== -1) {
      let shapes = this.state.shapes;
      shapes.splice(shapeIndex, 1);
      this.setState({ shapes });
      this.props.events.emit("mapfences-shapes-updated", shapes);
      this.props.events.emit(Constants.events.REMOVE_SHAPE, { ids });
      this.props.events.emit(Constants.events.DELETE, ids);
    }
  }

  private getSelectedShapeDataIndex(ids: string[]): number {
    for (let i = 0; i < this.state.shapes.length; i++) {
      if (this.state.shapes[i].ids.toString() === ids.toString()) {
        return i;
      }
    }
    return -1;
  }

  @autobind
  private startDraw() {
    this.props.events.emit(
      this.state.inDrawMode
        ? Constants.events.STOP_DRAW
        : Constants.events.START_DRAW
    );
  }

  @autobind
  private onDeleteWorkspaceItem({ id, type }) {
    if (this.state.shapes.length || this.state.geojsonFiles.length) {
      if (type === "shape") {
        const index = this.state.shapes.findIndex((item) => {
          return item.workspaceId === id;
        });

        if (index !== -1) {
          this.state.shapes[index].workspaceId = null;
          this.setState({ shapes: this.state.shapes });
        }
      } else if (type === "geojsonFile") {
        const index = this.state.geojsonFiles.findIndex((item) => {
          return item.workspaceId === id;
        });

        if (index !== -1) {
          this.state.geojsonFiles[index].workspaceId = null;
          this.setState({ geojsonFiles: this.state.geojsonFiles });
        }
      }
    }
  }
}
