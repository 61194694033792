const StaticMode: any = {};

StaticMode.onSetup = function () {
  this.setActionableState(); // default actionable state is false for all actions
  return {};
};

StaticMode.toDisplayFeatures = function (_state, geojson, display) {
  display(geojson);
};

export { StaticMode };
