import { GlobalStore } from "helpers";

export const TOGGLE_MODAL = "modal/TOGGLE";

const initialState = {
  isOpen: false,
  loadingConfirmBtn: false,
  title: null,
  content: "Modal Content",
  confirmBtnClass: "alert",
  confirmText: "Confirm",
  cancelText: "Cancel",
  isConfirm: false,
  onConfirm: () => {},
  onClose: () => {},
};

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export function toggleModal(options) {
  const currentState = GlobalStore.getStore().getState().modal;

  return {
    type: TOGGLE_MODAL,
    payload: {
      isOpen: options.isOpen || false,
      loadingConfirmBtn: options.loadingConfirmBtn,
      title: options.title || currentState.title,
      content: options.content || currentState.content,
      confirmText: options.confirmText || currentState.confirmText,
      confirmBtnClass: options.confirmBtnClass || currentState.confirmBtnClass,
      cancelText: options.cancelText || currentState.cancelText,
      isConfirm: options.isConfirm || currentState.isConfirm,
      onConfirm: options.onConfirm || currentState.onConfirm,
      onClose: options.onClose || currentState.onClose,
    },
  };
}
