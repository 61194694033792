import * as React from "react";
import * as sizeMe from "react-sizeme";

export function withMonitorSize<P>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  const SizedComponent = sizeMe({ monitorWidth: true, monitorHeight: true })(
    Component
  );

  class C extends React.Component<P> {
    public render(): JSX.Element {
      return <SizedComponent {...this.props} />;
    }
  }

  return C;
}
