import * as React from "react";
import * as mapboxgl from "mapbox-gl";
import autobind from "autobind-decorator";
import { Constants } from "pages/Planner/Map/draw/Constants";

const LAYER: string = "point-to-circle-layer";
const LAYER_SOURCE: string = "point-to-circle-layer-source";

interface IPointToCircleState {
  point_to_circles: any;
  draw_type: string;
  popup: any;
}

export class PointToCircleLayer extends React.Component<
  any,
  IPointToCircleState
> {
  private map: any = null;

  constructor(props) {
    super(props);
    this.state = {
      point_to_circles: null,
      popup: null,
      draw_type: "",
    };
    this.props.events.on(
      "point-to-circle-layer-clear-point",
      this.ptcClearLayer
    );
    this.props.events.on("draw-type-changed", this.setDrawType);
    this.props.events.on(
      "point-to-circle-layer-circle-mile-entered",
      this.pointToCircle
    );
    this.props.events.on("map.go.to.coordinates", this.goToCoordinates);
    this.props.events.on("planner-on-reset", this.onReset);
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.map) {
      this.map = nextProps.map;
      this.map.getMap().on("click", this.mapOnClickDrawPoint);
      this.map.getMap().on("mousedown", this.mapOnMouseDown);
    }
  }

  public render() {
    return null;
  }

  @autobind
  private goToCoordinates(longlat) {
    let map = this.map;

    if (map) {
      map._goToLocation(longlat.long, longlat.lat, 11);
      this.addPoint(
        longlat.long,
        longlat.lat,
        longlat.info,
        longlat.openContextMenu
      );
    }
  }

  @autobind
  private setDrawType(draw_type) {
    this.setState({ draw_type });
  }

  @autobind
  private onMove(e) {
    let map = this.map.getMap();
    let geojson = this.state.point_to_circles;
    if (geojson) {
      geojson.data.features[0].geometry.coordinates = [
        e.lngLat.lng,
        e.lngLat.lat,
      ];
      map.getSource(LAYER_SOURCE).setData(geojson.data);
    }
  }

  @autobind
  private onUp(e) {
    let map = this.map.getMap();
    map.dragPan.enable();

    let geojson = this.state.point_to_circles;
    if (geojson) {
      geojson.data.features[0].geometry.coordinates = [
        e.lngLat.lng,
        e.lngLat.lat,
      ];
      map.getSource(LAYER_SOURCE).setData(geojson.data);
      this.setState({ point_to_circles: geojson });
    }
    map.off("mousemove", this.onMove);
  }

  @autobind
  private mapOnMouseDown(e) {
    if (e.originalEvent.button === 2) {
      let map = this.map.getMap();
      let features = map.queryRenderedFeatures([e.point.x, e.point.y]);
      features = features.filter((f) => {
        if (f && f.properties) {
          f.properties.clientxy = {
            clientX: e.originalEvent.clientX,
            clientY: e.originalEvent.clientY,
          };
        }
        return f.layer.id === LAYER;
      });

      if (features.length > 0) {
        map.dragPan.disable();
        map.on("mousemove", this.onMove);
        map.once("mouseup", this.onUp);
      }
    }
  }

  @autobind
  private ptcClearLayer() {
    let map = this.map.getMap();
    if (map.getSource(LAYER_SOURCE)) {
      map.getSource(LAYER_SOURCE).setData({
        type: "FeatureCollection",
        features: [],
      });
    }
  }

  private openContextMenu(openContextMenu) {
    if (openContextMenu) {
      setTimeout(() => {
        let centerX = this.map.size.width / 2;
        let centerY = this.map.size.height / 2;

        const renderedFeatures = this.getFeatures({
          point: { x: centerX, y: centerY },
        });

        const eventParams = {
          clientX: openContextMenu.clientX || openContextMenu.offsetX + centerX,
          clientY: openContextMenu.clientY || openContextMenu.offsetY + centerY,
          features: renderedFeatures,
        };

        this.props.events.emit("map.left.clicked", eventParams);
      }, 1500);
    }
  }

  @autobind
  private addPoint(lng, lat, info = null, openContextMenu = null) {
    let map = this.map.getMap();

    if (this.state.popup) {
      this.state.popup.remove();
      this.setState({ popup: null });
    }

    if (info) {
      const popup = new mapboxgl.Popup({
        closeOnClick: false,
        offset: { bottom: [0, -20] },
      })
        .setLngLat([lng, lat])
        .setHTML(info)
        .addTo(map);

      this.setState({ popup });

      setTimeout(() => {
        popup.remove();
        this.setState({ popup: null });
      }, 3000);
    }

    if (map.getSource(LAYER_SOURCE)) {
      let geojson = this.state.point_to_circles;
      geojson.data.features[0].geometry.coordinates = [lng, lat];
      map.getSource(LAYER_SOURCE).setData(geojson.data);

      this.openContextMenu(openContextMenu);
    } else {
      let geojson = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              id: 0,
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [lng, lat],
              },
            },
          ],
        },
      };

      map.addSource(LAYER_SOURCE, geojson);

      this.setState({ point_to_circles: geojson });
    }

    this.setState({ draw_type: "" }, () => {
      this.props.events.emit("set-draw-type", "");
    });

    if (map.getLayer(LAYER)) {
      return;
    } else {
      map.loadImage("/public/images/icons/hm-point-unselected.png", function (
        error,
        image
      ) {
        if (error) {
          throw error;
        }
        map.addImage("point", image);
        map.addLayer({
          id: LAYER,
          type: "symbol",
          source: LAYER_SOURCE,
          layout: {
            "icon-image": "point",
          },
        });
      });
    }

    this.openContextMenu(openContextMenu);
  }

  @autobind
  private mapOnClickDrawPoint(e, lng?, lat?) {
    if (this.state.draw_type === Constants.modes.DRAW_POINT) {
      if (e.originalEvent.button === 0) {
        this.addPoint(e.lngLat.lng, e.lngLat.lat, null);
      }
    } else if (lng && lat) {
      this.addPoint(lng, lat, null);
    }
  }

  @autobind
  private onReset() {
    if (this.state.popup) {
      this.state.popup.remove();
    }

    this.setState({ popup: null });
  }

  @autobind
  private pointToCircle(s_mile) {
    let mile = parseFloat(s_mile);
    this.ptcClearLayer();
    let geojson = this.state.point_to_circles;
    const coords = geojson.data.features[0].geometry.coordinates;
    const km = mile * 1.60934;
    const ret = [];
    const distanceX = km / (111.32 * Math.cos((coords[1] * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta;
    let x;
    let y;
    for (let i = 0; i < 64; i += 1) {
      theta = (i / 64) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords[0] + x, coords[1] + y]);
    }
    ret.push(ret[0]);

    let feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [ret],
      },
      id: "f-from-point-" + Math.random(),
      properties: {
        mile,
        theme: "t1",
        shapeType: Constants.geojsonTypes.POLYGON,
      },
    };

    this.props.events.emit(Constants.events.ADD_PTC, feature);
    this.props.events.emit(Constants.events.CREATE, { features: [feature] });
  }

  private getFeatures(e) {
    let map = this.map.getMap();

    let features = map.queryRenderedFeatures([e.point.x, e.point.y]);
    features = features.filter((f) => {
      return f.layer.source === LAYER_SOURCE;
    });
    return features;
  }
}
