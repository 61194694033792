import * as React from "react";
import ReactSelect from "react-select";
import autobind from "autobind-decorator";
import * as cs from "classnames";
import DateHelpers from "./dateHelpers";
import Calendar from "./Calendar";

class DatePicker extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  public getInitialState() {
    return {
      isOpened: false,
      selectedDate: DateHelpers.dateOptions[0],
    };
  }

  public render() {
    const { selectedDate } = this.state;

    return (
      <div className="date-picker-component">
        <div
          className={cs("fly-combo-box fly-combo-box--has-icon date-dropdown", {
            selected: selectedDate.value,
          })}
        >
          <ReactSelect
            className="select"
            classNamePrefix="select"
            onChange={this.setDates}
            options={DateHelpers.dateOptions}
            value={selectedDate}
          />
        </div>

        <Calendar
          isOpened={this.state.isOpened}
          disabledDays={this.props.disabledDays}
          initial_start_date={this.props.initial_start_date}
          initial_end_date={this.props.initial_end_date}
          onClose={this.closePanel}
          onApply={this.onApply}
          onReset={this.onReset}
        />
      </div>
    );
  }

  @autobind
  private onApply(date) {
    this.setState({
      isOpened: false,
      selectedDate: date,
    });

    this.props.onDateRangeSelected(date);
  }

  @autobind
  private onReset() {
    this.setState({
      isOpened: false,
      selectedDate: DateHelpers.dateOptions[0],
    });

    this.props.onDateRangeSelected(DateHelpers.dateOptions[0]);
  }

  @autobind
  private setDates(date) {
    if (date) {
      if (date.value === "datepicker") {
        this.setState({ isOpened: true });
      } else {
        this.setState({
          selectedDate: DateHelpers.dateOptions.find(
            (option) => option.label === date.label
          ),
        });
        this.props.onDateRangeSelected(date);
      }
    }
  }

  @autobind
  private closePanel() {
    this.setState({ isOpened: false });
  }
}

export default DatePicker;
