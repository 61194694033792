// Source https://www.zip-codes.com/state/ny.asp
let poBoxZipcodes = [
  "90009",
  "90030",
  "90050",
  "90051",
  "90052",
  "90053",
  "90054",
  "90055",
  "90060",
  "90070",
  "90072",
  "90073",
  "90074",
  "90075",
  "90076",
  "90078",
  "90080",
  "90081",
  "90082",
  "90083",
  "90084",
  "90086",
  "90087",
  "90088",
  "90089",
  "90090",
  "90091",
  "90093",
  "90095",
  "90096",
  "90099",
  "90134",
  "90189",
  "90202",
  "90209",
  "90213",
  "90223",
  "90224",
  "90231",
  "90233",
  "90239",
  "90251",
  "90263",
  "90264",
  "90267",
  "90294",
  "90295",
  "90296",
  "90306",
  "90307",
  "90308",
  "90309",
  "90310",
  "90312",
  "90406",
  "90407",
  "90408",
  "90409",
  "90410",
  "90411",
  "90507",
  "90508",
  "90509",
  "90510",
  "90607",
  "90608",
  "90609",
  "90610",
  "90622",
  "90624",
  "90632",
  "90633",
  "90637",
  "90639",
  "90651",
  "90652",
  "90661",
  "90662",
  "90671",
  "90702",
  "90704",
  "90707",
  "90711",
  "90714",
  "90721",
  "90733",
  "90734",
  "90742",
  "90743",
  "90747",
  "90748",
  "90749",
  "90801",
  "90809",
  "90832",
  "90840",
  "90842",
  "90844",
  "90846",
  "90847",
  "90848",
  "90853",
  "90895",
  "90899",
  "91003",
  "91009",
  "91012",
  "91017",
  "91021",
  "91023",
  "91025",
  "91031",
  "91041",
  "91043",
  "91046",
  "91066",
  "91077",
  "91102",
  "91109",
  "91110",
  "91114",
  "91115",
  "91116",
  "91117",
  "91118",
  "91121",
  "91123",
  "91124",
  "91125",
  "91126",
  "91129",
  "91182",
  "91184",
  "91185",
  "91188",
  "91189",
  "91199",
  "91209",
  "91221",
  "91222",
  "91224",
  "91225",
  "91226",
  "91305",
  "91308",
  "91309",
  "91310",
  "91313",
  "91319",
  "91322",
  "91327",
  "91328",
  "91329",
  "91330",
  "91333",
  "91334",
  "91337",
  "91341",
  "91346",
  "91353",
  "91357",
  "91358",
  "91359",
  "91365",
  "91371",
  "91372",
  "91376",
  "91380",
  "91385",
  "91386",
  "91392",
  "91393",
  "91394",
  "91395",
  "91396",
  "91404",
  "91407",
  "91408",
  "91409",
  "91410",
  "91412",
  "91413",
  "91416",
  "91426",
  "91470",
  "91482",
  "91495",
  "91496",
  "91499",
  "91503",
  "91507",
  "91508",
  "91510",
  "91521",
  "91522",
  "91523",
  "91526",
  "91603",
  "91609",
  "91610",
  "91611",
  "91612",
  "91614",
  "91615",
  "91616",
  "91617",
  "91618",
  "91714",
  "91715",
  "91716",
  "91729",
  "91734",
  "91735",
  "91743",
  "91747",
  "91749",
  "91756",
  "91759",
  "91769",
  "91771",
  "91772",
  "91778",
  "91785",
  "91788",
  "91793",
  "91802",
  "91896",
  "91899",
  "91903",
  "91908",
  "91909",
  "91912",
  "91921",
  "91931",
  "91933",
  "91943",
  "91944",
  "91946",
  "91948",
  "91951",
  "91976",
  "91979",
  "91987",
  "92013",
  "92018",
  "92022",
  "92023",
  "92030",
  "92033",
  "92038",
  "92039",
  "92046",
  "92049",
  "92051",
  "92052",
  "92060",
  "92067",
  "92068",
  "92072",
  "92074",
  "92079",
  "92085",
  "92088",
  "92092",
  "92093",
  "92096",
  "92112",
  "92132",
  "92134",
  "92136",
  "92137",
  "92138",
  "92140",
  "92142",
  "92143",
  "92145",
  "92147",
  "92149",
  "92150",
  "92152",
  "92153",
  "92155",
  "92158",
  "92159",
  "92160",
  "92161",
  "92163",
  "92165",
  "92166",
  "92167",
  "92168",
  "92169",
  "92170",
  "92171",
  "92172",
  "92174",
  "92175",
  "92176",
  "92177",
  "92178",
  "92179",
  "92182",
  "92186",
  "92187",
  "92191",
  "92192",
  "92193",
  "92195",
  "92196",
  "92198",
  "92202",
  "92222",
  "92226",
  "92232",
  "92235",
  "92239",
  "92244",
  "92247",
  "92248",
  "92255",
  "92258",
  "92259",
  "92261",
  "92263",
  "92266",
  "92267",
  "92268",
  "92273",
  "92275",
  "92286",
  "92304",
  "92312",
  "92317",
  "92321",
  "92322",
  "92325",
  "92329",
  "92331",
  "92333",
  "92334",
  "92340",
  "92341",
  "92350",
  "92352",
  "92357",
  "92369",
  "92375",
  "92378",
  "92382",
  "92384",
  "92385",
  "92386",
  "92389",
  "92391",
  "92393",
  "92398",
  "92402",
  "92406",
  "92413",
  "92415",
  "92418",
  "92423",
  "92427",
  "92502",
  "92513",
  "92514",
  "92516",
  "92517",
  "92519",
  "92521",
  "92522",
  "92531",
  "92546",
  "92549",
  "92552",
  "92554",
  "92556",
  "92564",
  "92572",
  "92581",
  "92589",
  "92593",
  "92599",
  "92605",
  "92607",
  "92609",
  "92615",
  "92616",
  "92619",
  "92623",
  "92628",
  "92650",
  "92652",
  "92654",
  "92658",
  "92659",
  "92674",
  "92678",
  "92684",
  "92685",
  "92690",
  "92693",
  "92697",
  "92698",
  "92702",
  "92711",
  "92712",
  "92728",
  "92735",
  "92781",
  "92803",
  "92811",
  "92812",
  "92814",
  "92815",
  "92816",
  "92817",
  "92822",
  "92825",
  "92834",
  "92836",
  "92837",
  "92838",
  "92842",
  "92846",
  "92850",
  "92856",
  "92857",
  "92859",
  "92863",
  "92864",
  "92871",
  "92877",
  "92878",
  "92885",
  "93002",
  "93005",
  "93006",
  "93007",
  "93009",
  "93011",
  "93014",
  "93016",
  "93020",
  "93024",
  "93031",
  "93032",
  "93034",
  "93040",
  "93042",
  "93043",
  "93044",
  "93061",
  "93062",
  "93064",
  "93067",
  "93094",
  "93099",
  "93102",
  "93106",
  "93107",
  "93116",
  "93118",
  "93120",
  "93121",
  "93130",
  "93140",
  "93150",
  "93160",
  "93190",
  "93199",
  "93201",
  "93208",
  "93216",
  "93218",
  "93220",
  "93222",
  "93227",
  "93232",
  "93234",
  "93237",
  "93246",
  "93258",
  "93261",
  "93275",
  "93276",
  "93278",
  "93279",
  "93282",
  "93290",
  "93302",
  "93303",
  "93380",
  "93383",
  "93384",
  "93385",
  "93386",
  "93387",
  "93388",
  "93389",
  "93390",
  "93403",
  "93406",
  "93408",
  "93409",
  "93412",
  "93421",
  "93423",
  "93424",
  "93429",
  "93435",
  "93438",
  "93440",
  "93441",
  "93443",
  "93447",
  "93448",
  "93456",
  "93457",
  "93464",
  "93475",
  "93483",
  "93502",
  "93504",
  "93515",
  "93519",
  "93522",
  "93524",
  "93528",
  "93529",
  "93530",
  "93539",
  "93541",
  "93542",
  "93549",
  "93554",
  "93556",
  "93558",
  "93581",
  "93584",
  "93586",
  "93590",
  "93592",
  "93596",
  "93599",
  "93605",
  "93606",
  "93607",
  "93613",
  "93624",
  "93627",
  "93633",
  "93634",
  "93639",
  "93642",
  "93649",
  "93661",
  "93665",
  "93666",
  "93670",
  "93673",
  "93707",
  "93708",
  "93709",
  "93712",
  "93714",
  "93715",
  "93716",
  "93717",
  "93718",
  "93724",
  "93729",
  "93740",
  "93741",
  "93744",
  "93745",
  "93747",
  "93750",
  "93755",
  "93760",
  "93761",
  "93764",
  "93765",
  "93771",
  "93772",
  "93773",
  "93774",
  "93775",
  "93776",
  "93777",
  "93778",
  "93779",
  "93786",
  "93790",
  "93791",
  "93792",
  "93793",
  "93794",
  "93844",
  "93888",
  "93902",
  "93912",
  "93915",
  "93921",
  "93922",
  "93928",
  "93932",
  "93942",
  "93944",
  "93954",
  "93962",
  "94011",
  "94016",
  "94017",
  "94018",
  "94023",
  "94026",
  "94035",
  "94037",
  "94039",
  "94042",
  "94064",
  "94083",
  "94088",
  "94119",
  "94120",
  "94125",
  "94126",
  "94137",
  "94139",
  "94140",
  "94141",
  "94142",
  "94144",
  "94145",
  "94146",
  "94147",
  "94151",
  "94159",
  "94160",
  "94161",
  "94163",
  "94164",
  "94172",
  "94177",
  "94203",
  "94204",
  "94205",
  "94206",
  "94207",
  "94208",
  "94209",
  "94211",
  "94229",
  "94230",
  "94232",
  "94234",
  "94235",
  "94236",
  "94237",
  "94239",
  "94240",
  "94244",
  "94245",
  "94247",
  "94248",
  "94249",
  "94250",
  "94252",
  "94254",
  "94256",
  "94257",
  "94258",
  "94259",
  "94261",
  "94262",
  "94263",
  "94267",
  "94268",
  "94269",
  "94271",
  "94273",
  "94274",
  "94277",
  "94278",
  "94279",
  "94280",
  "94282",
  "94283",
  "94284",
  "94285",
  "94287",
  "94288",
  "94289",
  "94290",
  "94291",
  "94293",
  "94294",
  "94295",
  "94296",
  "94297",
  "94298",
  "94299",
  "94302",
  "94309",
  "94497",
  "94511",
  "94516",
  "94522",
  "94524",
  "94527",
  "94528",
  "94529",
  "94537",
  "94540",
  "94543",
  "94548",
  "94557",
  "94562",
  "94569",
  "94570",
  "94573",
  "94581",
  "94604",
  "94613",
  "94614",
  "94620",
  "94622",
  "94623",
  "94624",
  "94649",
  "94659",
  "94660",
  "94661",
  "94662",
  "94666",
  "94701",
  "94712",
  "94720",
  "94802",
  "94807",
  "94808",
  "94820",
  "94912",
  "94913",
  "94914",
  "94915",
  "94926",
  "94927",
  "94929",
  "94933",
  "94938",
  "94942",
  "94948",
  "94950",
  "94953",
  "94955",
  "94957",
  "94963",
  "94964",
  "94966",
  "94971",
  "94973",
  "94975",
  "94976",
  "94977",
  "94978",
  "94979",
  "94998",
  "95001",
  "95002",
  "95007",
  "95009",
  "95011",
  "95013",
  "95015",
  "95021",
  "95024",
  "95026",
  "95031",
  "95036",
  "95038",
  "95041",
  "95042",
  "95044",
  "95052",
  "95053",
  "95055",
  "95056",
  "95061",
  "95063",
  "95067",
  "95071",
  "95077",
  "95103",
  "95106",
  "95108",
  "95109",
  "95115",
  "95150",
  "95151",
  "95152",
  "95153",
  "95154",
  "95155",
  "95156",
  "95157",
  "95158",
  "95159",
  "95160",
  "95161",
  "95164",
  "95170",
  "95172",
  "95173",
  "95190",
  "95191",
  "95192",
  "95193",
  "95194",
  "95196",
  "95201",
  "95208",
  "95211",
  "95213",
  "95214",
  "95221",
  "95224",
  "95225",
  "95226",
  "95227",
  "95229",
  "95233",
  "95234",
  "95241",
  "95248",
  "95253",
  "95254",
  "95267",
  "95269",
  "95296",
  "95297",
  "95305",
  "95309",
  "95312",
  "95318",
  "95319",
  "95344",
  "95347",
  "95352",
  "95353",
  "95375",
  "95378",
  "95381",
  "95387",
  "95397",
  "95402",
  "95406",
  "95416",
  "95418",
  "95419",
  "95424",
  "95426",
  "95430",
  "95431",
  "95433",
  "95435",
  "95471",
  "95473",
  "95480",
  "95481",
  "95486",
  "95487",
  "95497",
  "95502",
  "95518",
  "95532",
  "95534",
  "95537",
  "95538",
  "95545",
  "95553",
  "95556",
  "95559",
  "95563",
  "95571",
  "95601",
  "95604",
  "95609",
  "95611",
  "95613",
  "95617",
  "95625",
  "95639",
  "95644",
  "95646",
  "95653",
  "95654",
  "95656",
  "95671",
  "95675",
  "95676",
  "95679",
  "95680",
  "95686",
  "95696",
  "95697",
  "95698",
  "95699",
  "95701",
  "95712",
  "95714",
  "95715",
  "95717",
  "95721",
  "95724",
  "95728",
  "95736",
  "95741",
  "95759",
  "95763",
  "95798",
  "95799",
  "95812",
  "95813",
  "95840",
  "95851",
  "95852",
  "95853",
  "95860",
  "95865",
  "95866",
  "95867",
  "95894",
  "95899",
  "95913",
  "95915",
  "95924",
  "95927",
  "95929",
  "95936",
  "95937",
  "95940",
  "95944",
  "95950",
  "95951",
  "95955",
  "95956",
  "95958",
  "95967",
  "95974",
  "95976",
  "95978",
  "95980",
  "95984",
  "95986",
  "95992",
  "96009",
  "96011",
  "96037",
  "96046",
  "96049",
  "96054",
  "96063",
  "96068",
  "96070",
  "96074",
  "96078",
  "96079",
  "96084",
  "96087",
  "96089",
  "96090",
  "96092",
  "96093",
  "96095",
  "96099",
  "96106",
  "96110",
  "96111",
  "96113",
  "96116",
  "96119",
  "96126",
  "96127",
  "96135",
  "96140",
  "96141",
  "96142",
  "96143",
  "96145",
  "96148",
  "96151",
  "96152",
  "96154",
  "96155",
  "96156",
  "96157",
  "96158",
  "96160",
  "96162",
  "00501",
  "00544",
  "06390",
  "10008",
  "10043",
  "10080",
  "10081",
  "10087",
  "10101",
  "10102",
  "10108",
  "10109",
  "10113",
  "10114",
  "10116",
  "10117",
  "10124",
  "10125",
  "10126",
  "10129",
  "10130",
  "10131",
  "10132",
  "10133",
  "10138",
  "10150",
  "10156",
  "10157",
  "10159",
  "10160",
  "10163",
  "10164",
  "10179",
  "10185",
  "10203",
  "10211",
  "10212",
  "10213",
  "10242",
  "10249",
  "10256",
  "10258",
  "10259",
  "10261",
  "10268",
  "10269",
  "10272",
  "10273",
  "10274",
  "10275",
  "10276",
  "10277",
  "10285",
  "10286",
  "10313",
  "10503",
  "10517",
  "10519",
  "10521",
  "10540",
  "10542",
  "10545",
  "10551",
  "10587",
  "10596",
  "10602",
  "10610",
  "10702",
  "10802",
  "10910",
  "10912",
  "10914",
  "10915",
  "10922",
  "10932",
  "10933",
  "10949",
  "10953",
  "10959",
  "10979",
  "10981",
  "10982",
  "10988",
  "10997",
  "11002",
  "11022",
  "11026",
  "11027",
  "11051",
  "11052",
  "11053",
  "11054",
  "11055",
  "11120",
  "11202",
  "11245",
  "11247",
  "11251",
  "11352",
  "11380",
  "11381",
  "11386",
  "11405",
  "11424",
  "11425",
  "11431",
  "11437",
  "11439",
  "11451",
  "11499",
  "11531",
  "11547",
  "11549",
  "11551",
  "11555",
  "11569",
  "11571",
  "11582",
  "11690",
  "11695",
  "11707",
  "11739",
  "11760",
  "11770",
  "11773",
  "11775",
  "11794",
  "11802",
  "11815",
  "11853",
  "11930",
  "11931",
  "11932",
  "11947",
  "11956",
  "11959",
  "11960",
  "11962",
  "11964",
  "11965",
  "11969",
  "11970",
  "11972",
  "11973",
  "11975",
  "12040",
  "12045",
  "12050",
  "12069",
  "12073",
  "12082",
  "12089",
  "12107",
  "12124",
  "12128",
  "12132",
  "12133",
  "12141",
  "12161",
  "12172",
  "12174",
  "12177",
  "12181",
  "12195",
  "12201",
  "12212",
  "12214",
  "12220",
  "12222",
  "12224",
  "12225",
  "12227",
  "12228",
  "12229",
  "12230",
  "12231",
  "12232",
  "12233",
  "12234",
  "12235",
  "12236",
  "12237",
  "12238",
  "12239",
  "12240",
  "12241",
  "12242",
  "12243",
  "12244",
  "12245",
  "12246",
  "12247",
  "12248",
  "12249",
  "12250",
  "12255",
  "12257",
  "12261",
  "12288",
  "12301",
  "12325",
  "12345",
  "12402",
  "12417",
  "12420",
  "12429",
  "12432",
  "12436",
  "12438",
  "12441",
  "12452",
  "12453",
  "12459",
  "12471",
  "12475",
  "12483",
  "12489",
  "12490",
  "12493",
  "12504",
  "12506",
  "12510",
  "12511",
  "12512",
  "12527",
  "12530",
  "12537",
  "12541",
  "12544",
  "12551",
  "12552",
  "12555",
  "12568",
  "12574",
  "12584",
  "12588",
  "12602",
  "12604",
  "12722",
  "12724",
  "12749",
  "12767",
  "12769",
  "12778",
  "12781",
  "12784",
  "12785",
  "12811",
  "12820",
  "12841",
  "12848",
  "12856",
  "12862",
  "12864",
  "12872",
  "12884",
  "12915",
  "12927",
  "12929",
  "12933",
  "12939",
  "12973",
  "12975",
  "12976",
  "12977",
  "12995",
  "13020",
  "13022",
  "13024",
  "13043",
  "13051",
  "13056",
  "13062",
  "13064",
  "13065",
  "13087",
  "13089",
  "13093",
  "13102",
  "13107",
  "13113",
  "13115",
  "13117",
  "13119",
  "13121",
  "13123",
  "13134",
  "13137",
  "13138",
  "13139",
  "13153",
  "13154",
  "13157",
  "13162",
  "13163",
  "13201",
  "13217",
  "13218",
  "13220",
  "13221",
  "13225",
  "13235",
  "13244",
  "13250",
  "13251",
  "13252",
  "13261",
  "13305",
  "13312",
  "13313",
  "13321",
  "13341",
  "13352",
  "13353",
  "13362",
  "13364",
  "13401",
  "13404",
  "13410",
  "13426",
  "13435",
  "13442",
  "13449",
  "13455",
  "13457",
  "13472",
  "13479",
  "13484",
  "13503",
  "13504",
  "13505",
  "13599",
  "13611",
  "13615",
  "13623",
  "13627",
  "13628",
  "13631",
  "13632",
  "13641",
  "13643",
  "13645",
  "13647",
  "13649",
  "13651",
  "13657",
  "13666",
  "13671",
  "13674",
  "13677",
  "13678",
  "13683",
  "13692",
  "13695",
  "13699",
  "13737",
  "13738",
  "13745",
  "13747",
  "13749",
  "13758",
  "13761",
  "13762",
  "13763",
  "13774",
  "13784",
  "13794",
  "13814",
  "13840",
  "13845",
  "13847",
  "13848",
  "13851",
  "13860",
  "13902",
  "14010",
  "14021",
  "14027",
  "14029",
  "14035",
  "14038",
  "14056",
  "14061",
  "14095",
  "14107",
  "14109",
  "14110",
  "14112",
  "14126",
  "14130",
  "14133",
  "14135",
  "14140",
  "14151",
  "14166",
  "14168",
  "14169",
  "14173",
  "14205",
  "14231",
  "14233",
  "14240",
  "14241",
  "14260",
  "14261",
  "14263",
  "14264",
  "14265",
  "14267",
  "14269",
  "14270",
  "14272",
  "14273",
  "14276",
  "14280",
  "14302",
  "14410",
  "14413",
  "14429",
  "14430",
  "14443",
  "14449",
  "14452",
  "14453",
  "14461",
  "14463",
  "14488",
  "14508",
  "14511",
  "14515",
  "14518",
  "14520",
  "14529",
  "14537",
  "14538",
  "14539",
  "14542",
  "14547",
  "14549",
  "14556",
  "14557",
  "14558",
  "14563",
  "14588",
  "14592",
  "14602",
  "14603",
  "14627",
  "14638",
  "14639",
  "14642",
  "14643",
  "14644",
  "14646",
  "14647",
  "14649",
  "14650",
  "14651",
  "14652",
  "14653",
  "14692",
  "14694",
  "14702",
  "14707",
  "14720",
  "14722",
  "14730",
  "14732",
  "14742",
  "14745",
  "14751",
  "14752",
  "14756",
  "14758",
  "14766",
  "14774",
  "14778",
  "14783",
  "14785",
  "14786",
  "14788",
  "14827",
  "14831",
  "14851",
  "14852",
  "14854",
  "14856",
  "14857",
  "14863",
  "14876",
  "14887",
  "14893",
  "14902",
];
export default poBoxZipcodes;
