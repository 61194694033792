import * as React from "react";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import autobind from "autobind-decorator";
import onClickOutside from "react-onclickoutside";
import Highlight from "react-highlighter";

class Search extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownActive: false,
      features: [],
      search_text: "",
    };
  }

  public render() {
    return (
      <div className="search">
        <div
          className={cs("fly-search", {
            "open-dropdown":
              this.state.isDropDownActive && this.state.features.length > 0,
          })}
        >
          <input
            type="text"
            placeholder="Search"
            onChange={this.onTextChange}
          />
          <SVG src={"/public/images/icons/search.svg"} className="svg" />
          <div className="fly-search__dropdown">
            {this.state.features.map((feature, index) => {
              return (
                <div
                  className="search-item"
                  key={index}
                  onClick={this.goToFeature.bind(this, feature)}
                >
                  <b>
                    <Highlight
                      search={this.state.search_text}
                      caseSensitive={false}
                      matchClass={"search-item-highlight"}
                    >
                      {feature.place_name}
                    </Highlight>
                  </b>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  public onTextChange(e) {
    let search_text = e.target.value;
    this.setState({ isDropDownActive: false, search_text }, () =>
      this.emitDropDownState()
    );
    if (search_text !== "") {
      let url =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" + search_text;
      url +=
        ".json?access_token=pk.eyJ1IjoibWF0dGZpY2tlIiwiYSI6ImNqNnM2YmFoNzAwcTMzM214NTB1NHdwbnoifQ.Or19S7KmYPHW8YjRz82v6g&cachebuster=1567441428464&autocomplete=true&country=US";
      fetch(url, {})
        .then((res) => res.json())
        .then((res) => {
          if (res.features.length > 0) {
            this.setState(
              { isDropDownActive: true, features: res.features },
              () => this.emitDropDownState()
            );
          }
        });
    }
  }

  @autobind
  public goToFeature(feature) {
    this.props.events.emit("map.go.to.coordinates", {
      long: feature.geometry.coordinates[0],
      lat: feature.geometry.coordinates[1],
      openContextMenu: {
        offsetX: this.props.pointOffset.x,
        offsetY: this.props.pointOffset.y,
      },
    });
    this.props.events.emit("map.go.to.coordinates.clear.event");
  }

  @autobind
  // @ts-ignore
  private handleClickOutside() {
    this.setState({ isDropDownActive: false }, () => this.emitDropDownState());
  }

  @autobind
  private emitDropDownState() {
    this.props.events.emit(
      "search.dropdown.state",
      this.state.isDropDownActive
    );
  }
}

export default onClickOutside(Search);
