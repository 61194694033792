import * as React from "react";
import autobind from "autobind-decorator";

export class CityNavigation extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selected_dmas: [],
    };

    this.props.events.on("planner-on-filter-city", this.onCityFilter);
  }

  public render() {
    if (this.state.selected_dmas.length <= 1) {
      return null;
    }

    return (
      <div className="fly-map-city-select">
        {this.state.selected_dmas.map((dma) => (
          <button
            key={dma}
            className="fly-map-city-select__item"
            onClick={this.gotoCity.bind(this, dma)}
          >
            <b>{dma}</b>
          </button>
        ))}
      </div>
    );
  }

  @autobind
  public gotoCity(city) {
    this.props.events.emit("map-on-goto-city", { dma: city });
  }

  @autobind
  public onCityFilter(selected_dmas) {
    this.setState({ selected_dmas });
  }
}
