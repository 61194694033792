import * as React from "react";
import { Geofence } from "components/Geofence";
import * as cs from "classnames";
import autobind from "autobind-decorator";

export class GeofenceFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const { geofence, selectedEvent } = this.props.filters;

    let isDmaOn = true;

    if (geofence.isOpen || (selectedEvent && geofence.drawing.shapes.length)) {
      isDmaOn = false;
    }

    this.state = {
      isDmaOn,
    };

    this.props.events.on("planner-on-filter-result", this.onFilter);
    this.props.events.on("planner-on-reset", this.onReset);
    this.props.events.on("workspace-on-selected", this.onSelectedWorkspace);
    this.props.events.on(
      "point-to-circle-layer-circle-mile-entered",
      this.onSelectedWorkspace
    );
  }

  public render() {
    const geofenceOptions = {
      isOpen: !this.state.isDmaOn,
      activeTab: this.props.filters.geofence.activeTab,
      zipcodes: this.props.filters.geofence.zipcodes,
      drawing: this.props.filters.geofence.drawing,
      events: this.props.events,
    };

    return (
      <div className="fly-section fly-section--no-padding">
        <div className="fly-section__header">
          <b>Geo-fence</b>
          <div className="fly-section__header__right">
            <p className="fly-label">DMA</p>
            <div
              className={cs("fly-switch fly-switch--small", {
                "is-on": this.state.isDmaOn,
              })}
              onClick={this.toogleDma}
            >
              <div className="toogle" />
            </div>
          </div>
        </div>

        <Geofence options={geofenceOptions} />
      </div>
    );
  }

  @autobind
  public toogleDma() {
    const isDmaOn = !this.state.isDmaOn;

    this.setState({ isDmaOn }, () =>
      this.props.events.emit("geofence-on-reset")
    );
  }

  @autobind
  private onSelectedWorkspace() {
    this.setState({ isDmaOn: false });
  }

  @autobind
  private onFilter() {
    this.props.events.emit("geofence-on-filter");
  }

  @autobind
  private onReset() {
    this.props.events.emit("geofence-on-reset");
    this.setState({ isDmaOn: true });
  }
}
