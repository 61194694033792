import * as React from "react";
import { Doughnut } from "react-chartjs-2";
import autobind from "autobind-decorator";
import * as numeral from "numeral";
// import { PLANNER_EXTERNAL } from "helpers/RBAC";

const options = {
  tooltips: {
    enabled: false,
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
};

export class ZipcodeResult extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { selectedShape: null };
    this.props.events.on("map-features-on-mouse-move", this.onFeatures);
  }

  public render() {
    if (!this.state.selectedShape) {
      return null;
    }

    let zipcode = this.state.selectedShape.user_zipcode;
    const isZipcodeResult = this.props.filters.plannerResponse
      .show_zipcode_results;

    let zipcode_result = null;
    if (!this.props.isFilterPage) {
      zipcode_result = [];

      if (isZipcodeResult) {
        zipcode_result = this.props.filters.plannerResponse.zipcode_results.filter(
          (r) => r.zipcode === zipcode
        );
      } else {
        zipcode_result = this.props.filters.plannerResponse.h3_results.filter(
          (r) => r.h3_p9 === zipcode
        );
      }

      if (zipcode_result.length === 0) {
        zipcode_result = null;
      } else {
        zipcode_result = zipcode_result[0];
      }
    }

    if (!zipcode) {
      return null;
    }

    if (this.props.isFilterPage || !zipcode_result) {
      return (
        <div
          className="fly-planner__map__result"
          style={{
            left: this.state.selectedShape.clientxy.clientX - 40,
            top: this.state.selectedShape.clientxy.clientY - 40,
          }}
        >
          <div className="white-holder">{zipcode}</div>
        </div>
      );
    }

    let sold_impression = zipcode_result.sold_impression;
    let available_impression = zipcode_result.available_impression;
    let available_impression_increased =
      zipcode_result.available_impression_increased;
    let available_impression_hour = zipcode_result.available_impression_hour;
    let total_impression = sold_impression + available_impression;
    let total_impression_increased =
      sold_impression + available_impression_increased;

    let formattedAvailableHour = numeral(available_impression_hour).format(
      "0,0.0"
    );

    formattedAvailableHour =
      formattedAvailableHour.substr(-1) === "0"
        ? numeral(available_impression_hour).format("0,0")
        : formattedAvailableHour;

    let percentage = 0;
    if (this.props.filters.selectedEvents) {
      percentage = parseInt(
        ((available_impression / total_impression) * 100).toString(),
        10
      );
    } else {
      percentage = parseInt(
        (
          (available_impression_increased / total_impression_increased) *
          100
        ).toString(),
        10
      );
    }

    if (available_impression < 0) {
      percentage = 0;
    } else if (available_impression_increased < 0) {
      percentage = 0;
    } else if (sold_impression === 0) {
      percentage = 100;
    }

    let data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(100, 0, 0, 100);
      const gradientRed = ctx.createLinearGradient(100, 0, 0, 100);
      gradientRed.addColorStop(1, "#FFBEAE");
      gradientRed.addColorStop(0, "#FD8A76");
      gradient.addColorStop(0, "#47D6F6");
      gradient.addColorStop(1, "#22ACEA");
      return {
        labels: ["", ""],
        datasets: [
          {
            data: [percentage, 100 - percentage],
            backgroundColor: [gradient, gradientRed],
            hoverBackgroundColor: [gradient, gradientRed],
            borderWidth: [1, 0],
          },
        ],
      };
    };

    return (
      <div
        className="fly-planner__map__zipcode_result"
        style={{
          left: this.state.selectedShape.clientxy.clientX - 40,
          top: this.state.selectedShape.clientxy.clientY - 40,
        }}
      >
        <div className="white-holder has-header">
          <div className="white-holder__header">
            {isZipcodeResult ? "Zipcode" : "H3 ID"}: {zipcode}
          </div>
          <div className="white-holder__body">
            <div className="flex-col margin-left-10">
              {/* {this.props.usertoken.role !== PLANNER_EXTERNAL ? ( */}
              <div className="f-col-5">
                <div className="f-row">
                  <div className="fly-graph-label two">
                    <div className="fly-graph-label__left">
                      <b className="h-600 pink">{100 - percentage}%</b>
                      <p className="small">Sold</p>
                    </div>
                    <span className="fly-graph-label__divider" />
                    <div className="fly-graph-label__right">
                      <b className="h-600 teal">{percentage}%</b>
                      <p className="small">Available</p>
                    </div>
                  </div>
                </div>
                <div className="f-row">
                  <Doughnut
                    data={data}
                    width={100}
                    height={100}
                    options={options}
                  />
                </div>
              </div>
              {/* ) : (
                ""
              )} */}
              <div className="f-col-7">
                {/* {this.props.usertoken.role !== PLANNER_EXTERNAL ? ( */}
                <>
                  {this.props.filters.selectedEvent ? (
                    <>
                      <div className="f-row">
                        <p className="small">
                          Estimated <b>Increased</b> Impressions
                        </p>
                      </div>
                      <div className="f-row">
                        <b className="h-500">
                          {numeral(total_impression_increased)
                            .format("0,0")
                            .toUpperCase()}
                        </b>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="f-row">
                    <p className="small">Estimated Impressions</p>
                  </div>
                  <div className="f-row">
                    <b className="h-500">
                      {numeral(total_impression).format("0,0").toUpperCase()}
                    </b>
                  </div>
                  <div className="f-row">
                    <p className="small">Sold Impressions</p>
                  </div>
                  <div className="f-row">
                    <b className="h-500 pink">
                      {numeral(sold_impression).format("0,0").toUpperCase()}
                    </b>
                  </div>
                </>
                {/* ) : (
                  ""
                )} */}
                {this.props.filters.selectedEvent ? (
                  <>
                    <div className="f-row">
                      <p className="small">
                        Available <b>Increased</b> Impressions
                      </p>
                    </div>
                    <div className="f-row">
                      <b className="h-500 teal">
                        {numeral(available_impression_increased)
                          .format("0,0")
                          .toUpperCase()}
                      </b>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="f-row">
                  <p className="small">Available Impressions</p>
                </div>
                <div className="f-row">
                  <b className="h-500 teal">
                    {numeral(available_impression).format("0,0").toUpperCase()}
                  </b>
                </div>
                <div className="f-row">
                  <p className="small">Available Time</p>
                </div>
                <div className="f-row">
                  <b className="h-500 orange">{formattedAvailableHour} h</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  public onFeatures(features) {
    if (features.length > 0) {
      this.setState({ selectedShape: features[0].properties });
    } else if (this.state.selectedShape !== null) {
      this.setState({ selectedShape: null });
    }
  }
}
