import {
  doubleClickZoomEnable,
  doubleClickZoomDisable,
} from "../lib/double_click_zoom";
import { Constants } from "../Constants";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";

let DrawLineString = MapboxDraw.modes.draw_line_string;
const RectangleMode: any = {
  onTap: DrawLineString.onTap,
  onClick: DrawLineString.onClick,
};

RectangleMode.onSetup = function (_opts) {
  const rectangle = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      shapeType: Constants.geojsonTypes.RECTANGLE,
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });
  this.addFeature(rectangle);
  this.clearSelectedFeatures();
  doubleClickZoomDisable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.setActionableState({
    trash: true,
  });
  return {
    rectangle,
  };
};

RectangleMode.clickAnywhere = function (state, e) {
  if (
    state.startPoint &&
    state.startPoint[0] !== e.lngLat.lng &&
    state.startPoint[1] !== e.lngLat.lat
  ) {
    this.updateUIClasses({ mouse: "pointer" });
    state.endPoint = [e.lngLat.lng, e.lngLat.lat];
    this.changeMode("simple_select", { featuresId: state.rectangle.id });
  }
  // on first click, save clicked point coords as starting for  rectangle
  const startPoint = [e.lngLat.lng, e.lngLat.lat];
  state.startPoint = startPoint;
};

RectangleMode.onMouseMove = function (state, e) {
  if (state.startPoint) {
    state.rectangle.updateCoordinate(
      "0.0",
      state.startPoint[0],
      state.startPoint[1]
    );
    state.rectangle.updateCoordinate("0.1", e.lngLat.lng, state.startPoint[1]);
    state.rectangle.updateCoordinate("0.2", e.lngLat.lng, e.lngLat.lat);
    state.rectangle.updateCoordinate("0.3", state.startPoint[0], e.lngLat.lat);
    state.rectangle.updateCoordinate(
      "0.4",
      state.startPoint[0],
      state.startPoint[1]
    );
  }
};

RectangleMode.onKeyUp = function (_state, e) {
  if (e.keyCode === 27) {
    // escape key
    return this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

RectangleMode.onStop = function (state) {
  doubleClickZoomEnable(this);
  this.updateUIClasses({ mouse: "none" });
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.rectangle.id) === undefined) {
    return;
  }

  // remove last added coordinate
  state.rectangle.removeCoordinate("0.4");
  if (state.rectangle.isValid()) {
    this.map.fire("draw.create", {
      features: [state.rectangle.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.rectangle.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

RectangleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.rectangle.id;
  geojson.properties.active = isActivePolygon ? "true" : "false";
  if (!isActivePolygon) {
    return display(geojson);
  }

  // Only render the rectangular polygon if it has the starting point
  if (!state.startPoint) {
    return;
  }
  return display(geojson);
};

RectangleMode.onTrash = function (state) {
  this.deleteFeature([state.rectangle.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

export { RectangleMode };
