import * as React from "react";
import { AbstractMessageDialog } from "./AbstractMessageDialog";

class ConfirmMessageDialog extends AbstractMessageDialog {
  constructor(props) {
    super(props);

    this.onYesClicked = this.onYesClicked.bind(this);
    this.onNoClicked = this.onNoClicked.bind(this);
  }

  public renderButtons() {
    return (
      <div>
        <button type="button" onClick={this.onYesClicked}>
          Yes
        </button>
        <button type="button" onClick={this.onNoClicked}>
          No
        </button>
      </div>
    );
  }

  public onYesClicked() {
    super._onCloseClicked();

    if (this.props.item && typeof this.props.item.onYes === "function") {
      this.props.item.onYes();
    }
  }

  public onNoClicked() {
    super._onCloseClicked();

    if (this.props.item && typeof this.props.item.onNo === "function") {
      this.props.item.onNo();
    } else {
      this._onCloseClicked();
    }
  }
}

export { ConfirmMessageDialog };
