import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { App } from "../pages/App";
import { Planner } from "../pages/Planner";
import ErrorPage from "../pages/Error";
import { AuthContext, PrivateRoute } from "@fireflyon/auth";
import { Spinner } from "components/Spinner";
import { setToken, setTokenGetter } from "../helpers/AjaxHelper";
import { navigationItems } from "../layouts/navbarConfig";
import { Layout } from "@fireflyon/firestrap";
import { Settings } from "../pages/Settings";

const PrivateRouteWithLayout = ({
  children,
  requiredPermissions = null,
  ...rest
}) => {
  const {
    loading,
    isAuthenticated,
    getTokenSilently,
    isAuthorized,
    logout,
  } = React.useContext(AuthContext);
  const [isInitialized, setInitialized] = React.useState(false);

  const getToken = React.useCallback(async () => {
    if (getTokenSilently && !loading) {
      if (!isAuthorized(null, requiredPermissions)) {
        return;
      }

      setTokenGetter(getTokenSilently);

      try {
        const token = (await getTokenSilently()) as string;

        setToken(token);

        setInitialized(true);
      } catch (e) {
        //logout();
      }
    }
  }, [loading, isAuthorized, getTokenSilently, requiredPermissions, logout]);

  React.useEffect(() => {
    if (!isAuthenticated) return;

    getToken();
  }, [isAuthenticated, getToken]);

  return (
    <PrivateRoute
      redirectIfNotAuthorized="/error-page"
      requiredPermissions={requiredPermissions}
      {...rest}
    >
      {isInitialized ? (
        <Layout realm="adops" navigationItems={navigationItems} noFooter={true}>
          <div className="geometrics-layout fr-main">{children}</div>
        </Layout>
      ) : (
        <Spinner />
      )}
    </PrivateRoute>
  );
};

export default (
  <App>
    <Switch>
      <Route path="/" exact>
        <Redirect to="/plan/custom" />
      </Route>

      <PrivateRouteWithLayout
        path="/plan/custom"
        exact
        requiredPermissions={["access-plan"]}
        children={<Planner />}
        loadingRenderer={<Spinner />}
        redirectIfNotAuthorized="/error-page"
      />

      <PrivateRouteWithLayout
        loadingRenderer={<Spinner />}
        path="/old-settings"
        requiredPermissions={["access-settings"]}
        children={<Settings />}
        redirectIfNotAuthorized="/error-page"
      />

      <PrivateRoute
        path="/error-page"
        children={<ErrorPage />}
        loadingRenderer={<Spinner />}
      />
    </Switch>
  </App>
);
