import * as React from "react";
import autobind from "autobind-decorator";
import { FilterSummary } from "pages/Planner/Result/FilterSummary";
import { ImpressionSummary } from "pages/Planner/Result/ImpressionSummary";
import { CampaignSummary } from "pages/Planner/Result/CampaignSummary";
import { CarNumbers } from "pages/Planner/Result/CarNumbers";
import { Constants } from "pages/Planner/Map/draw/Constants";
// import { PLANNER_EXTERNAL } from "helpers/RBAC";

export class Result extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      drawData: null,
      // userRole: props.usertoken.role,
    };
  }

  public componentDidMount() {
    this.setState({
      drawData: this.props.mapBoxRef.mapBoxDrawControlRef.mapBoxDrawControlRef.draw.getAll(),
    });
  }

  public render() {
    return (
      <div className="fly-planner__filter">
        <div className="fly-planner__filter__content">
          <FilterSummary filters={this.props.filters} />
          <ImpressionSummary
            filters={this.props.filters}
            userRole={this.state.userRole}
            withHours
            hasSovTooltip
          />
          {/* {this.state.userRole !== PLANNER_EXTERNAL ? ( */}
          <>
            <CampaignSummary filters={this.props.filters} />
            <CarNumbers filters={this.props.filters} />
          </>
          {/* ) : (
            ""
          )} */}
        </div>
        <div className="fly-planner__filter__action-box">
          <button className="fly-button secondary" onClick={this.onBack}>
            <b>Back</b>
          </button>
          <a
            href="javascript:void(0)"
            className="fly-button primary"
            onClick={this.onExport}
          >
            <b>
              Export
              {this.state.drawData && this.state.drawData.features.length > 0
                ? " GeoJSON"
                : ""}
            </b>
          </a>
        </div>
      </div>
    );
  }

  @autobind
  private onExport(e) {
    const { drawData } = this.state;

    if (drawData.features.length > 0) {
      const response = this.props.filters.plannerResponse;

      const total_impression =
        response.sold_impression + response.available_impression;
      // const h3Ids = response.h3_results.map((obj) => {
      //   return obj.h3;
      // });

      drawData.properties = {};

      // if (this.state.userRole !== PLANNER_EXTERNAL) {
      drawData.properties.total_impression = total_impression;
      drawData.properties.sold_impression = response.sold_impression;
      // }

      drawData.properties.available_impression = response.available_impression;

      // drawData.properties.h3Ids = h3Ids;

      // Stringify the GeoJson
      const convertedData =
        "text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(drawData));

      // Create export
      e.currentTarget.setAttribute("href", "data:" + convertedData);
      e.currentTarget.setAttribute("download", "planner-report.geojson");
    } else {
      window.print();
    }
  }

  @autobind
  private onBack() {
    this.props.events.emit(Constants.events.DELETE_ALL);
    this.props.events.emit("planner-on-back", {});
  }
}
