class AppConfig {
  public static toServerUri(uri: string): string {
    if (uri.startsWith("https://") || uri.startsWith("http://")) {
      return uri;
    }
    return this.getAppPathPrefix() + (uri.startsWith("/") ? uri : "/" + uri);
  }

  public static appendBasename(path: string): string {
    return this.getBasename() + (path.startsWith("/") ? path : "/" + path);
  }

  public static getAppPathPrefix() {
    return window.REACT_APP_GEOMETRICS_API_PATH;
  }

  public static getRootTimeZone() {
    return "Europe/Istanbul";
  }

  public static getMapApiKey() {
    return window.REACT_APP_MAPBOX_API_KEY;
  }

  public static getBasename() {
    return "";
  }

  public static getMediaApiUrl() {
    return window.REACT_APP_MEDIA_API_PATH;
  }
}

export { AppConfig };
