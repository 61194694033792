import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import { createBrowserHistory } from "history";
import { AppHistory } from "./helpers";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { syncHistoryWithStore } from "react-router-redux";
import { configureStore } from "./redux/store";
import routes from "./routes/";
import { AppConfig } from "./helpers";
import { registerStore } from "./helpers/GlobalStore";
import { AuthContextProvider } from "@fireflyon/auth";
import axios from "axios";
import "./styles";

axios.defaults.baseURL = window.REACT_APP_GEOMETRICS_API_PATH;

// Create a history of your choosing (we're using a browser history in this case)
let historyOptions: any = {};
if (AppConfig.getBasename().length > 0) {
  historyOptions.basename = AppConfig.getBasename();
}

const browserHistory = createBrowserHistory(historyOptions);

const store = configureStore(browserHistory);
registerStore(store);

syncHistoryWithStore(browserHistory as any, store);
AppHistory.__setBrowserHistory(browserHistory);
ReactDOM.render(
  //@ts-ignore
  <Provider store={store}>
    <Router history={browserHistory}>
      <AuthContextProvider
        client_id={window.REACT_APP_AUTH0_CLIENTID}
        audience="https://api.fireflyon.com"
        ignoreCacheOnRefresh={true}
      >
        {routes}
      </AuthContextProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
