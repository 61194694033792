import * as React from "react";

export class BuildingsLayer extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  public shouldComponentUpdate(nextProps): any {
    if (nextProps.map) {
      this.addLayer(nextProps.map.getMap());
      return true;
    }
    return false;
  }

  public render() {
    return null;
  }

  private addLayer(map) {
    let layers = map.getStyle().layers;

    let labelLayerId;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
        labelLayerId = layers[i].id;
        break;
      }
    }

    map.setLight({ color: "#fff", intensity: 0.15, position: [1.15, 135, 45] });

    map.addLayer(
      {
        id: "3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minzoom: 12,
        paint: {
          "fill-extrusion-color": "#fff",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 0.95,
        },
        light: {
          anchor: "viewport",
          color: "white",
          intensity: 0.1,
        },
      },
      labelLayerId
    );
  }
}
