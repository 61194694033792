import * as React from "react";
import autobind from "autobind-decorator";
import { AjaxHelper } from "helpers";
import { h3ToGeoBoundary, h3ToGeo } from "h3-js";
import { Constants } from "pages/Map/HeatMap/draw/Constants";

const LAYER: string = "zipcodes-layer";
const LAYER_SOURCE: string = "zipcodes-layer-source";

interface IZipcodeSelectResultLayerState {
  isSelectFromMap: boolean;
  plannerResponse: any;
  isFiltering: boolean;
  selected_zipcodes: any;
  hoverFeatureId: number;
}

export class ZipcodeSelectResultLayer extends React.Component<
  any,
  IZipcodeSelectResultLayerState
> {
  private map: any = null;

  constructor(props) {
    super(props);
    this.state = {
      isSelectFromMap: false,
      plannerResponse: {},
      isFiltering: false,
      selected_zipcodes: [],
      hoverFeatureId: 1,
    };

    this.props.events.on("map-features-select-from-map", this.selectFromMap);
    this.props.events.on(
      "map-features-select-from-map-bounds-changed",
      this.selectFromMapBoundChange
    );
    this.props.events.on("planner-on-filter-result", this.onFilter);
    this.props.events.on("planner-on-back", this.onBack);
    this.props.events.on(Constants.events.CLEAR_ALL_ZIPCODES, this.reset);
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.map) {
      this.map = nextProps.map;
      this.addLayer();
    }
  }

  public render() {
    return this.state.isFiltering ? (
      <div className="map-loading">
        <div className="map-loading__animation">
          <div className="fly-folding-cube">
            <div className="fly-cube1 fly-cube" />
            <div className="fly-cube2 fly-cube" />
            <div className="fly-cube4 fly-cube" />
            <div className="fly-cube3 fly-cube" />
          </div>
        </div>
      </div>
    ) : null;
  }

  @autobind
  private onFilter(plannerResponse) {
    this.setState({ plannerResponse }, () => {
      if (plannerResponse.show_zipcode_results) {
        this.selectFromMap(true);
      } else {
        this.drawH3Results(plannerResponse);
      }
      let map = this.map.getMap();
      map.setPaintProperty(LAYER, "fill-color", {
        property: "percentage",
        stops: [
          [0, "rgba(253,138,118, 0.001)"],
          [1, "rgba(253,138,118,1)"],
        ],
      });
      map.setPaintProperty(LAYER, "fill-outline-color", "rgb(188,204,240)");
    });
  }

  @autobind
  private onBack() {
    let map = this.map.getMap();
    this.setState({ plannerResponse: {} });
    this.selectFromMap(false);
    map.setPaintProperty(LAYER, "fill-color", "rgba(71,214,246,0.1)");
    map.setPaintProperty(LAYER, "fill-outline-color", "#22ACEA");
  }

  @autobind
  private selectFromMapBoundChange() {
    if (this.state.plannerResponse.show_zipcode_results) {
      this.selectFromMap(this.state.isSelectFromMap);
    }
  }

  @autobind
  private drawH3Results(plannerResponse) {
    this.setState({ isSelectFromMap: true });
    let map = this.map.getMap();
    let features = [];
    for (let item of plannerResponse.h3_results) {
      let coord = h3ToGeo(item.h3);
      item.lat = coord[0];
      item.lng = coord[1];
      let coordinates = h3ToGeoBoundary(item.h3, true);
      item.coordinates = coordinates;
      features.push({
        id: item.h3_p9,
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
        properties: {
          percentage: 0,
          h3: item.h3,
          "h3-p9": item.h3_p9,
          "postal-code": item.h3_p9,
          zipcodes: item.zipcodes,
        },
      });
    }
    let data = {
      type: "FeatureCollection",
      features,
    };
    this.colorZipcodePercentages(data);
    map.getSource(LAYER_SOURCE).setData(data);
    map.setLayoutProperty(LAYER, "visibility", "visible");
  }
  @autobind
  private selectFromMap(isSelectFromMap) {
    if (!this.map) {
      return;
    }

    this.setState({ isSelectFromMap });
    let map = this.map.getMap();
    if (isSelectFromMap) {
      let bounds = map.getBounds();
      let bsw = bounds.getSouthWest();
      let bne = bounds.getNorthEast();
      let minLat = bsw.lat - 0.5;
      let minLon = bsw.lng - 0.5;
      let maxLat = bne.lat + 0.5;
      let maxLon = bne.lng + 0.5;
      map.setLayoutProperty(LAYER, "visibility", "visible");
      let features = null;
      let url =
        "/api/geozip/area?minLat=" +
        minLat +
        "&maxLat=" +
        maxLat +
        "&minLong=" +
        minLon +
        "&maxLong=" +
        maxLon;
      AjaxHelper.get(url).then((res) => {
        features = res;
        for (let fet of features.features) {
          fet.id = parseInt(fet.properties["postal-code"], 10);
          fet.properties.percentage = 0;
        }
        this.colorZipcodePercentages(features);
        map.getSource(LAYER_SOURCE).setData(features);
      });
    } else {
      map.setLayoutProperty(LAYER, "visibility", "none");
    }
  }

  @autobind
  private colorZipcodePercentages(features) {
    let resp = this.state.plannerResponse;
    if (resp.zipcode_results && resp.zipcode_results.length > 0) {
      let zipcode_results = [];
      if (resp.show_zipcode_results) {
        zipcode_results = resp.zipcode_results;
      } else {
        zipcode_results = resp.h3_results;
      }
      for (let z of zipcode_results) {
        let feature = [];
        if (resp.show_zipcode_results) {
          feature = features.features.filter(
            (f) => f.properties["postal-code"] === z.zipcode
          );
        } else {
          feature = features.features.filter(
            (f) => f.properties["h3-p9"] === z.h3_p9
          );
        }
        let sold_impression = parseInt(z.sold_impression, 10);
        let available_impression = parseInt(z.available_impression, 10);
        let available_impression_increased = parseInt(
          z.available_impression_increased,
          10
        );
        if (available_impression_increased > available_impression) {
          available_impression = available_impression_increased;
        }
        let total_impression = sold_impression + available_impression;
        let percentage = 0;
        if (total_impression > 0) {
          percentage = sold_impression / total_impression;
        }
        if (feature.length > 0 && !isNaN(percentage)) {
          feature[0].properties.percentage = percentage;
        }
      }
    }
  }

  @autobind
  private addLayer() {
    let map = this.map.getMap();
    if (map.getLayer(LAYER)) {
      return;
    }

    if (map.getSource(LAYER_SOURCE)) {
      return;
    }
    map.addSource(LAYER_SOURCE, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: "zipcodes-layer",
      type: "fill",
      source: LAYER_SOURCE,
      layout: {
        visibility: "none",
      },
      paint: {
        "fill-color": "rgba(71,214,246,0.1)",
        "fill-outline-color": "#22ACEA",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          5,
          1,
        ],
      },
    });

    map.on("mousemove", this.onMouseMove);
    map.on("click", this.onClick);
  }

  @autobind
  private onMouseMove(e) {
    let map = this.map.getMap();
    let events = this.props.events;
    let features = map.queryRenderedFeatures([e.point.x, e.point.y]);
    features = features.filter((f) => {
      if (f && f.properties) {
        f.properties.clientxy = {
          clientX: e.originalEvent.clientX,
          clientY: e.originalEvent.clientY,
        };
      }

      if (f.layer.source === LAYER_SOURCE) {
        f.properties.user_zipcode = f.properties["postal-code"];
      }

      return (
        f.layer.source === "mapbox-gl-draw-hot" ||
        f.layer.source === "mapbox-gl-draw-cold" ||
        f.layer.source === LAYER_SOURCE
      );
    });

    map.setFeatureState(
      { source: LAYER_SOURCE, id: this.state.hoverFeatureId },
      { hover: false }
    );

    if (features.length > 0) {
      let hoverFeatureId = features[0].id;
      map.setFeatureState(
        { source: LAYER_SOURCE, id: hoverFeatureId },
        { hover: true }
      );
      this.setState({ hoverFeatureId });
    }

    events.emit("map-features-on-mouse-move", features);
  }

  @autobind
  private onClick(e) {
    let map = this.map.getMap();
    let events = this.props.events;
    let features = map.queryRenderedFeatures([e.point.x, e.point.y]);
    features = features.filter((f) => {
      if (f && f.properties) {
        f.properties.clientxy = {
          clientX: e.originalEvent.clientX,
          clientY: e.originalEvent.clientY,
        };
      }

      if (f.layer.source === LAYER_SOURCE) {
        f.properties.user_zipcode = f.properties["postal-code"];
      }

      return (
        f.layer.source === "mapbox-gl-draw-hot" ||
        f.layer.source === "mapbox-gl-draw-cold" ||
        f.layer.source === LAYER_SOURCE
      );
    });

    if (features.length > 0) {
      let selected_zipcodes = this.state.selected_zipcodes;
      let selected_feature = features[0];
      let zipcode = selected_feature.properties.user_zipcode;
      if (zipcode) {
        if (selected_zipcodes.filter((f) => f === zipcode).length === 0) {
          selected_zipcodes.push(zipcode);
          events.emit("map-features-on-click-zipcode-add", zipcode);
        } else {
          events.emit("map-features-on-click-zipcode-remove", zipcode);
          selected_zipcodes = selected_zipcodes.filter((f) => f !== zipcode);
        }
        this.setState({ selected_zipcodes });
      }
    }
  }

  @autobind
  private reset() {
    this.setState({ selected_zipcodes: [] });
  }
}
