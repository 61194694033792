import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  dateOptions: [
    {
      value: null,
      label: "Any Date",
    },
    {
      value: {
        start: moment().format(DATE_FORMAT),
        end: moment().format(DATE_FORMAT),
      },
      label: "Today",
    },
    {
      value: {
        start: moment().add(1, "days").format(DATE_FORMAT),
        end: moment().add(1, "days").format(DATE_FORMAT),
      },
      label: "Tomorrow",
    },
    {
      value: {
        start: moment().day(6).format(DATE_FORMAT),
        end: moment().day(7).format(DATE_FORMAT),
      },
      label: "This Weekend",
    },
    {
      value: {
        start: moment().day(0).format(DATE_FORMAT),
        end: moment().day(7).format(DATE_FORMAT),
      },
      label: "This Week",
    },
    {
      value: {
        start: moment().day(7).format(DATE_FORMAT),
        end: moment().day(14).format(DATE_FORMAT),
      },
      label: "Next Week",
    },
    {
      value: {
        start: moment().startOf("month").format(DATE_FORMAT),
        end: moment().endOf("month").format(DATE_FORMAT),
      },
      label: "This Month",
    },
    {
      value: {
        start: moment().endOf("month").add(1, "d").format(DATE_FORMAT),
        end: moment()
          .endOf("month")
          .add(1, "M")
          .add(1, "d")
          .format(DATE_FORMAT),
      },
      label: "Next Month",
    },
    {
      value: "datepicker",
      label: "Pick a date",
    },
  ],
};
