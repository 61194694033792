import * as React from "react";
import * as PropTypes from "prop-types";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import { EllipseMode } from "./modes/EllipseMode";
import { RectangleMode } from "./modes/RectangleMode";
import { StaticMode } from "./modes/StaticMode";
import { Emitter, CustomQueryStringParameter, IParameterMap } from "helpers";
import { Constants } from "./Constants";
import { drawStyles } from "./drawStyle";
import autobind from "autobind-decorator";
import { themeSupport, resetCurrentTheme } from "./modes/themeSupport";
import SVG from "react-inlinesvg";
import * as cs from "classnames";
import DrawCorridor from "pages/Planner/Map/draw/modes/DrawCorridor";

interface IMapBoxDrawControlProps {
  events: Emitter;
}

interface IMapBoxDrawControlState {
  inDrawMode: boolean;
  draw_type: string;
  isHelperOpen: boolean;
  helperMessage?: {
    message: string;
    steps: string[];
  };
}

export class MapBoxDrawControl extends React.Component<
  IMapBoxDrawControlProps,
  IMapBoxDrawControlState
> {
  public static contextTypes = {
    map: PropTypes.object,
  };

  public draw: MapboxDraw = null;

  constructor(props) {
    super(props);

    this.props.events.on(Constants.events.START_DRAW, this.onDrawStart);
    this.props.events.on(Constants.events.STOP_DRAW_OUTSIDE, this.onDrawEnd);
    this.props.events.on(Constants.events.REMOVE_SHAPE, this.onShapeRemove);
    this.props.events.on(Constants.events.ADD_ZIPCODE, this.onZipcodeAdd);
    this.props.events.on(Constants.events.REMOVE_ZIPCODE, this.onZipcodeRemove);
    this.props.events.on(Constants.events.CLEAR_ALL, this.onClearAll);
    this.props.events.on(
      Constants.events.CLEAR_ALL_ITEMS,
      this.onClearAllItems
    );
    this.props.events.on(Constants.events.SET_DRAW_TYPE, this.drawTypeClick);
    this.props.events.on(
      Constants.events.CLEAR_ALL_ZIPCODES,
      this.onClearAllZipcodes
    );
    this.props.events.on("set-draw-type", this.setDrawType);
    this.props.events.on(Constants.events.ADD_PTC, this.onPTCAdd);
    this.state = { inDrawMode: false, draw_type: "", isHelperOpen: true };
  }

  public componentWillMount() {
    let map = this.context.map;
    const modes = Object.assign(
      {
        draw_ellipse: themeSupport(EllipseMode, "ellipse"),
        draw_rectangle: themeSupport(RectangleMode, "rectangle"),
        draw_corridor: themeSupport(DrawCorridor, "corridor"),
        static: StaticMode,
      },
      MapboxDraw.modes
    );
    modes.draw_polygon = themeSupport(modes.draw_polygon, "polygon");

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      modes,
      controls: null,
      userProperties: true,
      styles: drawStyles,
    });

    map.on(Constants.events.CREATE, this.onShapeCreate);
    map.on(Constants.events.DELETE, this.onShapeDelete);
    map.addControl(this.draw, "top-left");

    setTimeout(() => {
      this.draw.changeMode(Constants.modes.STATIC);
    }, 100);
  }

  private getFeatures(e) {
    let map = this.context.map;

    let features = map.queryRenderedFeatures([e.point.x, e.point.y]);
    features = features.filter((f) => {
      return (
        f.layer.source === "mapbox-gl-draw-hot" ||
        f.layer.source === "mapbox-gl-draw-cold" ||
        f.layer.source === "location-search-layer-source" ||
        f.layer.source === "point-to-circle-layer-source" ||
        f.layer.source === "ptc-circle-layer-source"
      );
    });
    return features;
  }

  public componentDidMount() {
    let events = this.props.events;
    let map = this.context.map;

    map.on("load", () => {
      map.on("click", (e) => {
        if (e.originalEvent.button === 0) {
          events.emit("map.left.clicked", {
            clientX: e.originalEvent.clientX,
            clientY: e.originalEvent.clientY,
            features: this.getFeatures(e),
          });
        }
      });

      map.on("contextmenu", (e) => {
        events.emit("map.right.clicked", {
          clientX: e.originalEvent.clientX,
          clientY: e.originalEvent.clientY,
          features: this.getFeatures(e),
        });
      });
    });

    map.on("SET_HELPER_MESSAGE", (e) => {
      this.setState({
        helperMessage: {
          message: e.message,
          steps: e.steps,
        },
      });
    });
  }

  public render() {
    if (this.state.inDrawMode) {
      return (
        <div className="draw-tool-box-planner">
          <div className="draw-tool-box-planner-buttons">
            <button
              className={cs("tool-btn", "tooltip-container", {
                selected:
                  this.state.draw_type === Constants.modes.DRAW_CORRIDOR,
              })}
              onClick={this.drawTypeClick.bind(
                this.drawTypeClick,
                Constants.modes.DRAW_CORRIDOR
              )}
            >
              <SVG
                src={"/public/images/icons/corridor.svg"}
                className="fly-svg-icon fly-svg-icon--xlarge"
              />
              <p className="tooltip">Corridor</p>
            </button>
            <button
              className={cs("tool-btn", "tooltip-container", {
                selected: this.state.draw_type === Constants.modes.DRAW_ELLIPSE,
              })}
              onClick={this.drawTypeClick.bind(
                this.drawTypeClick,
                Constants.modes.DRAW_ELLIPSE
              )}
            >
              <SVG
                src={"/public/images/icons/hm-circle-unselected.svg"}
                className="fly-svg-icon fly-svg-icon--xlarge"
              />
              <p className="tooltip">Circle</p>
            </button>
            <button
              className={cs("tool-btn", "tooltip-container", {
                selected:
                  this.state.draw_type === Constants.modes.DRAW_RECTANGLE,
              })}
              onClick={this.drawTypeClick.bind(
                this.drawTypeClick,
                Constants.modes.DRAW_RECTANGLE
              )}
            >
              <SVG
                src={"/public/images/icons/hm-rectangle-unselected.svg"}
                className="fly-svg-icon fly-svg-icon--xlarge"
              />
              <p className="tooltip">Rectangle</p>
            </button>
            <button
              className={cs("tool-btn", "tooltip-container", {
                selected: this.state.draw_type === Constants.modes.DRAW_POLYGON,
              })}
              onClick={this.drawTypeClick.bind(
                this.drawTypeClick,
                Constants.modes.DRAW_POLYGON
              )}
            >
              <SVG
                src={"/public/images/icons/hm-polygon-unselected.svg"}
                className="fly-svg-icon fly-svg-icon--xlarge"
              />
              <p className="tooltip">Polygon</p>
            </button>
            <button
              className={cs("tool-btn", "tooltip-container", {
                selected: this.state.draw_type === Constants.modes.DRAW_POINT,
              })}
              onClick={this.drawTypeClick.bind(
                this.drawTypeClick,
                Constants.modes.DRAW_POINT
              )}
            >
              <SVG
                src={"/public/images/icons/hm-point-unselected.svg"}
                className="fly-svg-icon fly-svg-icon--xlarge"
              />
              <p className="tooltip">Radius</p>
            </button>
            <button className="fly-button primary" onClick={this.onDrawEnd}>
              <p>Close Drawing</p>
            </button>
          </div>

          {this.state.isHelperOpen && this.state.helperMessage && (
            <div className="draw-tool-helper">
              <div className="draw-tool-helper-icon">
                <SVG src={"/public/images/icons/help.svg"} />
              </div>

              <div className="draw-tool-helper-content">
                <span>{this.state.helperMessage.message}</span>
                {this.state.helperMessage.steps &&
                  this.state.helperMessage.steps.length > 0 && (
                    <ul>
                      {this.state.helperMessage.steps.map((step, i) => (
                        <li key={i}>{step}</li>
                      ))}
                    </ul>
                  )}
              </div>

              <div
                className="draw-tool-helper-icon"
                onClick={() => {
                  this.setState({ isHelperOpen: false });
                }}
              >
                <SVG src={"/public/images/icons/button-close.svg"} />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  public getGeoJsonQueryParameters(ids?) {
    let parameterMapList: IParameterMap[] = [];
    if (this.draw) {
      let zipcodeList = [];
      let featureCollections = this.draw.getAll();
      if (featureCollections) {
        for (let feature of featureCollections.features) {
          let properties = feature.properties || {};
          let geometry = feature.geometry || {};

          if (ids && ids.length && !ids.includes(feature.id)) {
            continue;
          }

          if (geometry.type !== "Point") {
            if (properties.shapeType === Constants.geojsonTypes.ZIPCODE) {
              let zipcode = properties.zipcode;
              if (zipcodeList.indexOf(zipcode) === -1) {
                zipcodeList.push(zipcode);
                parameterMapList.push({
                  t: "zipcode",
                  z: zipcode,
                });
              }
            } else if (
              properties.shapeType === Constants.geojsonTypes.ELLIPSE
            ) {
              let coordinates = feature.geometry.coordinates[0].slice(
                0,
                feature.geometry.coordinates[0].length - 1
              );
              parameterMapList.push({
                t: "polygon",
                c: CustomQueryStringParameter.coordinatesToText(coordinates),
              });
            } else {
              let coordinates = feature.geometry.coordinates[0].slice(
                0,
                feature.geometry.coordinates[0].length - 1
              );
              parameterMapList.push({
                t: "polygon",
                c: CustomQueryStringParameter.coordinatesToText(coordinates),
              });
            }
          }
        }
      }
    }

    return CustomQueryStringParameter.toCustomParameter(
      "shapes",
      parameterMapList
    );
  }

  public getGeometries(ids?) {
    let geometries = [];
    if (this.draw) {
      let featureCollections = this.draw.getAll();
      if (featureCollections) {
        for (let feature of featureCollections.features) {
          if (ids && ids.length && !ids.includes(feature.id)) {
            continue;
          }
          geometries.push(feature.geometry);
        }
      }
    }
    return geometries;
  }

  @autobind
  public onShapeCreate(geojson) {
    this.props.events.emit(Constants.events.CREATE, geojson);
    this.setState(
      { draw_type: "", helperMessage: undefined, isHelperOpen: false },
      () => this.emitDrawType
    );
  }

  @autobind
  public onShapeDelete(ids) {
    this.props.events.emit(Constants.events.DELETE, ids);
  }

  @autobind
  public onDrawStart() {
    this.setState({
      inDrawMode: true,
      isHelperOpen: true,
      helperMessage: undefined,
    });
  }

  @autobind
  public onDrawEnd() {
    this.setState(
      { inDrawMode: false, draw_type: "" },
      () => this.emitDrawType
    );

    this.draw.changeMode(Constants.modes.STATIC);
    this.props.events.emit(Constants.events.STOP_DRAW, {});
  }

  @autobind
  public onZipcodeAdd(zipcodeData) {
    zipcodeData.ids = this.draw.add(zipcodeData.geojson);
  }

  @autobind
  public onPTCAdd(feature) {
    this.draw.add(feature);
    this.draw.changeMode(Constants.modes.SIMPLE_SELECT);
  }

  @autobind
  public onShapeRemove(shapeData) {
    if (shapeData.ids && shapeData.ids.length > 0) {
      this.draw.delete(shapeData.ids);
    }
  }

  @autobind
  public onZipcodeRemove(zipcodeData) {
    if (zipcodeData && zipcodeData.ids && zipcodeData.ids.length > 0) {
      this.draw.delete(zipcodeData.ids);
    }
  }

  @autobind
  public onClearAllZipcodes(zipcodeData) {
    for (let z of zipcodeData) {
      this.onZipcodeRemove(z);
    }
  }

  @autobind
  public onClearAll() {
    this.draw.deleteAll();
    resetCurrentTheme();
    this.draw.changeMode(Constants.modes.STATIC);

    if (this.state.inDrawMode) {
      this.props.events.emit(Constants.events.STOP_DRAW, {});
      this.setState({
        inDrawMode: false,
        isHelperOpen: false,
        helperMessage: undefined,
      });
    }
  }

  @autobind
  public onClearAllItems() {
    this.draw.deleteAll();
  }

  @autobind
  private setDrawType(draw_type) {
    this.setState({ draw_type });
  }

  @autobind
  private emitDrawType() {
    this.props.events.emit("draw-type-changed", this.state.draw_type);
  }

  @autobind
  private drawTypeClick(draw_type) {
    this.setState({ helperMessage: undefined });
    if (draw_type !== Constants.modes.DRAW_POINT) {
      this.draw.changeMode(draw_type);
    } else {
      this.draw.changeMode(Constants.modes.STATIC);
    }
    if (draw_type === Constants.modes.DRAW_POINT) {
      setTimeout(() => {
        this.setState({ draw_type, isHelperOpen: true }, () =>
          this.emitDrawType()
        );
      }, 300);
    } else {
      this.setState({ draw_type, isHelperOpen: true }, () =>
        this.emitDrawType()
      );
    }
  }
}
