import { toast } from "react-toastify";

class AppHelper {
  public static median(arr) {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);

    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  }

  public static showMessage(message, type = "info", options = {}) {
    toast[type](message, {
      autoClose: 3000,
      closeButton: true,
      pauseOnFocusLoss: false,
      hideProgressBar: true,
      position: toast.POSITION.TOP_RIGHT,
      ...options,
    });
  }

  public static getDateFromDateString(dateString) {
    const splittedDate = dateString.split("-");
    const year = parseInt(splittedDate[0], 10);
    const month = parseInt(splittedDate[1], 10) - 1;
    const day = parseInt(splittedDate[2], 10);
    return new Date(year, month, day, 12, 0, 0);
  }

  public static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      // @ts-ignore
      // tslint:disable-next-line:no-bitwise one-variable-per-declaration
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public static lpad(str, padString, length) {
    while (str.length < length) {
      str = padString + str;
    }
    return str;
  }

  public static getTimeFormatByHours(duration) {
    duration = duration * 3600;

    return (
      Math.floor(duration / 60 / 60) +
      ":" +
      this.lpad((Math.floor(duration / 60) % 60) + "", "0", 2) +
      ":" +
      this.lpad(Math.floor(duration % 60) + "", "0", 2)
    );
  }

  public static secondsToHourText(duration) {
    return Math.floor(duration / 60 / 60);
  }
}

export { AppHelper };
