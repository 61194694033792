export const SHOW_ERROR_DIALOG = "dialogbox/SHOW_ERROR_DIALOG";
export const HIDE_ERROR_DIALOG = "dialogbox/HIDE_ERROR_DIALOG";

export const SHOW_SUCCESS_DIALOG = "dialogbox/SHOW_SUCCESS_DIALOG";
export const HIDE_SUCCESS_DIALOG = "dialogbox/HIDE_SUCCESS_DIALOG";

export const SHOW_CONFIRM_DIALOG = "dialogbox/SHOW_CONFIRM_DIALOG";
export const HIDE_CONFIRM_DIALOG = "dialogbox/HIDE_CONFIRM_DIALOG";

export const SHOW_WARNING_DIALOG = "dialogbox/SHOW_WARNING_DIALOG";
export const HIDE_WARNING_DIALOG = "dialogbox/HIDE_WARNING_DIALOG";

const initialState = {
  errorList: [],
  successList: [],
  confirmList: [],
  warningList: [],
};

function onHideDialog(state, listName) {
  let list = state[listName];

  let newList: any = [];
  for (let i = 1; i < list.length; i++) {
    newList.push(list[i]);
  }

  let result = { ...state };
  result[listName] = newList;
  return result;
}

function onShowDialog(state, listName, item) {
  let list = state[listName];

  let newList = [];
  for (let itm of list) {
    newList.push(itm);
  }
  newList.push(item);

  let result = { ...state };
  result[listName] = newList;
  return result;
}

export function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR_DIALOG:
      return onShowDialog(state, "errorList", action.item);

    case HIDE_ERROR_DIALOG:
      return onHideDialog(state, "errorList");

    case SHOW_SUCCESS_DIALOG:
      return onShowDialog(state, "successList", action.item);

    case HIDE_SUCCESS_DIALOG:
      return onHideDialog(state, "successList");

    case SHOW_CONFIRM_DIALOG:
      return onShowDialog(state, "confirmList", action.item);

    case HIDE_CONFIRM_DIALOG:
      return onHideDialog(state, "confirmList");

    case SHOW_WARNING_DIALOG:
      return onShowDialog(state, "warningList", action.item);

    case HIDE_WARNING_DIALOG:
      return onHideDialog(state, "warningList");

    default:
      return state;
  }
}

const noop = () => {};

export function showError(options) {
  return {
    type: SHOW_ERROR_DIALOG,
    item: {
      title: options.title || "Error",
      content: options.content || "",
      onOk: options.onOk || noop,
    },
  };
}

export function hideError() {
  return {
    type: HIDE_ERROR_DIALOG,
  };
}

export function showSuccess(options) {
  return {
    type: SHOW_SUCCESS_DIALOG,
    item: {
      content: options.content || "",
      onOk: options.onOk || noop,
    },
  };
}

export function hideSuccess() {
  return {
    type: HIDE_SUCCESS_DIALOG,
  };
}

export function showConfirm(options) {
  return {
    type: SHOW_CONFIRM_DIALOG,
    item: {
      title: options.title,
      content: options.content || "",
      onYes: options.onYes || noop,
      onNo: options.onNo || noop,
    },
  };
}

export function hideConfirm() {
  return {
    type: HIDE_CONFIRM_DIALOG,
  };
}

export function showWarning(options) {
  return {
    type: SHOW_WARNING_DIALOG,
    item: {
      title: options.title || "",
      content: options.content || "",
      onOk: options.onOk || noop,
    },
  };
}

export function hideWarning() {
  return {
    type: HIDE_WARNING_DIALOG,
  };
}
