import * as React from "react";
import Search from "./search";

export class Controllers extends React.Component<any, any> {
  public render() {
    return (
      <div className="map-controllers">
        <Search
          events={this.props.events}
          pointOffset={{
            x: this.props.pointOffset.x,
            y: this.props.pointOffset.y,
          }}
        />
      </div>
    );
  }
}
