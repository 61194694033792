import * as React from "react";
import * as numeral from "numeral";

export class ImpressionResult extends React.Component<any, any> {
  public render() {
    let goal = numeral(this.props.goal).format("0,0").toUpperCase();
    let classname = this.props.isFilteredGoal
      ? "paragraph bold"
      : goal === "0"
      ? "paragraph bold pink has-tooltip"
      : "paragraph bold green has-tooltip";
    let tooltip = this.props.isFilteredGoal
      ? "Pace Impression"
      : "Total Impression";
    let tooltip_classname = this.props.isFilteredGoal
      ? "tooltip black"
      : "tooltip";
    if (goal === "0" && this.props.isFilteredGoal) {
      return null;
    }

    return (
      <div className="impression-result">
        {goal === "0" ? (
          ""
        ) : (
          <div className={tooltip_classname}>
            <span>{tooltip}</span>
          </div>
        )}
        <b className={classname}>{goal}</b>
      </div>
    );
  }
}
