import * as React from "react";
import { AbstractMessageDialog } from "./AbstractMessageDialog";

class ErrorMessageDialog extends AbstractMessageDialog {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
  }

  public onOk() {
    super._onCloseClicked();

    if (this.props.item && typeof this.props.item.onOk === "function") {
      this.props.item.onOk();
    }
  }

  public renderButtons() {
    return (
      <div>
        <button className="primary-btn" onClick={this.onOk}>
          OK
        </button>
      </div>
    );
  }
}

export { ErrorMessageDialog };
