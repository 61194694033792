import { EventEmitter } from "eventemitter3";

export class Emitter {
  private emitter: any;

  constructor() {
    this.emitter = new EventEmitter();
  }

  public emit(name, data?) {
    this.emitter.emit(name, data);
  }

  public on(name, handler, scope?) {
    this.emitter.on(name, handler);

    if (scope) {
      const unbind = scope.$on("$destroy", () => {
        this.emitter.off(name, handler);
        unbind();
      });
    }
  }

  public removeAllListeners(evt?) {
    this.emitter.removeAllListeners(evt);
  }

  public off(name, handler) {
    this.emitter.off(name, handler);
  }
}
