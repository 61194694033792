import * as React from "react";
import { ImpressionResult } from "pages/Planner/Result/ImpressionResult";

export class CampaignSummary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="white-holder has-header">
        <div className="white-holder__header planner_result">
          Campaign summary
        </div>
        <div className="white-holder__body">
          <div className="flex-col">
            <div className="f-col-12">
              <p className="h-200 margin-bottom">Sold campaigns</p>
              <div className="fly-list-item slim" key={-1}>
                <div className="fly-list-item__wrapper">
                  <div className="flex-col">
                    <div className="f-col-5">
                      <b className="paragraph title-bold">Name</b>
                    </div>
                    <div className="f-col-2">
                      <b className="paragraph title-bold">AE</b>
                    </div>
                    <div className="f-col-2">
                      <b className="paragraph title-bold">AM</b>
                    </div>
                    <div className="f-col-3">
                      <b className="paragraph title-bold">Impressions</b>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.filters.plannerResponse.sold_campaings.map(
                (cmp, index) => (
                  <div className="fly-list-item slim" key={index}>
                    <div className="fly-list-item__wrapper">
                      <div className="flex-col">
                        <div className="f-col-5">
                          <b className="paragraph bold">{cmp.name}</b>
                        </div>
                        <div className="f-col-2">
                          <b className="paragraph bold">{cmp.exec_name}</b>
                        </div>
                        <div className="f-col-2">
                          {cmp.customer_success_manager ? (
                            <b className="paragraph bold">
                              {cmp.customer_success_manager}
                            </b>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="f-col-3">
                          <ImpressionResult
                            goal={cmp.filtered_goal}
                            isFilteredGoal={true}
                          />
                          <ImpressionResult
                            goal={cmp.goal}
                            isFilteredGoal={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="f-col-12">
              <p className="h-200 margin-bottom">RFP sent +</p>
              <div className="fly-list-item slim" key={-2}>
                <div className="fly-list-item__wrapper">
                  <div className="flex-col">
                    <div className="f-col-5">
                      <b className="paragraph title-bold">Name</b>
                    </div>
                    <div className="f-col-2">
                      <b className="paragraph title-bold">AE</b>
                    </div>
                    <div className="f-col-2">
                      <b className="paragraph title-bold">AM</b>
                    </div>
                    <div className="f-col-3">
                      <b className="paragraph title-bold">Impressions</b>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.filters.plannerResponse.rfp_sent_campaings.map(
                (cmp, index) => (
                  <div className="fly-list-item slim" key={index}>
                    <div className="fly-list-item__wrapper">
                      <div className="flex-col">
                        <div className="f-col-5">
                          <b className="paragraph bold">{cmp.name}</b>
                        </div>
                        <div className="f-col-2">
                          <b className="paragraph bold">{cmp.exec_name}</b>
                        </div>
                        <div className="f-col-2">
                          {cmp.customer_success_manager ? (
                            <b className="paragraph bold">
                              {cmp.customer_success_manager}
                            </b>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="f-col-3">
                          <ImpressionResult
                            goal={cmp.goal}
                            isFilteredGoal={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
