import * as React from "react";
import * as DayPicker from "react-day-picker";
import autobind from "autobind-decorator";
import * as styled from "styled-components";
import moment from "moment";
import { MaskedDateInput } from "components";
import "react-day-picker/lib/style.css";
import * as cs from "classnames";
import { AppHelper } from "helpers";

const StyledDayPicker = styled.default(DayPicker.default)`
`;

export class DateFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  public getInitialState(props) {
    let fromDate = new Date();
    let initial_start_date = null;
    if (!props.isNewRange) {
      fromDate = props.range.start;
      initial_start_date = props.range.start;
    }

    let toDate = new Date();
    let initial_end_date = null;
    let enteredTo = null;
    if (!props.isNewRange) {
      toDate = props.range.end;
      initial_end_date = props.range.end;
      enteredTo = props.range.end;
    }

    return {
      fromSet: fromDate,
      toSet: toDate,
      from: fromDate,
      to: toDate,
      enteredTo,
      isOpened: false,
      initial_start_date,
      initial_end_date,
    };
  }

  @autobind
  public getDateFromDateString(dateString) {
    const splittedDate = dateString.split("-");
    const year = parseInt(splittedDate[0], 10);
    const month = parseInt(splittedDate[1], 10) - 1;
    const day = parseInt(splittedDate[2], 10);
    return new Date(year, month, day, 12, 0, 0);
  }

  public componentWillMount() {
    if (this.props.isNewRange && this.props.rangeCount === 0) {
      this.setState({ isOpened: true });
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.isNewRange && nextProps.rangeCount === 0) {
      this.setState({ isOpened: true });
    }
  }

  public render() {
    const { from, enteredTo } = this.state;
    const modifiers = {
      start: from,
      end: enteredTo,
    };
    const disabledDays = { before: new Date() };
    const selectedDays = [from, { from, to: enteredTo }];
    let format = this.props.format || "DD MMMM YYYY";

    return (
      <div
        className={cs(
          "fly-calendar fly-calendar--planner",
          this.state.isOpened === true ? "is-open" : "is-close"
        )}
      >
        {this.props.isNewRange ? (
          ""
        ) : (
          <div className="calendar-btn">
            <b>
              {moment(this.state.fromSet).format(format)} -{" "}
              {moment(this.state.toSet).format(format)}
            </b>
            <button
              className="calendar-btn__change fly-button secondary"
              onClick={this.showPanel}
            >
              <b>Update</b>
            </button>
            <button className="calendar-btn__close" onClick={this.deleteRange}>
              <i className="icon ozzy-cross-thick" />
            </button>
          </div>
        )}

        {this.props.isNewRange &&
        this.props.rangeCount > 0 &&
        this.props.rangeCount < 2 ? (
          <button className="fly-button inline blue" onClick={this.showPanel}>
            <b>+ Add date</b>
          </button>
        ) : (
          ""
        )}

        <div className="calendar-dropdown">
          <div className={cs("calendar-dropdown__date-picker")}>
            <div className="input-box">
              <div className="start">
                <div className="fly-input">
                  <MaskedDateInput
                    value={this.state.from}
                    onDateChange={this.onStartDateChanged}
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="end">
                <div className="fly-input">
                  <MaskedDateInput
                    value={this.state.to}
                    onDateChange={this.onEndDateChanged}
                  />
                </div>
              </div>
            </div>

            <StyledDayPicker
              numberOfMonths={2}
              selectedDays={selectedDays}
              disabledDays={disabledDays}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
              renderDay={this.customRenderDay}
            />
          </div>
          <div className="calendar-dropdown__action-box">
            {this.props.isNewRange && this.props.rangeCount === 0 ? (
              ""
            ) : (
              <button className="fly-button inline" onClick={this.cancelRange}>
                <b>Cancel</b>
              </button>
            )}
            {this.props.isNewRange ? (
              ""
            ) : (
              <button className="fly-button dark" onClick={this.deleteRange}>
                <b>Delete</b>
              </button>
            )}
            <button
              className={cs("fly-button dark", {
                disabled:
                  this.state.enteredTo === null || this.state.to === null,
              })}
              onClick={this.setDates}
            >
              {this.props.isNewRange ? <b>Add</b> : <b>Update</b>}
            </button>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private customRenderDay(day) {
    const date = day.getDate();
    let useIndicator = false;
    const { initial_start_date, initial_end_date } = this.state;
    if (
      initial_start_date &&
      initial_end_date &&
      initial_start_date !== initial_end_date &&
      day >= initial_start_date &&
      day <= initial_end_date
    ) {
      useIndicator = true;
    }
    return (
      <div>
        <div
          className={cs("", { indicator: useIndicator })}
          style={{
            display: "block",
          }}
        />
        <div>{date}</div>
      </div>
    );
  }

  @autobind
  private showPanel() {
    this.setState({ isOpened: true });
  }

  @autobind
  public hidePanel() {
    this.setState({ isOpened: false });
  }

  @autobind
  private cancelRange() {
    this.setState({
      from: this.state.fromSet,
      to: this.state.toSet,
      enteredTo: this.state.toSet,
      isOpened: false,
    });
  }

  @autobind
  private deleteRange() {
    this.setState({
      from: this.state.fromSet,
      to: this.state.toSet,
      enteredTo: this.state.toSet,
    });
    this.props.deleteRange(this.props.range);
  }

  @autobind
  private setDates() {
    if (this.props.isNewRange) {
      if (this.props.addRange(this.state.from, this.state.to)) {
        this.setState({
          enteredTo: null,
          from: new Date(),
          to: new Date(),
          fromSet: new Date(),
          toSet: new Date(),
          isOpened: false,
        });
      } else {
        AppHelper.showMessage("Selected date range has already been added!");
      }
    } else {
      if (
        this.props.updateRange(this.props.range, this.state.from, this.state.to)
      ) {
        this.setState({
          initial_start_date: this.state.from,
          initial_end_date: this.state.to,
          fromSet: this.state.from,
          toSet: this.state.to,
          isOpened: false,
        });
      }
    }
  }

  @autobind
  public onStartDateChanged(val: Date) {
    const { to } = this.state;
    if (!to || val <= to) {
      this.setState({ from: val });
    }
  }

  @autobind
  public onEndDateChanged(val: Date) {
    const { from } = this.state;
    if (!from || val >= from) {
      this.setState({ to: val, enteredTo: val });
    }
  }

  public validate(): string {
    if (this.state.from == null) {
      return "Please select start date!";
    } else if (this.state.to == null) {
      return "Please select end date!";
    } else {
      return null as any;
    }
  }

  public getFromDateStr(): string {
    return moment(this.state.from).format("YYYYMMDD");
  }

  public getToDateStr(): string {
    return moment(this.state.to).format("YYYYMMDD");
  }

  @autobind
  public reset() {}

  @autobind
  public isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay =
      from && (DayPicker as any).DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  @autobind
  public handleDayClick(day) {
    const { from, to } = this.state;
    if (day.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      this.setState({
        from: new Date(),
        to: null,
        enteredTo: null,
      });
      return;
    }
    if (from && to && day >= from && day <= to) {
      this.setState({ from: day, to: null, enteredTo: null });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      });
    }
  }

  @autobind
  public handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }
}
