export const themeColors = {
  t1: "#22ACEA",
  t2: "#50E3C2",
  t3: "#5053E3",
  t4: "#D150E3",
  t5: "#E35050",
  t6: "#E3C250",
  t7: "#8AE350",
  t8: "#50D1E3",
};

function createTheme(color, themeName) {
  return [
    {
      id: "gl-draw-polygon-fill-inactive" + "-" + themeName,
      type: "fill",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "fill-color": color,
        "fill-outline-color": color,
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive" + "-" + themeName,
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-line-inactive" + "-" + themeName,
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "LineString"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-stroke-inactive" + "-" + themeName,
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": "#fff",
      },
    },
    {
      id: "gl-draw-point-point-stroke-inactive" + "-" + themeName,
      type: "circle",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Point"],
        ["==", "meta", "feature"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "circle-radius": 5,
        "circle-opacity": 1,
        "circle-color": "#fff",
      },
    },
    {
      id: "gl-draw-point-inactive" + "-" + themeName,
      type: "circle",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Point"],
        ["==", "meta", "feature"],
        ["!=", "mode", "static"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "circle-radius": 3,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-point-stroke-active" + "-" + themeName,
      type: "circle",
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["==", "active", "true"],
        ["!=", "meta", "midpoint"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "circle-radius": 7,
        "circle-color": "#fff",
      },
    },
    {
      id: "gl-draw-polygon-fill-static" + "-" + themeName,
      type: "fill",
      filter: [
        "all",
        ["==", "mode", "static"],
        ["==", "$type", "Polygon"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "fill-color": color,
        "fill-outline-color": color,
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-stroke-static" + "-" + themeName,
      type: "line",
      filter: [
        "all",
        ["==", "mode", "static"],
        ["==", "$type", "Polygon"],
        ["==", "user_theme", themeName],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-line-static" + "-" + themeName,
      type: "line",
      filter: [
        "all",
        ["==", "mode", "static"],
        ["==", "$type", "LineString"],
        ["==", "user_theme", themeName],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-point-static" + "-" + themeName,
      type: "circle",
      filter: [
        "all",
        ["==", "mode", "static"],
        ["==", "$type", "Point"],
        ["==", "user_theme", themeName],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": color,
      },
    },
  ];
}

export const drawStyles = [
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#fbb03b",
      "fill-outline-color": "#fbb03b",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  ...createTheme(themeColors.t1, "t1"),
  ...createTheme(themeColors.t2, "t2"),
  ...createTheme(themeColors.t3, "t3"),
  ...createTheme(themeColors.t4, "t4"),
  ...createTheme(themeColors.t5, "t5"),
  ...createTheme(themeColors.t6, "t6"),
  ...createTheme(themeColors.t7, "t7"),
  ...createTheme(themeColors.t8, "t8"),
];
