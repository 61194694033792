import * as React from "react";
import SVG from "react-inlinesvg";
import * as cs from "classnames";

interface IProps {
  onClickClose: () => any;
  onClickShare: (emails: string[]) => any;
}

function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ShareDialog: React.FC<IProps> = ({ onClickClose, onClickShare }) => {
  const [state, setState] = React.useState({
    inputValue: "",
    recipients: [],
    isInputValid: true,
  });

  return (
    <div className="share-dialog">
      <div className="share-dialog-inner">
        <div className="share-dialog-header">
          <span>Share</span>
          <div onClick={onClickClose} className="close-icon">
            <SVG
              src={"/public/images/icons/close.svg"}
              className="fly-svg-icon fly-svg-icon--small"
            />
          </div>
        </div>

        <div className="share-dialog-content">
          <div className="recipients">
            {state.recipients.map((recipient, index) => (
              <div className="recipient" key={index}>
                <span>{recipient}</span>

                <button
                  className="delete"
                  onClick={() => {
                    const recipients = state.recipients.filter(
                      (r) => r !== recipient
                    );

                    setState((state) => ({ ...state, recipients }));
                  }}
                >
                  <SVG
                    src={"/public/images/icons/trash.svg"}
                    className="fly-svg-icon"
                  />
                </button>
              </div>
            ))}
          </div>
          <div
            className={`email-input ${!state.isInputValid ? "invalid" : ""}`}
          >
            <input
              placeholder="E-mail address"
              value={state.inputValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                setState((state) => ({
                  ...state,
                  inputValue,
                  isInputValid: true,
                }));
              }}
            />

            {!state.isInputValid && (
              <span className="validation-message">
                A valid email adress is required.
              </span>
            )}
          </div>

          <div>
            <a
              href="javascript:void(0)"
              onClick={() => {
                if (state.recipients.includes(state.inputValue)) return;

                const isInputValid = validateEmail(state.inputValue);

                if (isInputValid) {
                  setState((state) => ({
                    ...state,
                    recipients: [...state.recipients, state.inputValue],
                    inputValue: "",
                    isInputValid: true,
                  }));
                } else {
                  setState((state) => ({ ...state, isInputValid: false }));
                }
              }}
              className={cs("add-recipient-btn", {
                disabled: false,
              })}
            >
              <SVG
                src={"/public/images/icons/hm-button-add.svg"}
                className="fly-svg-icon fly-svg-icon--small"
              />
              <span className="title">ADD RECIPIENT</span>
            </a>
          </div>
        </div>
        <div className="share-dialog-footer">
          <a
            href="javascript:void(0)"
            className={cs("sh_are-btn", {
              disabled:
                (state.recipients.length < 1 && !state.inputValue) ||
                !state.isInputValid,
            })}
            onClick={() => {
              const recipients = [...state.recipients];

              if (state.inputValue) {
                const isInputValid = validateEmail(state.inputValue);
                if (isInputValid) {
                  recipients.push(state.inputValue);
                } else {
                  setState((state) => ({ ...state, isInputValid: false }));
                  return;
                }
              }
              onClickShare(recipients);
            }}
          >
            <SVG
              src={"/public/images/icons/sh_are.svg"}
              className="fly-svg-icon fly-svg-icon--small"
            />
            <span className="title">Share</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShareDialog;
